import { GlobalsService } from "@services";

export interface IACHProcessPaymentModel {
	achPaymentTypeId: number;
	customerId: number;
	invoiceId?: number;
	invoiceIds: number[];
	paymentId?: number;
	amount: number;
	invoiceNumber: string;
	poNumber: string;
	paymentReferenceCode: string;
	isOnlinePayment: boolean;
	isDownPayment: boolean;
	isSkeditPay: boolean;
	syncToQuickbooks: boolean;
	notes: string;
}

export class ACHProcessPaymentModel implements IACHProcessPaymentModel {
	constructor() {
		this.isSkeditPay = GlobalsService.company.useSkeditPay;
	}
	achPaymentTypeId: number;
	customerId: number;
	invoiceId: number;
	invoiceIds: number[];
	paymentId: number;
	amount: number;
	invoiceNumber: string;
	poNumber: string;
	paymentReferenceCode: string;
	isOnlinePayment: boolean;
	isDownPayment: boolean;
	isSkeditPay: boolean;
	syncToQuickbooks: boolean;
	notes: string;
}