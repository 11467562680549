import { CreditCardPaymentTypeModel, ICreditCardPaymentTypeModel, ICustomerModel } from "@models";
import { CreditCardService, GlobalsService } from "../../services";
import { USAePayService } from "../../services/credit-card-processing";
import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { SlickDialogComponent } from "@slick-components";
import { CreditCardEditDialogComponent } from "../../../app-desktop/src/components/process-payment-dialog/credit-card-edit-dialog/credit-card-edit-dialog.component";



@Component({
	selector: "credit-card-selector-dialog",
	templateUrl: "./credit-card-selector-dialog.component.html",
	styleUrls: ["./credit-card-selector-dialog.component.css"],
	providers: [ CreditCardService]
})
export class CreditCardSelectorDialogComponent {
	@ViewChild("creditCardDialogRef", { static: true }) creditCardDialogRef: SlickDialogComponent;
	@ViewChild("creditCardEditDialog", { static: true }) creditCardEditDialog: CreditCardEditDialogComponent;
	@Output() onPaymentTypesChanged: EventEmitter<ICreditCardPaymentTypeModel[]> = new EventEmitter();
	@Input() isMobile: boolean;

	creditCardPaymentTypes: ICreditCardPaymentTypeModel[];
	customerModel: ICustomerModel;
	selectedPaymentTypeModel: ICreditCardPaymentTypeModel;
	resolve: any;
	submitted: boolean;

	constructor(private USAePayService: USAePayService,
	private creditCardService: CreditCardService) {

	}

	async openDialog(customerModel: ICustomerModel) {
		return new Promise<number>(async (resolve) => {
			if (this.isMobile) {
				this.creditCardDialogRef.width = 320;
			}
			
			this.submitted = false;
			this.customerModel = customerModel;
			this.selectedPaymentTypeModel = null;

			if (this.customerModel?.customerId > 0) {
				//special code for jellyfish. We need to use whatever jellyfish is using
				if (GlobalsService.company.parentCompanyId === 133 && (customerModel.companyId != GlobalsService.company.companyId))
					this.creditCardPaymentTypes = await this.USAePayService.getCreditCardPaymentTypeForCustomer(this.customerModel.customerId);
				else
					this.creditCardPaymentTypes = await this.creditCardService.getCreditCardPaymentTypeForCustomer(this.customerModel.customerId);

			}

			this.creditCardDialogRef.showDialog();
			this.resolve = resolve;
		});
	}

	createNewPaymentType() {
		const paymentType = new CreditCardPaymentTypeModel();
		paymentType.addressModel.address1 = this.customerModel.address1
		paymentType.addressModel.address2 = this.customerModel.address2
		paymentType.addressModel.city = this.customerModel.city
		paymentType.addressModel.state = this.customerModel.state
		paymentType.addressModel.zipcode = this.customerModel.zipcode
		paymentType.addressModel.country = this.customerModel.country
		paymentType.customerId = this.customerModel.customerId;
		this.creditCardEditDialog.openDialog(paymentType, true, this.customerModel.companyId);
	}

	editPaymentType(paymentType: ICreditCardPaymentTypeModel) {
		this.creditCardEditDialog.openDialog(paymentType, false, this.customerModel.companyId);
	}

	async creditCardEditDialogChanges() {
		// Something has changed
		if (GlobalsService.company.parentCompanyId === 133 && (this.customerModel.companyId !== GlobalsService.company.companyId))
			this.creditCardPaymentTypes = await this.USAePayService.getCreditCardPaymentTypeForCustomer(this.customerModel.customerId);
		else
			this.creditCardPaymentTypes = await this.creditCardService.getCreditCardPaymentTypeForCustomer(this.customerModel.customerId);

		if (this.creditCardPaymentTypes && this.creditCardPaymentTypes.length > 0)
			this.selectPaymentType(this.creditCardPaymentTypes[0]);

		this.onPaymentTypesChanged.emit(this.creditCardPaymentTypes);
	}


	selectPaymentType(paymentType: ICreditCardPaymentTypeModel) {
		this.selectedPaymentTypeModel = paymentType;
	}

	onSelect() {
		this.submitted = true;
		if (!this.selectedPaymentTypeModel)
			return;
		this.creditCardDialogRef.hideDialog();
		this.resolve(this.selectedPaymentTypeModel.creditCardPaymentTypeId);
	}

	onClose() {
		this.resolve(null);
	}




    
}