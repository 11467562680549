import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ComponentsModule } from "@components";
import { CustomerDisplayModule } from "@components/customer-display";
import { ProcessPaymentDialogModule } from "@components/process-payment-dialog/process-payment-dialog.module";
import { CreditCardEditDialogModule } from "@components/process-payment-dialog/credit-card-edit-dialog/credit-card-edit-dialog.module";
import { FormsModule } from '@angular/forms';

import { SlickAutoCompleteModule, SlickButtonSpinnerModule, SlickDialogModule, SlickDropDownModule, SlickDatePickerModule, SlickFileDropModule, SlickFileListModule, SlickToastModule, SlickTabsModule, SlickValidationIndicatorModule, SlickGoogleAddressSearchModule, SlickConfirmDialogModule, SlickContainerModule, SlickSearchBarModule } from "@slick-components";
import { CustomerEditComponent } from "./customer-edit/customer-edit.component";
import { CustomerMultipleAddressesComponent } from "./customer-edit/customer-multiple-addresses/customer-multiple-addresses.component";
import { CardEditComponent } from "./customer-edit/payment-info/payment-info-edit.component";
import { ContactEditComponent } from "./customer-edit/contacts/contact-edit.component";
import { ContactEditLayoutComponent } from "./customer-edit/contacts/contact-edit-layout/contact-edit-layout.component";
import { ContactEditDialogComponent } from "./customer-edit/contacts/contact-edit-dialog/contact-edit-dialog.component";
import { CrmEditComponent } from "./customer-edit/crm/crm-edit.component";
import { CustomerEditLayoutComponent } from "./customer-edit/customer-edit-layout/customer-edit-layout.component";
import { JobHistoryComponent } from "./customer-edit/job-history/job-history.component";
import { LathamEditComponent } from "./customer-edit/latham-edit/latham-edit.component";
import { CustomerQuickbooksLinkDialogComponent } from "./customer-quickbooks-link-dialog/customer-quickbooks-link-dialog.component";

import { CustomerEditDialogComponent } from "./customer-edit-dialog/customer-edit-dialog.component";
import { ConflictingCustomerDialogModule } from "@shared-components/conflicting-customers-dialog/conflicting-customers-dialog.module";

import { SystemLogsModule } from "@components/system-logs"
import { CustomersService } from "@services";

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ComponentsModule,
		CustomerDisplayModule,
		ProcessPaymentDialogModule,
		CreditCardEditDialogModule,
		SystemLogsModule,
		ConflictingCustomerDialogModule,
		SlickAutoCompleteModule, SlickButtonSpinnerModule, SlickDialogModule, SlickDropDownModule, SlickDatePickerModule, SlickFileDropModule, SlickFileListModule, SlickToastModule, SlickTabsModule, SlickValidationIndicatorModule, SlickGoogleAddressSearchModule, SlickConfirmDialogModule, SlickContainerModule, SlickSearchBarModule ],
	declarations: [
		CustomerEditComponent,
		CustomerEditDialogComponent,
		CardEditComponent,
		CustomerMultipleAddressesComponent,
		ContactEditComponent,
		ContactEditDialogComponent,
		CrmEditComponent,
		CustomerEditLayoutComponent,
		JobHistoryComponent,
		LathamEditComponent,
        ContactEditLayoutComponent,
		CustomerQuickbooksLinkDialogComponent
],
	exports: [CustomerEditComponent, CustomerEditDialogComponent, ContactEditLayoutComponent, CustomerQuickbooksLinkDialogComponent],
	providers: [CustomersService]
})
export class CustomerComponentsModule { }

export { CustomerEditComponent }
export { CustomerEditDialogComponent }
export { ContactEditLayoutComponent }
export { CustomerQuickbooksLinkDialogComponent } 



