import { Component, Injectable } from "@angular/core";
import { IACHPaymentTypeModel, IACHProcessPaymentModel, IACHProcessPaymentResultModel, ICreditCardPaymentTypeModel, ICreditCardProcessPaymentModel, ICreditCardProcessPaymentResult, IPaymentModel, PaymentModel } from "@models";
import { GlobalsService } from "../services.module";
import { PayTraceService } from "./paytrace.service";
import { USAePayService } from "./usaepay.service";
import { ZiftService } from "./zift.service";
import { BamboraService } from "./bambora.service";



export interface ICreditCardService {
    getNewCreditCardPaymentType(customerId: number): Promise<ICreditCardPaymentTypeModel>;
    getCreditCardPaymentType(creditCardPaymentTypeId: number): Promise<ICreditCardPaymentTypeModel>;
    getCreditCardPaymentTypeForCustomer(customerId: number): Promise<ICreditCardPaymentTypeModel[]>;
    getACHPaymentInfoForCustomer(customerId: number): Promise<IACHPaymentTypeModel[]>;
    getACHPaymentType(achPaymentTypeId: number): Promise<IACHPaymentTypeModel>;
    addCreditCardPaymentType(creditCardPaymentTypeModel: ICreditCardPaymentTypeModel): Promise<ICreditCardPaymentTypeModel>;
    updateCreditCardPaymentType(creditCardPaymentTypeModel: ICreditCardPaymentTypeModel): Promise<ICreditCardPaymentTypeModel>;
    deleteCreditCardPaymentType(creditCardPaymentTypeModel: ICreditCardPaymentTypeModel): Promise<ICreditCardPaymentTypeModel>;
    processPrePayment(processCreditCardPaymentModel: ICreditCardProcessPaymentModel): Promise<ICreditCardProcessPaymentResult>;
    processPayment(processCreditCardPaymentModel: ICreditCardProcessPaymentModel): Promise<ICreditCardProcessPaymentResult>;
    voidPayment(paymentModel: IPaymentModel): Promise<IPaymentModel>;
    refundPayment(paymentModel: IPaymentModel, amount: number): Promise<IPaymentModel>;
    processACHPayment(processACHPaymentModel: IACHProcessPaymentModel): Promise<IACHProcessPaymentResultModel>;
    addACHPaymentType(achPaymentTypeModel: IACHPaymentTypeModel): Promise<IACHPaymentTypeModel>;
    updateACHPaymentType(achPaymentTypeModel: IACHPaymentTypeModel): Promise<IACHPaymentTypeModel>;

}



@Injectable()
export class CreditCardService {
    private internalCreditCardService: ICreditCardService;
    
    constructor(
        private usaePayService: USAePayService,
        private bamboraService: BamboraService,
        private payTraceService: PayTraceService,
        private ziftService: ZiftService) {
        if (GlobalsService.company.useBambora)
            this.internalCreditCardService = this.bamboraService;
        else if (GlobalsService.company.usePayTrace)
            this.internalCreditCardService = this.payTraceService;
        else if (GlobalsService.company.useUSAePay)
            this.internalCreditCardService = this.usaePayService;
        else if (GlobalsService.company.useZift)
            this.internalCreditCardService = this.ziftService;
    }


    async getNewCreditCardPaymentType(customerId: number): Promise<ICreditCardPaymentTypeModel> {
        return this.internalCreditCardService.getNewCreditCardPaymentType(customerId);
    }

    async getCreditCardPaymentType(creditCardPaymentTypeId: number): Promise<ICreditCardPaymentTypeModel> {
        return this.internalCreditCardService.getCreditCardPaymentType(creditCardPaymentTypeId);
    }

    async getCreditCardPaymentTypeForCustomer(customerId: number): Promise<ICreditCardPaymentTypeModel[]> {
        return this.internalCreditCardService.getCreditCardPaymentTypeForCustomer(customerId);
    }

    async getACHPaymentTypeForCustomer(customerId: number): Promise<IACHPaymentTypeModel[]> {
        return this.internalCreditCardService.getACHPaymentInfoForCustomer(customerId);
    }

    async getACHPaymentType(achPaymentTypeId: number): Promise<IACHPaymentTypeModel> {
        return this.internalCreditCardService.getACHPaymentType(achPaymentTypeId);
    }

    async addCreditCardPaymentType(creditCardPaymentTypeModel: ICreditCardPaymentTypeModel): Promise<ICreditCardPaymentTypeModel> {
        return this.internalCreditCardService.addCreditCardPaymentType(creditCardPaymentTypeModel);
    }

    async updateCreditCardPaymentType(creditCardPaymentTypeModel: ICreditCardPaymentTypeModel): Promise<ICreditCardPaymentTypeModel> {
        return this.internalCreditCardService.updateCreditCardPaymentType(creditCardPaymentTypeModel);
    }

    async addACHPaymentType(achPaymentTypeModel: IACHPaymentTypeModel): Promise<IACHPaymentTypeModel> {
        return this.internalCreditCardService.addACHPaymentType(achPaymentTypeModel);
    }

    async updateACHPaymentType(achPaymentTypeModel: IACHPaymentTypeModel): Promise<IACHPaymentTypeModel> {
        return this.internalCreditCardService.updateACHPaymentType(achPaymentTypeModel);
    }

    async deleteCreditCardPaymentType(creditCardPaymentTypeModel: ICreditCardPaymentTypeModel): Promise<ICreditCardPaymentTypeModel> {
        return this.internalCreditCardService.deleteCreditCardPaymentType(creditCardPaymentTypeModel);
    }

    async processPrePayment(processCreditCardPaymentModel: ICreditCardProcessPaymentModel): Promise<ICreditCardProcessPaymentResult> {
        return this.internalCreditCardService.processPrePayment(processCreditCardPaymentModel);
    }

    async processPayment(processCreditCardPaymentModel: ICreditCardProcessPaymentModel): Promise<ICreditCardProcessPaymentResult> {
        return this.internalCreditCardService.processPayment(processCreditCardPaymentModel);
    }

    async voidPayment(paymentModel: IPaymentModel): Promise<IPaymentModel> {
        return this.internalCreditCardService.voidPayment(paymentModel);
    }

    async refundPayment(paymentModel: IPaymentModel, amount:number): Promise<IPaymentModel> {
        return this.internalCreditCardService.refundPayment(paymentModel, amount);
    }

    async processACHPayment(processACHPaymentModel: IACHProcessPaymentModel): Promise<IACHProcessPaymentResultModel> {
        return this.internalCreditCardService.processACHPayment(processACHPaymentModel);
    }

}