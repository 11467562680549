import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { SlickAutoCompleteModule, SlickValidationIndicatorModule, SlickDialogModule } from "@slick-components";
import { CreditCardEditDialogModule } from "../../../app-desktop/src/components/process-payment-dialog/credit-card-edit-dialog/credit-card-edit-dialog.module";
import { ComponentsModule } from "@components";

import { CreditCardSelectorDialogComponent } from "./credit-card-selector-dialog.component";

@NgModule({
	imports: [CommonModule,
		ComponentsModule,
		CreditCardEditDialogModule,
		FormsModule,
		SlickAutoCompleteModule, SlickValidationIndicatorModule, SlickDialogModule
	],
	declarations: [CreditCardSelectorDialogComponent],
	exports: [CreditCardSelectorDialogComponent]
})
export class CreditCardSelectorDialogModule { }

export { CreditCardSelectorDialogComponent }
