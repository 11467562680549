import { IACHTransactionLogModel } from "./ach-transaction-log.model";
import { IPaymentModel } from "../payments/payment.model";
import { IAppliedPaymentModel } from "../payments/applied-payment.model";

export interface IACHPaymentApprovedModel {
	achTransactionLogModel: IACHTransactionLogModel;
	paymentModel: IPaymentModel;
	appliedPayment: IAppliedPaymentModel;
}

export class ACHPaymentApprovedModel implements IACHPaymentApprovedModel {
	achTransactionLogModel: IACHTransactionLogModel;
	paymentModel: IPaymentModel;
	appliedPayment: IAppliedPaymentModel;
}
