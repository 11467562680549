<div class="row mt-3 ps-2">
    <div class="col-12 mb-3">
        <h4 class="mb-2">Credit Cards</h4>
        <hr class="mt-0" />
    </div>
    <div class="d-flex flex-wrap align-items-start">
        <!-- Credit Card Items -->
        <ng-container *ngIf="creditCardPaymentTypes?.length > 0;">
            <article class="credit-card-info me-3" *ngFor="let creditcard of creditCardPaymentTypes">
                <button type="button" class="card-ui btn btn-outline-primary"
                        [ngClass]="{ 'btn-defaultCard': creditcard.isDefault }"
                        (click)="selectPaymentType(creditcard); editPaymentType(creditcard);">
                    <div class="col-12 ps-0 pe-0">
                        <span class="col-10 ps-0 pe-0 card-description">{{ creditcard.paymentDescription }}</span>
                        <span class="col-2 ps-0 pe-0 card-icon">
                            <i *ngIf="creditcard.paymentType.toLowerCase().indexOf('visa') > -1" class='fab fa-cc-visa'></i>
                            <i *ngIf="creditcard.paymentType.toLowerCase().indexOf('mastercard') > -1" class='fab fa-cc-mastercard'></i>
                            <i *ngIf="creditcard.paymentType.toLowerCase().indexOf('discover') > -1" class='fab fa-cc-discover'></i>
                            <i *ngIf="creditcard.paymentType.toLowerCase().indexOf('american express') > -1" class='fab fa-cc-amex'></i>
                        </span>
                    </div>
                    <div class="col-12 ps-0 pe-0 card-number">
                        XXXX XXXX XXXX {{ creditcard.accountNumberLastFour }}
                    </div>
                    <form-error *ngIf="!allowAmericanExpress && creditcard.paymentType.toLowerCase().indexOf('american express') > -1">
                        American Express cards are not accepted.
                    </form-error>
                    <div class="col-12 ps-0 pe-0 d-flex justify-content-between">
                        <div style="text-align: left" *ngIf="creditcard.isDefault">Default Card</div>
                        <div style="text-align: right" *ngIf="creditcard.isDefault">{{ creditcard.expDate }}</div>
                    </div>
                    <div style="text-align: right" *ngIf="!creditcard.isDefault">{{ creditcard.expDate }}</div>
                </button>
            </article>
        </ng-container>

        <!-- Add Card Button -->
        <article>
            <button type="button" class="card-ui btn btn-outline-primary add-new-payment-holder me-3" (click)="createNewPaymentType()">
                <div class="add-new-payment"><i class="fa fa-plus"></i> Add Card</div>
            </button>
        </article>
    </div>

</div>


<div *ngIf="usePayTrace" class="row mt-3 ps-2">
    <div class="col-12 mb-3">
        <h4 class="mb-2">ACH</h4>
        <hr class="mt-0" />
    </div>
    <div class="d-flex flex-wrap align-items-start">

        <ng-container>
            <ng-template [ngIf]="achPaymentTypes">

                <article *ngFor="let ach of achPaymentTypes" class="credit-card-info me-3">
                    <button type="button" class="card-ui btn"
                            [ngClass]="{ 'btn-primary': ach.achPaymentTypeId === selectedACHPaymentTypeModel?.achPaymentTypeId,
                                     'btn-outline-primary': ach.achPaymentTypeId !== selectedACHPaymentTypeModel?.achPaymentTypeId }"
                            (click)="selectACHPaymentType(ach); editACHPaymentType(ach)">
                        <div class="col-12 ps-0 pe-0">
                            <span class="col-10 ps-0 pe-0 card-description">{{ ach.paymentDescription }}</span>
                            <span class="col-2 ps-0 pe-0 card-icon">
                                <i class="far fa-pencil ms-2" style="font-size: .8rem" (click)="editACHPaymentType(ach)"></i>
                            </span>
                        </div>
                        <div class="col-12 ps-0 pe-0 card-number">
                            XXX{{ ach.accountNumberLastThree }}
                        </div>
                        <div class="col-12 ps-0 pe-0 default-card" *ngIf="ach.isDefault">
                            Default Account
                        </div>
                    </button>
                </article>


            </ng-template>
            <article *ngIf="customerModel" class="credit-card-info col-6">
                <button type="button" class="card-ui btn btn-outline-primary add-new-payment-holder" (click)="createNewACHPaymentType()">
                    <div class="add-new-payment"><i class="fa fa-plus"></i> Add ACH</div>
                </button>
            </article>
        </ng-container>
    </div>
</div>

<credit-card-edit-dialog #creditCardEditDialog (onChange)="creditCardEditDialogChanges()"></credit-card-edit-dialog>


<slick-dialog [header]="newACHPaymentTypeModel?.achPaymentTypeId ? 'Edit ACH Info' : 'Add ACH Info'" #achEditDialogRef>
    <div *ngIf="newACHPaymentTypeModel" class="ms-3 me-3">

        <div>
            <label for="description">Description</label>
            <input id="description"
                   type="text"
                   class="form-control"
                   [(ngModel)]="newACHPaymentTypeModel.paymentDescription"
                   placeholder="Short Description" />

        </div>
        <div class="mt-2">
            <label for="routingNumber">Routing Number</label>
            <input id="routingNumber"
                   type="text"
                   class="form-control"
                   [(ngModel)]="newACHPaymentTypeModel.routingNumber"
                   placeholder="Routing Number"
                   [slick-validation-indicator]="newACHPaymentTypeModel.routingNumber"
                   maxlength="9"
                   required />
            <form-error *ngIf="invalidRoutingNumber">
                Invalid routing number
            </form-error>
        </div>
        <div class="mt-2">

            <label for="accountNumber">Account Number</label>
            <input id="accountNumber"
                   type="text"
                   class="form-control"
                   [(ngModel)]="newACHPaymentTypeModel.accountNumber"
                   [slick-validation-indicator]="newACHPaymentTypeModel.accountNumber"
                   placeholder="Account Number"
                   required />
            <form-error *ngIf="invalidAccountNumber">
                Invalid account number
            </form-error>
        </div>

        <div class="mt-2">

            <div>
                <label>Address Search</label>
                <input type="search"
                       class="form-control"
                       name="addressSearch"
                       [slick-google-address-search]
                       (onGoogleAddressSelect)="
                            onGoogleMapAddressSelect($event)
                        " />
            </div>
            <div class="mt-2 customer-info form-group">
                <div class="width-full">
                    <label>Address 1</label>
                    <input class="form-control"
                           [(ngModel)]="newACHPaymentTypeModel.addressModel.address1" />
                </div>
            </div>

            <div class="customer-info form-group">
                <div class="width-full">
                    <label>Address 2</label>
                    <input class="form-control"
                           [(ngModel)]="newACHPaymentTypeModel.addressModel.address2" />
                </div>
            </div>

            <div class="d-flex row form-group">
                <div class="col-12 col-lg-6 col-xl">
                    <label>City</label>
                    <input class="form-control"
                           [(ngModel)]="newACHPaymentTypeModel.addressModel.city" />
                </div>

                <div class="col-12 col-lg-6 col-xl-2">
                    <div *ngIf="!isCanada">
                        <label>State</label>
                        <input class="form-control"
                               [(ngModel)]="newACHPaymentTypeModel.addressModel.state" />
                    </div>

                    <div *ngIf="isCanada">
                        <label>Province</label>
                        <input class="form-control"
                               [(ngModel)]="newACHPaymentTypeModel.addressModel.state" />
                    </div>
                </div>

                <div class="col-12 col-lg-6 col-xl-2">
                    <label>Zipcode</label>
                    <input class="form-control"
                           [(ngModel)]="newACHPaymentTypeModel.addressModel.zipcode" />
                </div>

                <div class="col-12 col-lg-6 col-xl-3">
                    <label>Country</label>
                    <slick-drop-down [(ngModel)]="newACHPaymentTypeModel.addressModel.country"
                                     [items]="countries"></slick-drop-down>
                </div>

            </div>
            <div class="row">
                <div class="col-md-12 pad-adjust">
                    <div class="checkbox">
                        <label>
                            <input type="checkbox"
                                   checked
                                   class="text-muted small-font"
                                   [(ngModel)]="newACHPaymentTypeModel.isDefault"> Default Account
                        </label>
                    </div>
                </div>
            </div>

        </div>

    </div>
    <slick-dialog-footer>
        <button class=" btn btn-outline-success me-1" style="width:140px" [slick-button-spinner]="achSaveSpinnerStatus" (click)="saveACHPaymentType()"><i class="bi bi-check-circle"></i> Save</button>
        <button class="btn btn-outline-danger" style="width:140px" (click)="cancelACHPaymentType()"><i class="bi bi-x-circle"></i> Cancel</button>
    </slick-dialog-footer>
</slick-dialog>