import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from "@components";
import { SystemLogsModule } from "@components/system-logs";
import { CustomerSearchModule } from "@components/customer-search";
import { ProcessPaymentDialogModule } from '@components/process-payment-dialog/process-payment-dialog.module';
import { PaymentsComponentsModule } from "@app/payments/payments-components";
import { EmailerDialogModule } from "@components/emailer-dialog";
import { InvoiceCustomFieldsModule } from "@shared-components/invoice-custom-fields"
import { ShippingAddressModule } from "@shared-components/shipping-address";
import { TexterDialogModule } from "@shared-components/texter-dialog";

import { SlickButtonSpinnerModule, SlickContainerModule, SlickCurrencyMaskModule, SlickDatePickerModule, SlickDialogModule, SlickDropDownModule, SlickFileListModule, SlickScreenBlockerModule, SlickTabsModule, SlickToastModule, SlickValidationIndicatorModule } from "@slick-components";

import { LineItemsModule } from "@components/line-items"
import { InventoryLineItemsModule } from "@shared-components/inventory-line-items"

import { SalesOrderEditComponent } from "./sales-order-edit/sales-order-edit.component";
import { SalesOrderEditDialogComponent } from "./sales-order-edit-dialog/sales-order-edit-dialog.component";
import { InvoicesService } from "@services";
import { AddressSearchModule } from "@shared-components/address-search";
import { JellyFishOrderSpecsModule } from "@shared-components/jelly-fish-order-specs/jelly-fish-order-specs.module"
import { CreditCardSelectorDialogModule } from '@shared-components/credit-card-selector-dialog/credit-card-selector-dialog.module';
import { JobCostingDetailsModule } from "@app/job-costing-details/job-costing-details.module";

@NgModule({
	imports: [AddressSearchModule,
		CommonModule,
		FormsModule,
		ComponentsModule,
		SystemLogsModule,
		CustomerSearchModule,
		ProcessPaymentDialogModule,
		CreditCardSelectorDialogModule,
		PaymentsComponentsModule,
		LineItemsModule,
		InventoryLineItemsModule,
		JellyFishOrderSpecsModule,
		JobCostingDetailsModule,
		EmailerDialogModule,
		ShippingAddressModule,
		TexterDialogModule,
		InvoiceCustomFieldsModule,
		SlickButtonSpinnerModule, SlickContainerModule, SlickCurrencyMaskModule, SlickDropDownModule, SlickDialogModule, SlickDatePickerModule, SlickFileListModule, SlickScreenBlockerModule, SlickTabsModule, SlickToastModule, SlickValidationIndicatorModule],
	exports: [SalesOrderEditComponent, SalesOrderEditDialogComponent],
	declarations: [SalesOrderEditComponent, SalesOrderEditDialogComponent],
	providers: [InvoicesService]
})
export class SalesOrdersComponentsModule { }

