import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { ILineItemModel, ICustomerModel, LineItemTypes, IInvoiceModel, ITaxRegionModel, LineItemModel, ILineItemTotalsModel, LineItemTotalsModel, IAddressModel, ITaxRateGroupModel, ITaxRateModel } from "@models";
import { GlobalsService } from "@services/utils/globals.service";
import { LookupService } from "@services/utils/lookup.service";
import { UtilsService } from "@services/utils/utils.service";
import { sortBy } from "sort-by-typescript";

class ItemCache {
	customerId: number;
	sku: string;
	itemModel: ILineItemModel
}

class CereTaxCalculation {
	taxAuthority: string;
	calcBase: number;
	rate: number;
	allocatedTax: number;
	allocatedTaxRounded: number;
}

class FinalTaxCalculations {
	taxAuthority: string;
	totalNotRounded: number;
	totalRoundedAfter: number;
	totalRounded: number;
}

@Injectable()
export class LineItemsService {
	private _itemCache: ItemCache[];

	constructor(private readonly httpService: HttpService, private readonly lookupService: LookupService) {
		this._itemCache = [];
	}

	async refreshPricing(lineItems: ILineItemModel[], customer: ICustomerModel): Promise<ILineItemModel[]> {
		return new Promise<ILineItemModel[]>(async (resolve) => {
			// If there is no customer, just return 0's
			if (!customer || customer.customerId === 0) {
				lineItems = lineItems.map(li => {
					if (li.lineItemType === LineItemTypes.LineItem && li.priceModified === false) {
						li.cost = 0;
						li.price = 0;
					}

					return li;
				});

				resolve(lineItems);
			}
			else {
				// Only get skus where it's a line item and the price hasn't been modified
				const skus = lineItems
					.map(li => {
						if (li.lineItemType === LineItemTypes.LineItem && li.priceModified === false)
							return li.sku;
					})
					.filter(sku => !!sku);

				const refreshedLineItems = await this.getLineItemsBySkus(skus, customer.customerId);
				lineItems.forEach(li => {
					if (li.priceModified === false) {
						const refreshedLineItem = refreshedLineItems.find(x => x.sku.toLowerCase() === li.sku.toLowerCase());
						if (refreshedLineItem)
							li.price = refreshedLineItem.price;
					}
				})

				resolve(lineItems);
			}
		});
	}

	recalculateByInvoice(invoiceModel: IInvoiceModel): ILineItemModel[] {
		return this.recalculate(invoiceModel.lineItems, invoiceModel.customer, invoiceModel.taxRate, invoiceModel.shippingAddress?.taxRates, invoiceModel.customer?.taxExempt, invoiceModel.coloradoShippingSurcharge);
	}

	recalculate(lineItems: ILineItemModel[], customer: ICustomerModel, taxRate: number, taxRates: ITaxRateModel[], taxExempt?: boolean, addColoradoShippingSurcharge?: boolean): ILineItemModel[] {
		if (taxExempt === null || taxExempt === undefined)
			taxExempt = customer?.taxExempt ?? false;

		taxRates = taxRates || [];

		addColoradoShippingSurcharge = addColoradoShippingSurcharge || false;

		let returnLineItems: ILineItemModel[] = [];

		// Start by adding all the line items
		returnLineItems = lineItems.filter(li => li.lineItemType === LineItemTypes.LineItem);

		// Get the subtotals of the existing line items so we can apply any discounts to these
		const lineItemsSubtotals = this.calcItemsSubtotals(returnLineItems);

		// Add any discounts
		// Make an array that has both the customer only discounts and the global discounts
		let allDiscounts = this.lookupService.getDiscounts().filter(x => x.isGlobal);
		let discountAmountToTax: number = 0;
		if (customer && customer.discounts && customer.discounts.length > 0)
			allDiscounts = allDiscounts.concat(customer.discounts);

		allDiscounts.forEach(discount => {
			const existingDiscount = lineItems.find(x => x.lineItemType === LineItemTypes.Discount && x.discountId === discount.discountId);
			if (existingDiscount && existingDiscount.priceModified) {
				returnLineItems.push({ ...existingDiscount });
				if (existingDiscount.taxable)
					discountAmountToTax += existingDiscount.price;
			}
			else {
				const newDiscount = new LineItemModel();

				newDiscount.sku = (discount.hideSKU === true) ? "" : discount.sku;
				newDiscount.discountId = discount.discountId;
				if (discount.hideDescription === false)
					newDiscount.description = discount.description + ((discount.hideDiscountAmount === true) ? "" : (discount.percentAmount) ? " (" + discount.percentAmount + "%)" : " ($" + discount.dollarAmount + ")");
				newDiscount.quantity = 1;
				newDiscount.cost = 0;
				newDiscount.price = 0;
				newDiscount.taxable = true;
				newDiscount.editable = false;
				newDiscount.uuid = UtilsService.newGuid();
				newDiscount.lineItemType = LineItemTypes.Discount;


				if (discount.applyToItemList) {
					let discountLineItemsCost = 0;
					let discountLineItemsPrice = 0;

					let itemFound = false;
					const discountItemList = discount.applyToItemList.split(',');
					discountItemList.forEach(sku => {
						sku = sku.trim().toLowerCase();
						const lineItem = lineItems.find(li => (li.sku || '').toLowerCase() === sku);
						if (lineItem) {
							itemFound = true;
							discountLineItemsCost += lineItem.cost * lineItem.quantity;
                            discountLineItemsPrice += lineItem.price * lineItem.quantity;
                            if (lineItem.taxable)
                                if (discount.percentAmount)
                                    discountAmountToTax += lineItem.price * lineItem.quantity * (discount.percentAmount / 100) * -1;
								else
									discountAmountToTax += (lineItem.price * lineItem.quantity) * discount.dollarAmount * -1;

						}
					});

					// Only apply the discount if one of the items was actually found
					if (itemFound) {
						if (discount.percentAmount) {
							newDiscount.cost = discountLineItemsCost * (discount.percentAmount / 100) * -1;
							newDiscount.price = discountLineItemsPrice * (discount.percentAmount / 100) * -1;
						}
						else if (discount.dollarAmount) {
							newDiscount.cost = discount.dollarAmount * -1;
							newDiscount.price = discount.dollarAmount * -1;
						}
					}
				}
				else {
					if (discount.percentAmount) {
						newDiscount.cost = lineItemsSubtotals.cost * (discount.percentAmount / 100) * -1;
						newDiscount.price = lineItemsSubtotals.price * (discount.percentAmount / 100) * -1;
						discountAmountToTax += this.calcItemsSubtotals(returnLineItems, true).price * (discount.percentAmount / 100) * -1;
					}
					else if (discount.dollarAmount) {
						newDiscount.cost = discount.dollarAmount * -1;
						newDiscount.price = discount.dollarAmount * -1;
						discountAmountToTax += this.calcItemsSubtotals(returnLineItems, true).price * (discount.dollarAmount / 100) * -1;
					}
				

					
				}

				// Don't add the discount line item if it's just $0
				if (newDiscount.cost !== 0 || newDiscount.price !== 0) {
					newDiscount.cost = UtilsService.round(newDiscount.cost);
					newDiscount.price = UtilsService.round(newDiscount.price);

					newDiscount.discountId = discount.discountId;
					newDiscount.quickbooksItemId = (!!discount.syncToQuickbooks === true) ? discount.quickbooksId : null;
					returnLineItems.push(newDiscount);
				}
			}
		});

		// Recalculate it now that we have the discounts
		const itemsSubtotals = this.calcItemsSubtotals(returnLineItems);
		const subtotals = this.calcSubtotals(lineItems);

		let shipping = lineItems.find(li => li.lineItemType === LineItemTypes.Shipping);
		if (!shipping) {
			shipping = new LineItemModel();
			shipping.sku = 'Shipping';
			shipping.description = 'Shipping';
			shipping.quantity = 1;
			shipping.cost = 0;
			shipping.price = 0;
			shipping.taxable = false;
			shipping.editable = false;
			shipping.uuid = UtilsService.newGuid();
			shipping.lineItemType = LineItemTypes.Shipping;
		};
		returnLineItems.push(shipping);

		if (GlobalsService.company.useFuelSurcharge) {
			let fuelSurcharge = lineItems.find(li => li.lineItemType === LineItemTypes.FuelSurcharge);
			if (!fuelSurcharge) {
				fuelSurcharge = new LineItemModel();
				fuelSurcharge.sku = 'Fuel Surcharge';
				fuelSurcharge.description = 'Fuel Surcharge';
				fuelSurcharge.quantity = 1;
				fuelSurcharge.cost = 0;
				fuelSurcharge.price = 0;
				fuelSurcharge.taxable = true;
				fuelSurcharge.editable = false;
				fuelSurcharge.uuid = UtilsService.newGuid();
				fuelSurcharge.lineItemType = LineItemTypes.FuelSurcharge;
			};

			returnLineItems.push(fuelSurcharge);
		}

		if (GlobalsService.company.creditCardProcessingFee && GlobalsService.company.creditCardProcessingFee !== 0) {
			let creditCardProcessingFee = lineItems.find(li => li.lineItemType === LineItemTypes.CreditCardProcessingFee);
			if(creditCardProcessingFee)
				returnLineItems.push(creditCardProcessingFee);
		}

		if (GlobalsService.company.allowTips) {
			let tip = lineItems.find(li => li.lineItemType === LineItemTypes.Tip);
			if (!tip) {
				tip = new LineItemModel();
				tip.sku = 'Tip';
				tip.description = 'Tip';
				tip.quantity = 1;
				tip.cost = 0;
				tip.price = 0;
				tip.taxable = false;
				tip.editable = false;
				tip.uuid = UtilsService.newGuid();
				tip.lineItemType = LineItemTypes.Tip;
			};

			returnLineItems.push(tip);
		}

		let labor = lineItems.find(li => li.lineItemType === LineItemTypes.Labor);
		if (!labor) {
			labor = new LineItemModel();
			labor.sku = '';
			labor.description = '';
			labor.quantity = 1;
			labor.cost = 0;
			labor.price = 0;
			labor.taxable = false;
			labor.editable = false;
			labor.uuid = UtilsService.newGuid();
			labor.lineItemType = LineItemTypes.Labor;
		};
		returnLineItems.push(labor);

		if (GlobalsService.company.useLabor2LineItem === true) {
			let labor2 = lineItems.find(li => li.lineItemType === LineItemTypes.Labor2);
			if (!labor2) {
				labor2 = new LineItemModel();
				labor2.sku = '';
				labor2.description = '';
				labor2.quantity = 1;
				labor2.cost = 0;
				labor2.price = 0;
				labor2.taxable = false;
				labor2.editable = false;
				labor2.uuid = UtilsService.newGuid();
				labor2.lineItemType = LineItemTypes.Labor2;
			};
			returnLineItems.push(labor2);
		}

		if (GlobalsService.company.useLabor3LineItem === true) {
			let labor3 = lineItems.find(li => li.lineItemType === LineItemTypes.Labor3);
			if (!labor3) {
				labor3 = new LineItemModel();
				labor3.sku = '';
				labor3.description = '';
				labor3.quantity = 1;
				labor3.cost = 0;
				labor3.price = 0;
				labor3.taxable = false;
				labor3.editable = false;
				labor3.uuid = UtilsService.newGuid();
				labor3.lineItemType = LineItemTypes.Labor3;
			};
			returnLineItems.push(labor3);
		}

		if (GlobalsService.company.useLabor4LineItem === true) {
			let labor4 = lineItems.find(li => li.lineItemType === LineItemTypes.Labor4);
			if (!labor4) {
				labor4 = new LineItemModel();
				labor4.sku = '';
				labor4.description = '';
				labor4.quantity = 1;
				labor4.cost = 0;
				labor4.price = 0;
				labor4.taxable = false;
				labor4.editable = false;
				labor4.uuid = UtilsService.newGuid();
				labor4.lineItemType = LineItemTypes.Labor4;
			};
			returnLineItems.push(labor4);
		}

		if (addColoradoShippingSurcharge && !taxExempt) {
			//let coloradoShippingSurcharge = lineItems.find(li => li.lineItemType === LineItemTypes.ColoradoShippingSurcharge);
			//if (!coloradoShippingSurcharge) {
			//	coloradoShippingSurcharge = new LineItemModel()
			//	coloradoShippingSurcharge.sku = "Colorado Shipping Surcharge"
			//	coloradoShippingSurcharge.description = "Colorado Shipping Surcharge"
			//	coloradoShippingSurcharge.taxable = false;
			//	coloradoShippingSurcharge.editable = false;
			//	coloradoShippingSurcharge.quantity = 1;
			//	coloradoShippingSurcharge.cost = .28;
			//	coloradoShippingSurcharge.price = .28;
			//	coloradoShippingSurcharge.uuid = UtilsService.newGuid();
			//	coloradoShippingSurcharge.lineItemType = LineItemTypes.ColoradoShippingSurcharge;
			//}
			//returnLineItems.push(coloradoShippingSurcharge);
		}

        // we dont want to calculate tax for mizu until they save so we can skip this.
		if (GlobalsService.company.companyId === 1) {
            let tax = lineItems.find(x => x.lineItemType === LineItemTypes.Tax)
			if (tax) {
				if (!customer.taxExempt)
					returnLineItems.push(tax)
			}
        }
		else {
			if (GlobalsService.company.useCereTax === true) {
				let tax = lineItems.find(li => li.lineItemType === LineItemTypes.Tax);
				if (!tax) {
					tax = new LineItemModel()
					tax.taxable = false;
					tax.editable = false;
					tax.quantity = 1;
					tax.uuid = UtilsService.newGuid();
					tax.lineItemType = LineItemTypes.Tax;
				}

				if (taxExempt) {
					tax.sku = "Tax";
					tax.description = 'Tax Exempt';
					tax.cost = 0;
					tax.price = 0;
				}
				else {
					const cereTaxRate = UtilsService.round(taxRates.reduce((totalTaxRate, taxRate) => (totalTaxRate += taxRate.taxRatePercent), 0));
					let calculations: CereTaxCalculation[] = [];
					let finalCalculations: FinalTaxCalculations[] = [];
					let lineItemsToCalculate = returnLineItems.filter(x => x.lineItemType === LineItemTypes.LineItem && x.taxable);

					let discountToTax = new LineItemModel();
					discountToTax.price = discountAmountToTax;
					discountToTax.cost = 0;
					discountToTax.quantity = 1;
					lineItemsToCalculate.push(discountToTax);
					lineItemsToCalculate.push(discountToTax);

					//populate the models with the info we need
					taxRates.forEach(taxRate => {
						lineItemsToCalculate.forEach(li => {
							let calculation = new CereTaxCalculation();
							calculation.taxAuthority = taxRate.taxAuthority + `_${taxRate.taxRatePercent}`;
							calculation.calcBase = (li.quantity * li.price);
							calculation.rate = (taxRate.taxRatePercent / 100);
							calculation.allocatedTax = (li.quantity * li.price) * (taxRate.taxRatePercent / 100);
							calculation.allocatedTaxRounded = UtilsService.round((li.quantity * li.price) * (taxRate.taxRatePercent / 100), 2)
							calculations.push(calculation);

						})
					});

					// go through and create our "final models" that will be used to calculate the tax
					calculations.forEach(x => {
						let model = finalCalculations.find(c => c.taxAuthority == x.taxAuthority);
						if (!model) {
							model = new FinalTaxCalculations();
							finalCalculations.push(model);
							model.taxAuthority = x.taxAuthority;
							model.totalRounded = 0;
							model.totalNotRounded = 0;
						}
						model.totalRounded += x.allocatedTaxRounded;
						model.totalNotRounded += x.allocatedTax;
					})

					// we need to round the non rounded tax now so that we can find discrepancies
					finalCalculations.forEach(x => x.totalRoundedAfter = UtilsService.round(x.totalNotRounded, 2));

					let amountToSubtract = 0;
					let amountToAdd = 0;
					let totalTax = 0;
					// total rounded is where we round each line item calculation directly after we apply the tax rate to it. we then add up all the rounded values to get "totalRounded"
					// total rounded after is where we perform the line item calculation of applying the tax, then add them all up then round after which is "totalRoundedAfter"
					// go through and find the descrepancies so we can subtract or add a cent. if the total rounded is less than the total that was rounded after, 
					// then we need to add to get up to the rounded after amount. if the total rounded is more than we need to subtract. 
					finalCalculations.forEach(x => {
						totalTax += x.totalRounded;
						if (x.totalRounded < x.totalRoundedAfter) {
							amountToAdd += Math.abs(x.totalRoundedAfter - x.totalRounded)
						}
						else if (x.totalRounded > x.totalRoundedAfter) {
							amountToSubtract += Math.abs(x.totalRoundedAfter - x.totalRounded);
						}
					})

					totalTax -= amountToSubtract;
					totalTax += amountToAdd;
					tax.sku = "Tax";
					tax.description = 'Tax (' + cereTaxRate + '%)';
					tax.cost = totalTax;
					tax.price = totalTax;
				}

				if (tax.price < 0)
					tax.price = 0;

				if (tax.price !== 0)
					returnLineItems.push(tax);

			}
			else if (GlobalsService.company.useAdvancedTax === true) {
				if (taxExempt) {
					const taxRate1 = new LineItemModel();
					taxRate1.sku = 'Tax';
					taxRate1.description = 'Tax Exempt';
					taxRate1.quantity = 1;
					taxRate1.cost = 0;
					taxRate1.price = 0;
					taxRate1.taxable = false;
					taxRate1.editable = false;
					taxRate1.uuid = UtilsService.newGuid();
					taxRate1.lineItemType = LineItemTypes.TaxRate1;
					returnLineItems.push(taxRate1);
				}
				else {
					const taxRegions = this.lookupService.getTaxRegions();
					let taxRegion = null;
					if (customer)
						taxRegion = taxRegions.find(tr => tr.taxRegionId === customer.taxRegionId);

					const taxAmounts = this.calcTaxByTaxRegion(returnLineItems, taxRegion, discountAmountToTax);

					const taxRate1 = new LineItemModel();
					taxRate1.sku = GlobalsService.company.taxRate1Name;
					taxRate1.description = GlobalsService.company.taxRate1Name + ' (' + ((taxRegion) ? taxRegion.taxRate1Percent : "0") + '%)';;
					taxRate1.quantity = 1;
					taxRate1.cost = taxAmounts.taxRate1.cost;
					taxRate1.price = taxAmounts.taxRate1.price;
					taxRate1.taxable = false;
					taxRate1.editable = false;
					taxRate1.uuid = UtilsService.newGuid();
					taxRate1.lineItemType = LineItemTypes.TaxRate1;
					returnLineItems.push(taxRate1);

					if (GlobalsService.company.taxRate2Name && ((taxRegion) ? taxRegion.taxRate2Percent : 0) > 0) {
						const taxRate2 = new LineItemModel();
						taxRate2.sku = GlobalsService.company.taxRate2Name;
						taxRate2.description = GlobalsService.company.taxRate2Name + ' (' + ((taxRegion) ? taxRegion.taxRate2Percent : "0") + '%)';;
						taxRate2.quantity = 1;
						taxRate2.cost = taxAmounts.taxRate2.cost;
						taxRate2.price = taxAmounts.taxRate2.price;
						taxRate2.taxable = false;
						taxRate2.editable = false;
						taxRate2.uuid = UtilsService.newGuid();
						taxRate2.lineItemType = LineItemTypes.TaxRate2;
						returnLineItems.push(taxRate2);
					}

					if (GlobalsService.company.taxRate3Name && ((taxRegion) ? taxRegion.taxRate3Percent : 0) > 0) {
						const taxRate3 = new LineItemModel();
						taxRate3.sku = GlobalsService.company.taxRate3Name;
						taxRate3.description = GlobalsService.company.taxRate3Name + ' (' + ((taxRegion) ? taxRegion.taxRate3Percent : "0") + '%)';;
						taxRate3.quantity = 1;
						taxRate3.cost = taxAmounts.taxRate3.cost;
						taxRate3.price = taxAmounts.taxRate3.price;
						taxRate3.taxable = false;
						taxRate3.editable = false;
						taxRate3.uuid = UtilsService.newGuid();
						taxRate3.lineItemType = LineItemTypes.TaxRate3;
						returnLineItems.push(taxRate3);
					}
				}
			}
			else {
				if (taxExempt) {
					const tax = new LineItemModel()
					tax.sku = "Tax";
					tax.description = 'Tax Exempt';
					tax.quantity = 1;
					tax.cost = 0;
					tax.price = 0;
					tax.taxable = false;
					tax.editable = false;
					tax.uuid = UtilsService.newGuid();
					tax.lineItemType = LineItemTypes.Tax;
					returnLineItems.push(tax);
				}
				else {
					const taxAmounts = this.calcTaxByTaxRate(returnLineItems, taxRate);

					const tax = new LineItemModel()
					tax.sku = "Tax";
					tax.description = 'Tax (' + taxRate + '%)';
					tax.quantity = 1;
					tax.cost = taxAmounts.cost;
					tax.price = taxAmounts.price;
					tax.taxable = false;
					tax.editable = false;
					tax.uuid = UtilsService.newGuid();
					tax.lineItemType = LineItemTypes.Tax;
					returnLineItems.push(tax);
				}
			}
			
		}

		returnLineItems = this.reorderLineItems(returnLineItems);

		return returnLineItems;
	}

	async getLineItemBySku(sku: string, customerId: number): Promise<ILineItemModel> {

		return new Promise<ILineItemModel>(async (resolve) => {
			sku = (sku || '').trim().toLowerCase();

			const cachedItem = this._itemCache.find(i => i.customerId === customerId && i.sku === sku);

			if (cachedItem) {
				resolve(cachedItem.itemModel);
				return;
			}

			const item = await this.httpService.get('/items/getLineItemBySku', { sku: sku, customerId: customerId });

			const newItemCache = new ItemCache();
			newItemCache.sku = sku;
			newItemCache.customerId = customerId;
			newItemCache.itemModel = item;

			this._itemCache.push(newItemCache);
			resolve(item);
		})
	}

	async getLineItemsBySkus(skus: string[], customerId: number): Promise<ILineItemModel[]> {
		return new Promise<ILineItemModel[]>(async (resolve) => {
			const skusNotInCache = skus.filter(sku => {
				// If this is in the cache, no need to load it
				if (this._itemCache.find(i => i.customerId === customerId && i.sku === sku))
					return false;
				else
					return true;
			});

			if (skusNotInCache.length > 0) {
				const skusFromServer = <ILineItemModel[]>(await this.httpService.get('/items/getLineItemsBySkus', { skus: skusNotInCache, customerId: customerId }));
				skusFromServer.forEach(item => {
					const newItemCache = new ItemCache();
					newItemCache.sku = item.sku;
					newItemCache.customerId = customerId;
					newItemCache.itemModel = item;

					this._itemCache.push(newItemCache);
				})
			}

			const lineItemsToReturn = skus
				.map(sku => {
					const cachedItem = this._itemCache.find(i => i.customerId === customerId && i.sku === sku)
					if (cachedItem)
						return cachedItem.itemModel;
					else
						return null;
				})
				.filter(x => x !== null);

			resolve(lineItemsToReturn);
		});

		return
	}

	calcItemsSubtotals(lineItems: ILineItemModel[], includeOnlyTaxable: boolean = false, includeDiscounts: boolean = true): ILineItemTotalsModel {
		const lineItemTotals = new LineItemTotalsModel();

		let lineItemsToCalculate = (includeOnlyTaxable) ? lineItems.filter(x => x.taxable === true) : lineItems;

		lineItemsToCalculate = includeDiscounts ? lineItemsToCalculate : lineItemsToCalculate.filter(x => x.lineItemType !== LineItemTypes.Discount)

		lineItemTotals.cost = lineItemsToCalculate
			.filter(x => x.lineItemType === LineItemTypes.LineItem || x.lineItemType === LineItemTypes.Discount)
			.reduce((acc, li) => UtilsService.round(acc + (li.cost * li.quantity)), 0);

		lineItemTotals.price = lineItemsToCalculate
			.filter(x => x.lineItemType === LineItemTypes.LineItem || x.lineItemType === LineItemTypes.Discount)
			.reduce((acc, li) => UtilsService.round(acc + (li.price * li.quantity)), 0);

		return lineItemTotals;
	}

	calcSubtotals(lineItems: ILineItemModel[]): ILineItemTotalsModel {
		const subtotals = new LineItemTotalsModel();

		const itemsSubtotals = this.calcItemsSubtotals(lineItems);
		subtotals.price = itemsSubtotals.price;
		subtotals.cost = itemsSubtotals.cost;

		lineItems.forEach(li => {
			if (li.lineItemType === LineItemTypes.Labor ||
				li.lineItemType === LineItemTypes.Labor2 ||
				li.lineItemType === LineItemTypes.Labor3 ||
				li.lineItemType === LineItemTypes.Labor4 ||
				li.lineItemType === LineItemTypes.Shipping ||
				li.lineItemType === LineItemTypes.FuelSurcharge ||
				li.lineItemType === LineItemTypes.CreditCardProcessingFee) {

				subtotals.price += li.price * li.quantity;
				subtotals.cost += li.cost * li.quantity;
			}
		})

		subtotals.price = UtilsService.round(subtotals.price);
		subtotals.cost = UtilsService.round(subtotals.cost);

		return subtotals;
	}

	calcTotals(lineItems: ILineItemModel[]): ILineItemTotalsModel {
		const totals = new LineItemTotalsModel();

		const subtotals = this.calcSubtotals(lineItems);
		totals.cost = subtotals.cost;
		totals.price = subtotals.price;

		const tax = lineItems.find(li => li.lineItemType === LineItemTypes.Tax);
		//totals.cost += (tax) ? tax.cost : 0;
		totals.price += (tax) ? tax.price : 0;

		const taxRate1 = lineItems.find(li => li.lineItemType === LineItemTypes.TaxRate1);
		//totals.cost += (taxRate1) ? taxRate1.cost : 0;
		totals.price += (taxRate1) ? taxRate1.price : 0;

		const taxRate2 = lineItems.find(li => li.lineItemType === LineItemTypes.TaxRate2);
		//totals.cost += (taxRate2) ? taxRate2.cost : 0;
		totals.price += (taxRate2) ? taxRate2.price : 0;

		const taxRate3 = lineItems.find(li => li.lineItemType === LineItemTypes.TaxRate3);
		//totals.cost += (taxRate3) ? taxRate3.cost : 0;
		totals.price += (taxRate3) ? taxRate3.price : 0;

		const coloradoShippingSurcharge = lineItems.find(li => li.lineItemType === LineItemTypes.ColoradoShippingSurcharge);
		//totals.cost += (coloradoShippingSurcharge) ? coloradoShippingSurcharge.cost : 0;
		totals.price += (coloradoShippingSurcharge) ? coloradoShippingSurcharge.price : 0;

		const tip = lineItems.find(li => li.lineItemType === LineItemTypes.Tip);
		//totals.cost += (tip) ? tip.cost : 0;
		totals.price += (tip) ? tip.price : 0;

		totals.price = UtilsService.round(totals.price);
		totals.cost = UtilsService.round(totals.cost);

		return totals;
	}

	calcCreditCardProccessingFee(lineItems: ILineItemModel[]) {
		const fee = new LineItemTotalsModel();

		const totals = this.calcTotals(lineItems);
		fee.price = GlobalsService.company.creditCardProcessingFee ? UtilsService.round(totals.price * (GlobalsService.company.creditCardProcessingFee / 100 )) : 0;
		fee.cost = 0;

		return fee;
	}

	private calcTaxByTaxRate(lineItems: ILineItemModel[], taxRate: number) {
		const taxAmounts = {
			cost: 0,
			price: 0
		}

		if (taxRate || 0 > 0) {
			const itemsSubtotals = this.calcItemsSubtotals(lineItems, true);
			taxAmounts.cost = itemsSubtotals.cost;
			taxAmounts.price = itemsSubtotals.price;

			const shipping = lineItems.find(li => li.lineItemType === LineItemTypes.Shipping);
			if (shipping && shipping.taxable === true) {
				taxAmounts.price += shipping.price * shipping.quantity;
				taxAmounts.cost += shipping.cost * shipping.quantity;
			}

			if (GlobalsService.company.useFuelSurcharge) {
				const fuelSurcharge = lineItems.find(li => li.lineItemType === LineItemTypes.FuelSurcharge);
				if (fuelSurcharge) {
					taxAmounts.price += fuelSurcharge.price;
					taxAmounts.cost += fuelSurcharge.cost;
				}
			}

			const labor = lineItems.find(li => li.lineItemType === LineItemTypes.Labor);
			if (labor && labor.taxable === true) {
				taxAmounts.price += labor.price * labor.quantity;
				taxAmounts.cost += labor.cost * labor.quantity;
			}

			const labor2 = lineItems.find(li => li.lineItemType === LineItemTypes.Labor2);
			if (labor2 && labor2.taxable === true) {
				taxAmounts.price += labor2.price * labor2.quantity;
				taxAmounts.cost += labor2.cost * labor2.quantity;
			}

			const labor3 = lineItems.find(li => li.lineItemType === LineItemTypes.Labor3);
			if (labor3 && labor3.taxable === true) {
				taxAmounts.price += labor3.price * labor3.quantity;
				taxAmounts.cost += labor3.cost * labor3.quantity;
			}

			const labor4 = lineItems.find(li => li.lineItemType === LineItemTypes.Labor4);
			if (labor4 && labor4.taxable === true) {
				taxAmounts.price += labor4.price * labor4.quantity;
				taxAmounts.cost += labor4.cost * labor4.quantity;
			}

			taxAmounts.price = taxAmounts.price * (taxRate / 100);
			taxAmounts.cost = taxAmounts.cost * (taxRate / 100);

			taxAmounts.price = UtilsService.round(taxAmounts.price, 2);
			taxAmounts.cost = UtilsService.round(taxAmounts.cost, 2);
		}

		return taxAmounts;
	}

	private calcTaxByTaxRegion(lineItems: ILineItemModel[], taxRegion: ITaxRegionModel, discountAmountToTax: number = 0) {
		const taxAmounts = {
			taxRate1: { price: 0, cost: 0 },
			taxRate2: { price: 0, cost: 0 },
			taxRate3: { price: 0, cost: 0 },
		}

		if (taxRegion) {
			const itemsSubtotals = this.calcItemsSubtotals(lineItems, true, false);

			taxAmounts.taxRate1.price += itemsSubtotals.price;
			taxAmounts.taxRate1.cost += itemsSubtotals.cost;
			taxAmounts.taxRate2.price += itemsSubtotals.price;
			taxAmounts.taxRate2.cost += itemsSubtotals.cost;
			taxAmounts.taxRate3.price += itemsSubtotals.price;
			taxAmounts.taxRate3.cost += itemsSubtotals.cost;

			// Settings determine if we should include labor and shipping in the taxes
			const shipping = lineItems.find(li => li.lineItemType === LineItemTypes.Shipping);
			if (taxRegion.taxRate1IncludeShipping === true) {
				taxAmounts.taxRate1.price += (shipping.price * shipping.quantity);
				taxAmounts.taxRate1.cost += (shipping.cost * shipping.quantity);
			}

			if (taxRegion.taxRate2IncludeShipping === true) {
				taxAmounts.taxRate2.price += (shipping.price * shipping.quantity);
				taxAmounts.taxRate2.cost += (shipping.cost * shipping.quantity);
			}

			if (taxRegion.taxRate3IncludeShipping === true) {
				taxAmounts.taxRate3.price += (shipping.price * shipping.quantity);
				taxAmounts.taxRate3.cost += (shipping.cost * shipping.quantity);
			}

			// Todo: Add a checkbox to Tax Region like labor and shipping to include or not include fuel surcharge
			//if (GlobalsService.company.useFuelSurcharge) {
			//	const fuelSurcharge = lineItems.find(li => li.lineItemType === LineItemTypes.FuelSurcharge);
			//	if (fuelSurcharge) {
			//		taxAmounts.taxRate1.price += fuelSurcharge.price;
			//		taxAmounts.taxRate1.cost += fuelSurcharge.cost;
			//		taxAmounts.taxRate2.price += fuelSurcharge.price;
			//		taxAmounts.taxRate2.cost += fuelSurcharge.cost;
			//		taxAmounts.taxRate3.price += fuelSurcharge.price;
			//		taxAmounts.taxRate3.cost += fuelSurcharge.cost;
			//	}
			//}


			const labor = lineItems.find(li => li.lineItemType === LineItemTypes.Labor);
			const labor2 = lineItems.find(li => li.lineItemType === LineItemTypes.Labor2);
			const labor3 = lineItems.find(li => li.lineItemType === LineItemTypes.Labor3);
			const labor4 = lineItems.find(li => li.lineItemType === LineItemTypes.Labor4);
			if (taxRegion.taxRate1IncludeLabor === true) {
				taxAmounts.taxRate1.price += (labor.price * labor.quantity);
				taxAmounts.taxRate1.cost += (labor.cost * labor.quantity);

				if (GlobalsService.company.useLabor2LineItem === true) {
					taxAmounts.taxRate1.price += (labor2.price * labor2.quantity);
					taxAmounts.taxRate1.cost += (labor2.cost * labor2.quantity);
				}

				if (GlobalsService.company.useLabor3LineItem === true) {
					taxAmounts.taxRate1.price += (labor3.price * labor3.quantity);
					taxAmounts.taxRate1.cost += (labor3.cost * labor3.quantity);
				}

				if (GlobalsService.company.useLabor4LineItem === true) {
					taxAmounts.taxRate1.price += (labor4.price * labor4.quantity);
					taxAmounts.taxRate1.cost += (labor4.cost * labor4.quantity);
				}
			}

			if (taxRegion.taxRate2IncludeLabor === true) {
				taxAmounts.taxRate2.price += (labor.price * labor.quantity);
				taxAmounts.taxRate2.cost += (labor.cost * labor.quantity);

				if (GlobalsService.company.useLabor2LineItem === true) {
					taxAmounts.taxRate2.price += (labor2.price * labor2.quantity);
					taxAmounts.taxRate2.cost += (labor2.cost * labor2.quantity);
				}

				if (GlobalsService.company.useLabor3LineItem === true) {
					taxAmounts.taxRate2.price += (labor3.price * labor3.quantity);
					taxAmounts.taxRate2.cost += (labor3.cost * labor3.quantity);
				}

				if (GlobalsService.company.useLabor4LineItem === true) {
					taxAmounts.taxRate2.price += (labor4.price * labor4.quantity);
					taxAmounts.taxRate2.cost += (labor4.cost * labor4.quantity);
				}
			}

			if (taxRegion.taxRate3IncludeLabor === true) {
				taxAmounts.taxRate3.price += (labor.price * labor.quantity);
				taxAmounts.taxRate3.cost += (labor.cost * labor.quantity);

				if (GlobalsService.company.useLabor2LineItem === true) {
					taxAmounts.taxRate3.price += (labor2.price * labor2.quantity);
					taxAmounts.taxRate3.cost += (labor2.cost * labor2.quantity);
				}

				if (GlobalsService.company.useLabor3LineItem === true) {
					taxAmounts.taxRate3.price += (labor3.price * labor3.quantity);
					taxAmounts.taxRate3.cost += (labor3.cost * labor3.quantity);
				}

				if (GlobalsService.company.useLabor4LineItem === true) {
					taxAmounts.taxRate3.price += (labor4.price * labor4.quantity);
					taxAmounts.taxRate3.cost += (labor4.cost * labor4.quantity);
				}
			}

			taxAmounts.taxRate1.price = UtilsService.round((taxAmounts.taxRate1.price * taxRegion.taxRate1Percent) / 100);
			taxAmounts.taxRate1.cost = UtilsService.round((taxAmounts.taxRate1.cost * taxRegion.taxRate1Percent) / 100);

			taxAmounts.taxRate2.price = UtilsService.round((taxAmounts.taxRate2.price * taxRegion.taxRate2Percent) / 100);
			taxAmounts.taxRate2.cost = UtilsService.round((taxAmounts.taxRate2.cost * taxRegion.taxRate2Percent) / 100);

			taxAmounts.taxRate3.price = UtilsService.round((taxAmounts.taxRate3.price * taxRegion.taxRate3Percent) / 100);
			taxAmounts.taxRate3.cost = UtilsService.round((taxAmounts.taxRate3.cost * taxRegion.taxRate3Percent) / 100);

			if (discountAmountToTax) {
				taxAmounts.taxRate1.price += (discountAmountToTax * taxRegion.taxRate1Percent) / 100;
				taxAmounts.taxRate2.price += (discountAmountToTax * taxRegion.taxRate2Percent) / 100;
				taxAmounts.taxRate3.price += (discountAmountToTax * taxRegion.taxRate3Percent) / 100;
			}

		}

		return taxAmounts;
	}

	calcFuelSurcharge(fuelSurcharge: ILineItemModel, lineItems: ILineItemModel[], address: IAddressModel): ILineItemModel {
		if (!fuelSurcharge.priceModified) {
			fuelSurcharge.cost = 0;
			fuelSurcharge.price = 0;
			fuelSurcharge.sku = "Fuel Surcharge";
			fuelSurcharge.description = "Fuel Surcharge";

			// Start with the default fuel surcharge
			let fuelSurchargeSetting = this.lookupService.getFuelSurcharges().find(x => x.serviceAreaId === null);
			if (address) {

				// Find the service area
				const serviceArea = this.lookupService.getServiceAreas().find(sa => {
					if (address.city != null) {
						sa.cityNames = sa.cityNames || '';
						const cities = sa.cityNames.split(",").map(x => x.trim());
						if (cities.includes(address.city))
							return sa;
					}

					if (address.zipcode != null) {
						sa.zipcodes = sa.zipcodes || '';
						const zipCodes = sa.zipcodes.split(",").map(x => x.trim());
						if (zipCodes.includes(address.zipcode))
							return sa;
					}

					return null;
				});


				if (GlobalsService.company.fuelSurchargeType === 0) {
					if (serviceArea) {
						const dollarFuelSurchargeSetting = this.lookupService.getFuelSurcharges().find(x => x.serviceAreaId === serviceArea.serviceAreaId);

						// If they have set the surcharge $ or % amount to null, then use the default, else set the setting to the service area one
						if (dollarFuelSurchargeSetting.dollarAmount !== null)
							fuelSurchargeSetting = dollarFuelSurchargeSetting;
					}

					fuelSurcharge.cost = fuelSurchargeSetting.dollarAmount || 0;
					fuelSurcharge.price = fuelSurchargeSetting.dollarAmount || 0;
				}
				else if (GlobalsService.company.fuelSurchargeType === 1) {
					if (serviceArea) {
						const percentFuelSurchargeSetting = this.lookupService.getFuelSurcharges().find(x => x.serviceAreaId === serviceArea.serviceAreaId);

						// If they have set the surcharge $ or % amount to null, then use the default, else set the setting to the service area one
						if (percentFuelSurchargeSetting.percentAmount !== null)
							fuelSurchargeSetting = percentFuelSurchargeSetting;
					}

					fuelSurcharge.description = `Fuel Surcharge (${fuelSurchargeSetting.percentAmount}%)`;

					let fuelSurchargeCalcTotal = this.calcItemsSubtotals(lineItems).price;

					let laborTotal = 0;
					if (fuelSurchargeSetting.includeLabor) {
						fuelSurchargeCalcTotal += lineItems.find(x => x.lineItemType === LineItemTypes.Labor)?.price || 0;
						fuelSurchargeCalcTotal += lineItems.find(x => x.lineItemType === LineItemTypes.Labor2)?.price || 0;
						fuelSurchargeCalcTotal += lineItems.find(x => x.lineItemType === LineItemTypes.Labor3)?.price || 0;
						fuelSurchargeCalcTotal += lineItems.find(x => x.lineItemType === LineItemTypes.Labor4)?.price || 0;
					}

					if (fuelSurchargeSetting.includeShipping)
						fuelSurchargeCalcTotal += lineItems.find(x => x.lineItemType === LineItemTypes.Shipping)?.price || 0

					fuelSurcharge.price = UtilsService.round(fuelSurchargeCalcTotal * (fuelSurchargeSetting.percentAmount / 100), 2);
				}
			}

			else if (!address) {
				//if no jobsite address to pull from then we want the default fuel surcharge price from the settings.
				if (GlobalsService.company.fuelSurchargeType === 0) {

					fuelSurcharge.cost = fuelSurchargeSetting.dollarAmount ?? 0;
					fuelSurcharge.price = fuelSurchargeSetting.dollarAmount ?? 0;
				}
				else if (GlobalsService.company.fuelSurchargeType === 1) {

					fuelSurcharge.description = `Fuel Surcharge (${fuelSurchargeSetting.percentAmount}%)`;

					let fuelSurchargeCalcTotal = this.calcItemsSubtotals(lineItems).price;

					if (fuelSurchargeSetting.includeLabor) {
						fuelSurchargeCalcTotal += lineItems.find(x => x.lineItemType === LineItemTypes.Labor)?.price || 0;
						fuelSurchargeCalcTotal += lineItems.find(x => x.lineItemType === LineItemTypes.Labor2)?.price || 0;
						fuelSurchargeCalcTotal += lineItems.find(x => x.lineItemType === LineItemTypes.Labor3)?.price || 0;
						fuelSurchargeCalcTotal += lineItems.find(x => x.lineItemType === LineItemTypes.Labor4)?.price || 0;
					}

					if (fuelSurchargeSetting.includeShipping)
						fuelSurchargeCalcTotal += lineItems.find(x => x.lineItemType === LineItemTypes.Shipping)?.price || 0

					fuelSurcharge.price = UtilsService.round(fuelSurchargeCalcTotal * (fuelSurchargeSetting.percentAmount / 100), 2);
				}
			}
		}

		return fuelSurcharge;
	}

	reorderLineItems(lineItems: ILineItemModel[]): ILineItemModel[] {
		const systemLineItems = lineItems.filter(x => x.lineItemType === LineItemTypes.LineItem && x.isSystemLineItem === true);
		const addedLineItems = lineItems.filter(x => x.lineItemType === LineItemTypes.LineItem && x.isSystemLineItem === false);
		const everythingElse = lineItems.filter(x => x.lineItemType !== LineItemTypes.LineItem);

		systemLineItems.sort(sortBy("displayOrder^"));
		addedLineItems.sort(sortBy("displayOrder^"));


		return [...systemLineItems, ...addedLineItems, ...everythingElse];
	}

}