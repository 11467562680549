import { Component, ViewChild, Output, Input, EventEmitter, SimpleChanges } from "@angular/core";
import { SlickDialogComponent, SlickScreenBlockerService } from "@slick-components";
import { ICustomerModel, ICreditCardPaymentTypeModel, CreditCardPaymentTypeModel, IACHPaymentTypeModel, ACHPaymentTypeModel } from "@models";
import { GlobalsService, PaymentsService, UtilsService } from "@services/services.module";
import { CreditCardEditDialogComponent } from "@components/process-payment-dialog/credit-card-edit-dialog/credit-card-edit-dialog.component";
import { CustomerEditLayoutComponent } from "@app/customers/customer-components/customer-edit/customer-edit-layout/customer-edit-layout.component";
import { CreditCardService } from "@services";

@Component({
	selector: "payment-info-edit",
	templateUrl: "payment-info-edit.component.html",
	styleUrls: ["payment-info-edit.component.scss"],
	providers: [SlickScreenBlockerService, CreditCardService, PaymentsService]
})

export class CardEditComponent {
	@Output() onPaymentTypesChanged: EventEmitter<ICreditCardPaymentTypeModel[]> = new EventEmitter();

	@ViewChild("creditCardDialogRef", { static: true }) creditCardDialogRef: SlickDialogComponent;
	@ViewChild("creditCardEditDialog", { static: true }) creditCardEditDialog: CreditCardEditDialogComponent;
	@ViewChild("achEditDialogRef", { static: true }) achEditDialogRef: SlickDialogComponent;

	@ViewChild("customerEditLayoutRef") customerEditLayoutRef: CustomerEditLayoutComponent;

	@Input() customerModel: ICustomerModel;

	selectedPaymentTypeModel: ICreditCardPaymentTypeModel;
	isCanada = (GlobalsService.company.companyId === 3);
	allowAmericanExpress = GlobalsService.company.allowAmericanExpress;

	creditCardPaymentTypes: ICreditCardPaymentTypeModel[];
	achPaymentTypes: IACHPaymentTypeModel[];
	selectedACHPaymentTypeModel: IACHPaymentTypeModel;
	achSaveSpinnerStatus: string = ''

	newACHPaymentTypeModel: IACHPaymentTypeModel;
	usePayTrace: boolean = GlobalsService.company.usePayTrace;
	invalidAccountNumber: boolean;
	invalidRoutingNumber: boolean;
	constructor(private paymentsService: PaymentsService,
		private creditCardService: CreditCardService) {
	}

	async ngOnChanges(changes: SimpleChanges) {
		if (this.customerModel) {
			this.creditCardPaymentTypes = await this.creditCardService.getCreditCardPaymentTypeForCustomer(this.customerModel.customerId);
			if (this.creditCardPaymentTypes && this.creditCardPaymentTypes.length > 0)
				this.selectPaymentType(this.creditCardPaymentTypes[0]);

			this.onPaymentTypesChanged.emit(this.creditCardPaymentTypes);

			if (this.usePayTrace)
				this.achPaymentTypes = await this.creditCardService.getACHPaymentTypeForCustomer(this.customerModel.customerId);

		}
	}

	async openDialog() {

		this.creditCardPaymentTypes = null;
		this.selectedPaymentTypeModel = null;
		if (this.customerModel)
			this.creditCardPaymentTypes = await this.creditCardService.getCreditCardPaymentTypeForCustomer(this.customerModel.customerId);
		if (this.creditCardPaymentTypes.length > 0) {
			this.selectedPaymentTypeModel = this.creditCardPaymentTypes.find(x => x.isDefault === true);
			if (!this.selectedPaymentTypeModel)
				this.selectedPaymentTypeModel = this.creditCardPaymentTypes[0];
		}
		this.creditCardDialogRef.showDialog();
	}

	async creditCardEditDialogChanges() {
		// Something has changed
		this.creditCardPaymentTypes = await this.creditCardService.getCreditCardPaymentTypeForCustomer(this.customerModel.customerId);
		if (this.creditCardPaymentTypes && this.creditCardPaymentTypes.length > 0)
			this.selectPaymentType(this.creditCardPaymentTypes[0]);
	}

	createNewPaymentType() {
		const paymentType = new CreditCardPaymentTypeModel();
		paymentType.addressModel.address1 = this.customerModel.address1
		paymentType.addressModel.address2 = this.customerModel.address2
		paymentType.addressModel.city = this.customerModel.city
		paymentType.addressModel.state = this.customerModel.state
		paymentType.addressModel.zipcode = this.customerModel.zipcode
		paymentType.addressModel.country = this.customerModel.country
		paymentType.customerId = this.customerModel.customerId;
		this.creditCardEditDialog.openDialog(paymentType, true);
	}

	selectPaymentType(paymentType: ICreditCardPaymentTypeModel) {
		this.selectedPaymentTypeModel = paymentType;
	}

	editPaymentType(paymentType: ICreditCardPaymentTypeModel) {


		this.creditCardEditDialog.openDialog(paymentType, false);
	}


	createNewACHPaymentType() {
		this.newACHPaymentTypeModel = new ACHPaymentTypeModel();
		this.achEditDialogRef.showDialog();
	}

	editACHPaymentType(achPaymentypeToEdit: IACHPaymentTypeModel) {
		this.newACHPaymentTypeModel = UtilsService.clone(achPaymentypeToEdit);
		this.achEditDialogRef.showDialog();
	}

	selectACHPaymentType(achPaymentType: IACHPaymentTypeModel) {
		this.selectedACHPaymentTypeModel = achPaymentType;
	}

	validateACHPaymentType(achPaymentType: IACHPaymentTypeModel) {
		this.invalidAccountNumber = false;
		this.invalidRoutingNumber = false;
		let isValid = true;
		if (!achPaymentType) return false; // Ensure the input is not null or undefined

		// Validate routing number (must be 9 digits for US ACH)
		if (!/^\d{9}$/.test(achPaymentType.routingNumber)) {
			this.invalidRoutingNumber = true;
			isValid = false;
		}

		// Validate account number (must be numeric and non-empty)
		if (!/^\d+$/.test(achPaymentType.accountNumber)) {
			this.invalidAccountNumber = true;
			isValid = false;
		}

		// Ensure account number and routing number are not the same
		if (achPaymentType.routingNumber === achPaymentType.accountNumber) {
			isValid = false;
		}
		if (!achPaymentType.customerId)
			isValid = false;

		return isValid;
	}

	async saveACHPaymentType() {
		try {
			this.achSaveSpinnerStatus = 'spin'
			this.newACHPaymentTypeModel.customerId = this.customerModel?.customerId;
			let valid = await this.validateACHPaymentType(this.newACHPaymentTypeModel);

			if (!valid) {
				this.achSaveSpinnerStatus = 'error'
				return;
			}

			if (this.newACHPaymentTypeModel.achPaymentTypeId)
				await this.creditCardService.updateACHPaymentType(this.newACHPaymentTypeModel);
			else
				await this.creditCardService.addACHPaymentType(this.newACHPaymentTypeModel);

			this.achPaymentTypes = await this.creditCardService.getACHPaymentTypeForCustomer(this.customerModel.customerId);

			if (this.achPaymentTypes && this.achPaymentTypes.length > 0)
				this.selectedACHPaymentTypeModel = this.achPaymentTypes[0];

			this.achSaveSpinnerStatus = 'ok'
			this.achEditDialogRef.hideDialog();
		}
		catch (error) {
			this.achSaveSpinnerStatus = 'error'
		}

	}

	cancelACHPaymentType() {
		this.newACHPaymentTypeModel = null;
		this.achEditDialogRef.hideDialog();
	}

	onGoogleMapAddressSelect(placeResult: google.maps.places.PlaceResult) {
		this.setAddress(placeResult);
	}

	private setAddress(placeResult: google.maps.places.PlaceResult) {
		this.newACHPaymentTypeModel.addressModel.address1 = "";
		this.newACHPaymentTypeModel.addressModel.city = "";
		this.newACHPaymentTypeModel.addressModel.state = "";
		this.newACHPaymentTypeModel.addressModel.zipcode = "";
		this.newACHPaymentTypeModel.addressModel.country = "";

		placeResult.address_components.forEach(adr => {
			adr.types.forEach(type => {
				switch (type) {
					case "street_number":
					case "route":
						this.newACHPaymentTypeModel.addressModel.address1 += adr.long_name + " ";
						break;

					case "locality":
						this.newACHPaymentTypeModel.addressModel.city = adr.long_name;
						break;

					case "administrative_area_level_1":
						this.newACHPaymentTypeModel.addressModel.state = adr.short_name;
						break;

					case "postal_code":
						this.newACHPaymentTypeModel.addressModel.zipcode = adr.short_name;
						break;

					case "country":
						if (adr.short_name === "CA") {
							this.newACHPaymentTypeModel.addressModel.country = "Canada";
						} else if (adr.short_name === "IL") {
							this.newACHPaymentTypeModel.addressModel.country = "Israel";
						} else if (adr.short_name === "MX") {
							this.newACHPaymentTypeModel.addressModel.country = "Mexico";
						} else {
							this.newACHPaymentTypeModel.addressModel.country = adr.short_name;
						}
						break;
				};
			});
		});
	}

}