import { Component, ViewChild, Output, Input, EventEmitter } from "@angular/core";
import { SlickConfirmDialogComponent, SlickDialogComponent, SlickScreenBlockerService } from "@slick-components";
import { ICustomerModel, ICreditCardPaymentTypeModel, CreditCardPaymentTypeModel, ICreditCardProcessPaymentModel, IInvoiceListModel, ICreditCardTransactionLogModel, ICreditCardPaymentApprovedModel, CreditCardPaymentApprovedModel, ICustomerListModel, CustomerModel, IACHTransactionLogModel, IACHProcessPaymentModel, ACHProcessPaymentModel, IACHPaymentTypeModel, IACHPaymentApprovedModel, ACHPaymentTypeModel, ACHPaymentApprovedModel, IEmailerModel, EmailerModel, CreditCardProcessPaymentModel } from "@models";
import { GlobalsService, PaymentsService, CreditCardService, SleepService, CustomersService, UtilsService, LookupService } from "@services";
import { EmailerDialogComponent } from "@components/emailer-dialog";

import { CreditCardEditDialogComponent } from "./credit-card-edit-dialog/credit-card-edit-dialog.component";
import { USAePayService } from "@services/credit-card-processing";


@Component({
	selector: "process-payment-dialog",
	templateUrl: "./process-payment-dialog.component.html",
	styleUrls: ["./process-payment-dialog.component.css"],
	providers: [SlickScreenBlockerService, PaymentsService, CreditCardService, CustomersService]
})

export class ProcessPaymentDialogComponent {
	@Output() onApproved: EventEmitter<ICreditCardPaymentApprovedModel> = new EventEmitter();
	@Output() onACHApproved: EventEmitter<IACHPaymentApprovedModel> = new EventEmitter();
	@Output() onChildPaymentApproved: EventEmitter<ICreditCardPaymentApprovedModel> = new EventEmitter();
	@Output() onPaymentTypesChanged: EventEmitter<ICreditCardPaymentTypeModel[]> = new EventEmitter();
	@Input() searchText: string;
	@Input() useCompanyId: number;
	@Input() amountLocked: boolean = false;

	@ViewChild("customerEditDialogRef", { static: true }) customerEditDialogRef: SlickDialogComponent;
	@ViewChild("processPaymentDialogRef", { static: true }) processPaymentDialogRef: SlickDialogComponent;
	@ViewChild("achEditDialogRef", { static: true }) achEditDialogRef: SlickDialogComponent;
	@ViewChild("creditCardEditDialog", { static: true }) creditCardEditDialog: CreditCardEditDialogComponent;
	@ViewChild("emailerDialogRef") emailerDialogRef: EmailerDialogComponent;
	@ViewChild("cardNotFound") cardNotFound: SlickConfirmDialogComponent;
	customerModel: ICustomerModel;
	newCustomerModel: ICustomerModel;
	selectedPaymentTypeModel: ICreditCardPaymentTypeModel;
	selectedACHPaymentTypeModel: IACHPaymentTypeModel;
	creditCardProcessPaymentModel: ICreditCardProcessPaymentModel;
	creditCardTransactionLogModel: ICreditCardTransactionLogModel;
	achProcessPaymentModel: IACHProcessPaymentModel;
	newACHPaymentTypeModel: IACHPaymentTypeModel;
	achTransactionLogModel: IACHTransactionLogModel;
	prePaymentSpinner: string;
	paymentSpinner: string;
	isQuickbooksEnabled = GlobalsService.isQuickbooksEnabled;
	isCanada = (GlobalsService.company.companyId === 3);
	syncToQuickbooks: boolean;
	customerInvoices: IInvoiceListModel[];
	approvedPaymentUuid: string;
	spinnerStatus: string;
	isSubmitted: boolean;
	paymentApproved: boolean;
	allowAmericanExpress: boolean = GlobalsService.company.allowAmericanExpress;
	showAmericanExpressError: boolean;
	notes: string;
	amount: number;
	invoiceId: number;
	poNumber: string;
	invoiceNumber: string;
	paymentId: number;
	totalCharge: number;
	ccFeeAmount: number;
	ccFeePercent: number = GlobalsService.company.creditCardProcessingFee;
	customerId: number;

	useSkeditPay: boolean = GlobalsService.company.useSkeditPay;

	creditCardPaymentApprovedModel: ICreditCardPaymentApprovedModel;
	creditCardPaymentTypes: ICreditCardPaymentTypeModel[];
	achPaymentApprovedModel: IACHPaymentApprovedModel;
	achPaymentTypes: IACHPaymentTypeModel[];
	achSaveSpinnerStatus: string = ''
	results: ICustomerListModel[];
	selectedCustomerBasic: ICustomerListModel;

	displayCustomerSearch: boolean = false;
	selectedPaymentType: string = 'creditCard'; // Default to Credit Card
	usePayTrace: boolean = GlobalsService.company.usePayTrace;
	invalidRoutingNumber: boolean = false;
	invalidAccountNumber: boolean = false;

	countries: any = [
		{ id: 'US', text: 'US' },
		{ id: 'Canada', text: 'Canada' },
		{ id: 'Mexico', text: 'Mexico' },
		{ id: 'Israel', text: 'Israel' },
	]

	constructor(private customersService: CustomersService,
		private lookupService: LookupService,
		private paymentsService: PaymentsService,
		private slickScreenBlockerService: SlickScreenBlockerService,
		private creditCardService: CreditCardService,
		private USAePayService: USAePayService) {
	}
	setPaymentType(type: string): void {
		this.selectedPaymentType = type;
		if (type === "ach")
			this.ccFeeAmount = 0;
		else
			this.calcCCFee();
	}

	async openDialog(customerModel: ICustomerModel = null, invoiceId: number = null, paymentId: number = null, amount: number = null, invoiceNumber: string = '', poNumber: string = "", selectedCreditCardPaymentTypeId: number = null) {
		if (!customerModel) {
			this.displayCustomerSearch = true;
			this.searchText = '';
		}
		this.paymentApproved = false;
		this.paymentSpinner = "reset";
		this.prePaymentSpinner = "reset";

		this.creditCardPaymentTypes = null;
		this.achPaymentTypes = null;
		this.creditCardTransactionLogModel = null;
		this.selectedPaymentTypeModel = null;
		this.syncToQuickbooks = true;

		this.customerModel = customerModel;
        //if(customerModel)
        //	this.creditCardProcessPaymentModel.customerId = customerModel.customerId;

        this.amount = amount;
        this.invoiceId = invoiceId;
        this.poNumber = poNumber;
		this.invoiceNumber = invoiceNumber;
		this.paymentId = paymentId;
        this.calcCCFee();

		// Get any open invoices for this customer
		//if (!customerModel || this.customerModel.customerId === 0)
		//	this.customerInvoices = [];
		//else
		//	this.customerInvoices = await this.invoicesService.getInvoicesByCustomer(this.customerModel.customerId, true);

		if (this.customerModel?.customerId > 0) {
			//special code for jellyfish. We need to use whatever jellyfish is using
			if (GlobalsService.company.parentCompanyId === 133 && (customerModel.companyId != GlobalsService.company.companyId)) 
				this.creditCardPaymentTypes = await this.USAePayService.getCreditCardPaymentTypeForCustomer(this.customerModel.customerId);
			else
				this.creditCardPaymentTypes = await this.creditCardService.getCreditCardPaymentTypeForCustomer(this.customerModel.customerId);	

			if (this.usePayTrace)
				this.achPaymentTypes = await this.creditCardService.getACHPaymentTypeForCustomer(this.customerModel.customerId);

		}

		this.processPaymentDialogRef.showDialog();

		if (this.creditCardPaymentTypes?.length > 0) {
			if (selectedCreditCardPaymentTypeId) {
				this.selectedPaymentTypeModel = this.creditCardPaymentTypes.find(x => x.creditCardPaymentTypeId === selectedCreditCardPaymentTypeId);
				if (!this.selectedPaymentTypeModel) {
					this.cardNotFound.confirm();
					this.selectedPaymentTypeModel = this.creditCardPaymentTypes[0];
				}
			}
			else {
				this.selectedPaymentTypeModel = this.creditCardPaymentTypes.find(x => x.isDefault === true);
				if (!this.selectedPaymentTypeModel)
					this.selectedPaymentTypeModel = this.creditCardPaymentTypes[0];
			}
		}

		if (this.achPaymentTypes?.length > 0) {
				this.selectedACHPaymentTypeModel = this.achPaymentTypes.find(x => x.isDefault === true);
				if (!this.selectedACHPaymentTypeModel)
					this.selectedACHPaymentTypeModel = this.achPaymentTypes[0];
			
		}
	}

	async calcCCFee() {
		await SleepService.sleep();
		if (GlobalsService.company.creditCardProcessingFee && GlobalsService.company.creditCardProcessingFee !== 0 && this.amount !== 0) {
			this.ccFeeAmount = UtilsService.round(this.amount * (GlobalsService.company.creditCardProcessingFee / 100));
			this.totalCharge = this.amount + this.ccFeeAmount;
		}
		else {
			this.totalCharge = this.amount;
			this.ccFeeAmount = 0;

		}
	}

    async creditCardEditDialogChanges() {
        // Something has changed
		if (GlobalsService.company.parentCompanyId === 133 && (this.customerModel.companyId !== GlobalsService.company.companyId))
			this.creditCardPaymentTypes = await this.USAePayService.getCreditCardPaymentTypeForCustomer(this.customerModel.customerId);
		else
			this.creditCardPaymentTypes = await this.creditCardService.getCreditCardPaymentTypeForCustomer(this.customerModel.customerId);

		if (this.creditCardPaymentTypes && this.creditCardPaymentTypes.length > 0)
			this.selectPaymentType(this.creditCardPaymentTypes[0]);

        this.onPaymentTypesChanged.emit(this.creditCardPaymentTypes);
    }

    createNewPaymentType() {
		const paymentType = new CreditCardPaymentTypeModel();
		paymentType.addressModel.address1 = this.customerModel.address1
		paymentType.addressModel.address2 = this.customerModel.address2
		paymentType.addressModel.city = this.customerModel.city
		paymentType.addressModel.state = this.customerModel.state
		paymentType.addressModel.zipcode = this.customerModel.zipcode
		paymentType.addressModel.country = this.customerModel.country
		paymentType.customerId = this.customerModel.customerId;
        this.creditCardEditDialog.openDialog(paymentType, true, this.customerModel.companyId);
	}

	selectPaymentType(paymentType: ICreditCardPaymentTypeModel) {
		this.selectedPaymentTypeModel = paymentType;
	}

    editPaymentType(paymentType: ICreditCardPaymentTypeModel) {
        this.creditCardEditDialog.openDialog(paymentType, false, this.customerModel.companyId);
	}

	async processPrePayment() {
		try {
			if(this.allowAmericanExpress == false && (this.selectedPaymentTypeModel.paymentType.toLowerCase().indexOf('american express') > -1)){
				this.paymentApproved = false
				this.paymentSpinner = "error";
				this.showAmericanExpressError = true

				return;
			}
			this.prePaymentSpinner = "spin";
			this.creditCardProcessPaymentModel.amount = this.totalCharge;
			this.creditCardProcessPaymentModel.creditCardPaymentTypeId = this.selectedPaymentTypeModel.creditCardPaymentTypeId;
			this.creditCardProcessPaymentModel.syncToQuickbooks = this.syncToQuickbooks;
			const creditCardProcessPaymentResult = await this.creditCardService.processPrePayment(this.creditCardProcessPaymentModel);

			this.creditCardTransactionLogModel = creditCardProcessPaymentResult.creditCardTransactionLogModel;
			
			if (this.creditCardTransactionLogModel.result === 'Approved' || this.creditCardTransactionLogModel.result === 'A01') {
				this.paymentApproved = true;
				const creditCardPaymentApprovedModel: ICreditCardPaymentApprovedModel = new CreditCardPaymentApprovedModel();
				creditCardPaymentApprovedModel.creditCardTransactionLogModel = creditCardProcessPaymentResult.creditCardTransactionLogModel;

				if (this.onApproved)
					this.onApproved.emit(creditCardPaymentApprovedModel);

				this.prePaymentSpinner = "ok";
			}
			else {
				this.paymentApproved = false;
				this.prePaymentSpinner = "error";
			}
		}
		catch (err) {
			this.prePaymentSpinner = "error";
		}
	}

	async processPayment() {
		try {
			if(this.allowAmericanExpress == false && (this.selectedPaymentTypeModel.paymentType.toLowerCase().indexOf('american express') > -1)){
				this.paymentApproved = false
				this.paymentSpinner = "error";
				this.showAmericanExpressError = true

				return;
			}
			this.paymentSpinner = "spin";
			if (this.selectedPaymentType === "creditCard") {
				this.creditCardProcessPaymentModel = new CreditCardProcessPaymentModel();
				this.creditCardProcessPaymentModel.amount = this.totalCharge;
				this.creditCardProcessPaymentModel.notes = this.notes;
				this.creditCardProcessPaymentModel.customerId = this.customerModel.customerId;
				this.creditCardProcessPaymentModel.creditCardPaymentTypeId = this.selectedPaymentTypeModel.creditCardPaymentTypeId;
				this.creditCardProcessPaymentModel.syncToQuickbooks = this.syncToQuickbooks;
				this.creditCardProcessPaymentModel.invoiceId = this.invoiceId;
				this.creditCardProcessPaymentModel.poNumber = this.poNumber;
				this.creditCardProcessPaymentModel.invoiceNumber = this.invoiceNumber;

				// special code for jellyfish, use usaEpay if they are submitting to jellyfish and need a payment
				const creditCardProcessPaymentResult = GlobalsService.company.parentCompanyId === 133
					&& (this.customerModel.companyId != GlobalsService.company.companyId)
					? await this.USAePayService.processPayment(this.creditCardProcessPaymentModel)
					: await this.creditCardService.processPayment(this.creditCardProcessPaymentModel);

				this.creditCardTransactionLogModel = creditCardProcessPaymentResult.creditCardTransactionLogModel;
				//A01 is approved for zift
				if (this.creditCardTransactionLogModel.result === 'Approved' || this.creditCardTransactionLogModel.result === 'A01') {
					this.paymentApproved = true;
					this.creditCardPaymentApprovedModel = new CreditCardPaymentApprovedModel();
					this.creditCardPaymentApprovedModel.creditCardTransactionLogModel = creditCardProcessPaymentResult.creditCardTransactionLogModel;
					this.creditCardPaymentApprovedModel.paymentModel = creditCardProcessPaymentResult.paymentModel;
					this.creditCardPaymentApprovedModel.appliedPayment = creditCardProcessPaymentResult.appliedPaymentModel;
					this.approvedPaymentUuid = this.creditCardPaymentApprovedModel.appliedPayment?.uuid;

					if (GlobalsService.company.parentCompanyId === 133 && (this.customerModel.companyId != GlobalsService.company.companyId))
						this.onChildPaymentApproved.emit(this.creditCardPaymentApprovedModel);
					if (this.onApproved)
						this.onApproved.emit(this.creditCardPaymentApprovedModel);
					this.paymentSpinner = "ok";
				}
			}
			else if (this.selectedPaymentType === "ach") {

				this.achProcessPaymentModel = new ACHProcessPaymentModel();
				this.achProcessPaymentModel.achPaymentTypeId = this.selectedACHPaymentTypeModel.achPaymentTypeId;
				this.achProcessPaymentModel.amount = this.amount;
				this.achProcessPaymentModel.notes = this.notes;
				this.achProcessPaymentModel.syncToQuickbooks = this.syncToQuickbooks;
				this.achProcessPaymentModel.customerId = this.customerModel.customerId;
				this.achProcessPaymentModel.invoiceId = this.invoiceId;
				this.achProcessPaymentModel.poNumber = this.poNumber;
				this.achProcessPaymentModel.invoiceNumber = this.invoiceNumber;

				const achProcessPaymentResult = await this.creditCardService.processACHPayment(this.achProcessPaymentModel);
				this.achTransactionLogModel = achProcessPaymentResult.achTransactionLogModel;

				//A01 is approved for zift
				if (this.achTransactionLogModel.result === 'Approved' || this.achTransactionLogModel.result === 'A01') {
					this.paymentApproved = true;
					this.achPaymentApprovedModel = new ACHPaymentApprovedModel();
					this.achPaymentApprovedModel.achTransactionLogModel = achProcessPaymentResult.achTransactionLogModel;
					this.achPaymentApprovedModel.paymentModel = achProcessPaymentResult.paymentModel;
					this.achPaymentApprovedModel.appliedPayment = achProcessPaymentResult.appliedPaymentModel;
					this.approvedPaymentUuid = this.achPaymentApprovedModel.appliedPayment?.uuid;

					//if (GlobalsService.company.parentCompanyId === 133 && (this.customerModel.companyId != GlobalsService.company.companyId))
					//	this.onChildPaymentApproved.emit(this.achPaymentApprovedModel);
					if (this.onACHApproved)
						this.onACHApproved.emit(this.achPaymentApprovedModel);
					this.paymentSpinner = "ok";

				}
				else {
					this.paymentApproved = false
					this.paymentSpinner = "error";
				}
			}
		}
		catch (err) {
			this.paymentSpinner = "error";
		}
	}

	async printAppliedPaymentReceipt() {
		const w = window.open('', '_blank');
		w.document.write('Generating pdf...');
		const url = await this.paymentsService.generateInvoicePaymentReceiptPdf(this.approvedPaymentUuid);
		w.location.href = url;
	}

	async generatePaymentPdf() {
		const w = window.open('', '_blank');
		w.document.write('Generating pdf...');
		const url = await this.paymentsService.generatePaymentReceiptPDF(this.creditCardPaymentApprovedModel.paymentModel.paymentId);
		w.location.href = url;
	}

	closeDialog() {
		if (this.displayCustomerSearch)
			this.customerModel = null;
		this.searchText = '';
        this.processPaymentDialogRef.hideDialog();
	}

	async onApprovedOk() {
		this.processPaymentDialogRef.hideDialog();
		if (GlobalsService.company.receiptPopUpAfterPayment) {
			this.slickScreenBlockerService.block();
			let emailerModel: IEmailerModel = new EmailerModel();
			if(this.selectedPaymentType === 'creditCard')
				emailerModel = await this.paymentsService.preparePaymentReceiptEmail(this.creditCardPaymentApprovedModel.paymentModel);
			else
				emailerModel = await this.paymentsService.preparePaymentReceiptEmail(this.achPaymentApprovedModel.paymentModel);
			this.slickScreenBlockerService.unblock();
			this.emailerDialogRef.showDialog(emailerModel);
		}
		else {
			await this.paymentsService.emailPaymentReceipt(this.creditCardPaymentApprovedModel.paymentModel);
        }
	}

	async onKeyUp(e: KeyboardEvent) {
		await SleepService.sleep();
		if (!this.searchText && this.customerModel) {
			this.customerModel = null;
		}
	}

	async onBlur() {
		await SleepService.sleep();
		if (!this.searchText && this.customerModel) {
			this.customerModel = null;
		}
	}

	async search(searchText) {
		this.results = await this.customersService.getCustomersForList(searchText, false, false, false, this.useCompanyId);
	}

	async onSelect(customer: ICustomerListModel) {
        this.selectedCustomerBasic = customer;
        this.customerModel = await this.customersService.getCustomer(customer.customerId);
        this.searchText = this.customerModel.companyName && this.customerModel.companyName.length > 0
            ? this.customerModel.companyName
			: this.customerModel.fullName;
		this.creditCardProcessPaymentModel.customerId = customer.customerId;
		this.creditCardPaymentTypes = await this.creditCardService.getCreditCardPaymentTypeForCustomer(this.customerModel.customerId);
		if (this.creditCardPaymentTypes?.length > 0) {
			this.selectedPaymentTypeModel = this.creditCardPaymentTypes.find(x => x.isDefault === true);
			if (!this.selectedPaymentTypeModel)
				this.selectedPaymentTypeModel = this.creditCardPaymentTypes[0];
		}
	}


	async showNewCustomerDialog() {
		this.newCustomerModel = new CustomerModel();
		await this.customerEditDialogRef.showDialog();
	}

	async showEditCustomerDialog() {
		this.newCustomerModel = UtilsService.clone(this.customerModel);
		await this.customerEditDialogRef.showDialog();
	}


	isValid(): boolean {
		let isValid = true;

		if (!this.newCustomerModel.firstName && !this.newCustomerModel.lastName && !this.newCustomerModel.companyName)
			isValid = false;

		return isValid;
	}

	async saveCustomer() {
		this.customerModel = this.newCustomerModel;
		this.isSubmitted = true;
		if (this.isValid() === false) {
			this.spinnerStatus = "error";
			return;
		}

		try {
			this.spinnerStatus = "spin";
			await SleepService.sleep(500);

            if (!this.customerModel.customerId) {
                this.customerModel.customerTypeId = this.lookupService.getCustomerTypes().find(x => x.name === 'Home Owner').customerTypeId;
                this.customerModel.priceLevelId = this.lookupService.getPriceLevels().find(x => x.name === 'Default').priceLevelId;
                this.customerModel.paymentTermId = this.lookupService.getPaymentTerms().find(x => x.name === 'COD').paymentTermId;

                this.customerModel = await this.customersService.addCustomer(this.customerModel);

			}
			else
				this.customerModel = await this.customersService.updateCustomer(this.customerModel);
			
			this.spinnerStatus = "ok";
			this.searchText = this.customerModel.companyName && this.customerModel.companyName.length > 0
				? this.customerModel.companyName
				: this.customerModel.fullName;
			this.customerEditDialogRef.hideDialog();
		} catch (err) {
			this.spinnerStatus = "error";
		}

	}
	async cancelCustomer() {
		this.customerEditDialogRef.hideDialog();
	}

	createNewACHPaymentType() {
		this.newACHPaymentTypeModel = new ACHPaymentTypeModel();
		this.achEditDialogRef.showDialog();
	}

	editACHPaymentType(achPaymentypeToEdit: IACHPaymentTypeModel) {
		this.newACHPaymentTypeModel = UtilsService.clone(achPaymentypeToEdit);
		this.achEditDialogRef.showDialog();
	}

	selectACHPaymentType(achPaymentType: IACHPaymentTypeModel) {
		this.selectedACHPaymentTypeModel = achPaymentType;
	}

	validateACHPaymentType(achPaymentType: IACHPaymentTypeModel) {
		this.invalidAccountNumber = false;
		this.invalidRoutingNumber = false;
		let isValid = true;
		if (!achPaymentType) return false; // Ensure the input is not null or undefined

		// Validate routing number (must be 9 digits for US ACH)
		if (!/^\d{9}$/.test(achPaymentType.routingNumber)) {
			this.invalidRoutingNumber = true;
			isValid = false;
		}

		// Validate account number (must be numeric and non-empty)
		if (!/^\d+$/.test(achPaymentType.accountNumber)) {
			this.invalidAccountNumber = true;
			isValid = false;
		}

		// Ensure account number and routing number are not the same
		if (achPaymentType.routingNumber === achPaymentType.accountNumber) {
			isValid = false;
		}
		if (!achPaymentType.customerId)
			isValid = false;

		return isValid;
	}

	async saveACHPaymentType() {
		try {
			this.achSaveSpinnerStatus = 'spin'
			this.newACHPaymentTypeModel.customerId = this.customerModel?.customerId;
			let valid = await this.validateACHPaymentType(this.newACHPaymentTypeModel);

			if (!valid) {
				this.achSaveSpinnerStatus = 'error'
				return;
			}
			
			if (this.newACHPaymentTypeModel.achPaymentTypeId)
				await this.creditCardService.updateACHPaymentType(this.newACHPaymentTypeModel);
			else
				await this.creditCardService.addACHPaymentType(this.newACHPaymentTypeModel);

			this.achPaymentTypes = await this.creditCardService.getACHPaymentTypeForCustomer(this.customerModel.customerId);

			if (this.achPaymentTypes && this.achPaymentTypes.length > 0)
				this.selectedACHPaymentTypeModel = this.achPaymentTypes[0];

			this.achSaveSpinnerStatus = 'ok'
			this.achEditDialogRef.hideDialog();
		}
		catch (error) {
			this.achSaveSpinnerStatus = 'error'
		}
		
	}

	cancelACHPaymentType() {
		this.newACHPaymentTypeModel = null;
		this.achEditDialogRef.hideDialog();
	}

	onGoogleMapAddressSelect(placeResult: google.maps.places.PlaceResult) {
		this.setAddress(placeResult);
	}

	private setAddress(placeResult: google.maps.places.PlaceResult) {
		this.newACHPaymentTypeModel.addressModel.address1 = "";
		this.newACHPaymentTypeModel.addressModel.city = "";
		this.newACHPaymentTypeModel.addressModel.state = "";
		this.newACHPaymentTypeModel.addressModel.zipcode = "";
		this.newACHPaymentTypeModel.addressModel.country = "";

		placeResult.address_components.forEach(adr => {
			adr.types.forEach(type => {
				switch (type) {
					case "street_number":
					case "route":
						this.newACHPaymentTypeModel.addressModel.address1 += adr.long_name + " ";
						break;

					case "locality":
						this.newACHPaymentTypeModel.addressModel.city = adr.long_name;
						break;

					case "administrative_area_level_1":
						this.newACHPaymentTypeModel.addressModel.state = adr.short_name;
						break;

					case "postal_code":
						this.newACHPaymentTypeModel.addressModel.zipcode = adr.short_name;
						break;

					case "country":
						if (adr.short_name === "CA") {
							this.newACHPaymentTypeModel.addressModel.country = "Canada";
						} else if (adr.short_name === "IL") {
							this.newACHPaymentTypeModel.addressModel.country = "Israel";
						} else if (adr.short_name === "MX") {
							this.newACHPaymentTypeModel.addressModel.country = "Mexico";
						} else {
							this.newACHPaymentTypeModel.addressModel.country = adr.short_name;
						}
						break;
				};
			});
		});
	}
}