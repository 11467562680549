import { Component, Input, OnChanges, Output, EventEmitter, ViewChild, HostBinding } from '@angular/core';
import { SleepService, GlobalsService, LookupService, LineItemsService, JobsService, FunctionLockService, UtilsService, JellyFishService, JobCostingService } from '@services';
import { EstimatesAuthService } from '@services/utils/auth-services/estimates-auth.service';
import { SlickToastService, SlickScreenBlockerService, SlickFileListComponent } from "@slick-components";
import { IInvoiceModel, IDropdownModel, ICustomerModel, CreditCardProcessPaymentModel, ICreditCardPaymentTypeModel, ICreditCardPaymentApprovedModel, IEmailerModel, UserModel, DropdownModel, IDocumentModel, IShippingAddressModel, JellyFishOrderSpecsModel, ILineItemModel, UserLookupModel, AddressModel, IJobCostingSetupModel, JobModel, IACHPaymentApprovedModel } from '@models';
import { InvoicesService } from '@services';
import { ProcessPaymentDialogComponent } from '@components/process-payment-dialog/process-payment-dialog.module';
import { EmailerDialogComponent } from "@components/emailer-dialog";
import * as moment from "moment";
import Swal from 'sweetalert2';
import { TexterDialogComponent } from '@shared-components/texter-dialog/texter-dialog.component';
import { JellyFishSystemLineItemService } from '@services/jelly-fish-system-line-items.service';
import { CreditCardSelectorDialogComponent } from '@shared-components/credit-card-selector-dialog/credit-card-selector-dialog.module';


@Component({
	selector: 'estimate-edit',
	templateUrl: './estimate-edit.component.html',
	styleUrls: ['./estimate-edit.component.scss'],
	providers: [EstimatesAuthService, JellyFishService, JobsService, FunctionLockService, JellyFishSystemLineItemService, JobCostingService]
})
export class EstimateEditComponent implements OnChanges {
	@HostBinding('style.flex') flex = '1';

	@Input() estimateModel: IInvoiceModel;
	@Input() isDialog: boolean = false;
	@Output() onSave = new EventEmitter<IInvoiceModel>();
	@Output() onCancel = new EventEmitter<void>();
	@Output() onDuplicate = new EventEmitter<void>();

	@ViewChild("jobPhotosRef") jobPhotosRef: SlickFileListComponent;
	@ViewChild("creditCardDialogRef") creditCardDialogRef: ProcessPaymentDialogComponent;
	@ViewChild("emailerDialogRef") emailerDialogRef: EmailerDialogComponent;
	@ViewChild("texterDialogRef") texterDialogRef: TexterDialogComponent;
	@ViewChild("creditCardSelectorDialogRef") creditCardSelectorDialogRef: CreditCardSelectorDialogComponent;


	isMizu: boolean = GlobalsService.company.companyId === 1;
	useAdvancedTax: boolean = GlobalsService.company.useAdvancedTax;
	useLathamOrdering = GlobalsService.company.useLathamOrdering;
	paymentTerms: IDropdownModel[];
	spinnerStatus: string = null;
	textSpinnerStatus: string = null;

	isSubmitted = false;
	emailerModel: IEmailerModel;
	tabIndex = 0;
	tabKey: string;
	createdByUser: UserLookupModel;
	downPaymentType: number = GlobalsService.company.downPaymentType || 0;
	showDownPaymentPercent: boolean;
	showDownPaymentDollar: boolean;
	salesReps: IDropdownModel[] = [];
	jobPhotos: IDocumentModel[];
	job: JobModel;
	useJellyFishOrdering: boolean = GlobalsService.company.parentCompanyId === 133 || GlobalsService.company.companyId === 133;

	parentMaxHeight = (GlobalsService.userInfo.layoutSettings.estimatesLayout === 'grid-full-screen') ? "calc(100vh - 140px)" : "calc(100vh - 80px)"
	tabMaxHeight = (GlobalsService.userInfo.layoutSettings.estimatesLayout === 'grid-full-screen') ? "calc(100vh - 264px)" : "calc(100vh - 204px)"

	canAccessJobCosting: boolean = GlobalsService.checkPermission("JobCosting", "Access");

	jobCostingSetupModel: IJobCostingSetupModel;
	isJobCostSetupValid: boolean = true;

	quoteStatuses: IDropdownModel[] = [new DropdownModel("Open", "Open"),
		new DropdownModel("Closed", "Closed"),
		new DropdownModel("Test", "Test")];

	reasons: IDropdownModel[] = [new DropdownModel(1, "Other Mizu quote"),
	new DropdownModel(2, "Went with competitor"),
		new DropdownModel(3, "Did not move forward"),
		new DropdownModel(4, "Other")
];


	detailedReasons: IDropdownModel[] = [new DropdownModel(1, "Price"),
		new DropdownModel(2, "Service"),
		new DropdownModel(3, "Wait time"),
		new DropdownModel(4, "Other")
]

	constructor(
		public estimatesAuthService: EstimatesAuthService,
		private invoicesService: InvoicesService,
		private jellyFishService: JellyFishService,
		private jobsService: JobsService,
		private jobCostingService: JobCostingService,

		private lineItemsService: LineItemsService,
		private lookupService: LookupService,
		private functionLockService: FunctionLockService,
		private slickScreenBlockerService: SlickScreenBlockerService,
		private slickToastService: SlickToastService) {
	}


	async ngOnInit() {
		this.jobCostingSetupModel = await this.lookupService.getJobCostingSetupModel();
	}

	async ngOnChanges() {
		this.isSubmitted = false;
		this.spinnerStatus = "reset";
		this.jobPhotos = null;
		this.job = null;

		this.tabIndex = 0;

		if (this.estimateModel) {
			if (!this.estimateModel.quoteStatus)
				this.estimateModel.quoteStatus = "Open";

			this.salesReps = this.lookupService.getUsers(this.estimateModel.salesRepUserId)
				.filter(x => x.isSalesRep === true)
				.map(x => new DropdownModel(x.userId, x.fullName));
			if (this.estimateModel.invoiceId === 0)
				this.estimateModel.quoteDate = moment().startOf("date").toDate();

			this.paymentTerms = this.lookupService.getPaymentTermsForDropdown(this.estimateModel.paymentTermId);

			if (this.estimateModel.createdBy) {
				this.createdByUser = this.lookupService.getUsers(this.estimateModel.createdBy, true).find(x => x.userId === this.estimateModel.createdBy);
			}

			if(this.estimateModel.jobId){
				this.job = await this.jobsService.getJob(this.estimateModel.jobId);
			}

			// Default to don't show these
			this.showDownPaymentDollar = false;
			this.showDownPaymentPercent = false;

			if (this.downPaymentType === 3 || this.downPaymentType === 4) {
				this.showDownPaymentPercent = (this.downPaymentType === 3);
				this.showDownPaymentDollar = (this.downPaymentType === 4);
				if (this.estimateModel.downPaymentAmount) {
					this.showDownPaymentPercent = false;
					this.showDownPaymentDollar = true;
				}
				else if (this.estimateModel.downPaymentPercent) {
					this.showDownPaymentDollar = false;
					this.showDownPaymentPercent = true;
				}
			}
			
			if (!this.estimateModel.jellyFishOrderSpecsModel && this.useJellyFishOrdering)
				this.estimateModel.jellyFishOrderSpecsModel = new JellyFishOrderSpecsModel();
		}
	}

	async onTabChanged(tabKey: string) {
		this.tabKey = tabKey;

		if (tabKey === "Job Photos" && !this.jobPhotos) {
			this.refreshJobPhotos();
		}

		if (this.tabKey === "Job Costing") {
			if (!this.jobCostingService.isValidJobCostingSetupModel(this.jobCostingSetupModel)) {
				this.isJobCostSetupValid = false;
				return;
			}
		}
	}

	async generateJobCostingReport() {
		try {
			this.slickScreenBlockerService.forceBlock();

			this.estimateModel.jobCostingDetailModel = await this.jobCostingService.generateJobCostingDetail(this.estimateModel.invoiceId, this.estimateModel.jobId);
			this.slickScreenBlockerService.forceUnblock();
			this.slickToastService.showSuccess("Report updated");
		}
		catch {
			this.slickToastService.showDanger("Error generating report")
		}

	}

	onSalesRepSelected(salesRep: IDropdownModel) {
		if (!salesRep) {
			this.estimateModel.salesRepUserId = null;
			this.estimateModel.salesRepFullName = null;

		}
		else {
			this.estimateModel.salesRepUserId = salesRep.id;
			this.estimateModel.salesRepFullName = salesRep.text;
		}
	}

	swapDownPaymentType() {
		if (this.showDownPaymentDollar) {
			this.showDownPaymentDollar = false;
			this.showDownPaymentPercent = true;
			this.estimateModel.downPaymentAmount = 0;
		}
		else {
			this.showDownPaymentDollar = true;
			this.showDownPaymentPercent = false;
			this.estimateModel.downPaymentPercent = 0;
		}
	}

	async onCustomerModelChange(customerModel: ICustomerModel) {
		this.estimateModel.customer = customerModel;
		if (customerModel) {
			// mapping the jobsite address here since we need it for fuel surcharge
			this.estimateModel.jobSiteAddress = this.invoicesService.mapAddressFromCustomer(customerModel);

			this.estimateModel.paymentTermId = customerModel.paymentTermId;
			this.estimateModel.lineItems = await this.lineItemsService.refreshPricing(this.estimateModel.lineItems, customerModel);
			this.estimateModel.pricingChanged = false;
			if (this.estimateModel.customer.salesRepId) {
				this.estimateModel.salesRepUserId = this.estimateModel.customer.salesRepId;
				this.estimateModel.salesRepFullName = this.lookupService.getUsers(this.estimateModel.salesRepUserId).find(x => x.userId === this.estimateModel.salesRepUserId).fullName;
			}

		}
	}

	async onShippingAddressChanged(newShippingAddress: IShippingAddressModel) {
		if (newShippingAddress.address1 != this.estimateModel.shippingAddress.address1 ||
			newShippingAddress.address2 != this.estimateModel.shippingAddress.address2 ||
			newShippingAddress.city != this.estimateModel.shippingAddress.city ||
			newShippingAddress.state != this.estimateModel.shippingAddress.state ||
			newShippingAddress.zipcode != this.estimateModel.shippingAddress.zipcode ||
			newShippingAddress.country != this.estimateModel.shippingAddress.country)
			this.estimateModel.flagSaveForTaxUpdate = true;

		this.estimateModel.shippingAddress = newShippingAddress;
		this.estimateModel.cereTaxRate = newShippingAddress.taxRate;
		this.estimateModel.lineItems = this.lineItemsService.recalculateByInvoice(this.estimateModel);
	}

	async onColoradoShippingSurchargeChange() {
		await SleepService.sleep();

		this.estimateModel.lineItems = this.lineItemsService.recalculateByInvoice(this.estimateModel);
	}

	async convertToSalesOrder(): Promise<IInvoiceModel> {
		try {
			this.estimateModel.isQuote = false
			this.estimateModel.isSalesOrder = true;

			this.estimateModel.invoiceDate = new Date();

			if (GlobalsService.company.useInventory)
				this.estimateModel.inventoryLineItems = await this.invoicesService.syncInventoryFromLineItems(this.estimateModel);

			await this.saveEstimate();

			this.slickToastService.showSuccess("Converted to sales order");
		}
		catch {
			this.slickToastService.showDanger("Error converting to sales order");
		}

		return this.estimateModel;
	}

	async convertToInvoice(): Promise<IInvoiceModel> {
		try {

			this.estimateModel.isQuote = false
			this.estimateModel.isSalesOrder = false;

			this.estimateModel.invoiceDate = new Date();

			if (GlobalsService.company.useInventory)
				this.estimateModel.inventoryLineItems = await this.invoicesService.syncInventoryFromLineItems(this.estimateModel);

			await this.saveEstimate();

			this.slickToastService.showSuccess("Converted to invoice");
		}
		catch {
			this.slickToastService.showDanger("Error converting to invoice");
		}

		return this.estimateModel;
	}

	async onFileCheckChanged(fileModel: any) {
		await this.functionLockService.lock("ON_FILE_CHECK_CHANGED");
		try {
			await this.invoicesService.updateJobPhotoCheckChanged(this.estimateModel.invoiceId, fileModel.documentId, fileModel.isChecked);
		}
		finally {
			this.functionLockService.release("ON_FILE_CHECK_CHANGED");
		}
	}


	async generateEstimatePdf() {
		const w = window.open('', '_blank');
		w.document.write('Generating pdf...');

		const invoiceUrl = await this.invoicesService.generateInvoicePdf(this.estimateModel, false);
		w.location.href = invoiceUrl;
	}

	async generateWorkOrderPdf() {
		const w = window.open('', '_blank');
		w.document.write('Generating pdf...');
		const invoiceUrl = await this.invoicesService.generateWorkOrderPdf(this.estimateModel);
		w.location.href = invoiceUrl;
	}

	async emailEstimate() {
		try {
			this.isSubmitted = true;
			this.spinnerStatus = "spin";
			if (this.isValid() === false) {
				this.spinnerStatus = "error";
				return;
			}
			await this.saveEstimate();
			this.spinnerStatus = "ok"


		} catch {
			this.spinnerStatus = "error"
			return;
		}
		this.slickScreenBlockerService.block();

		try {
			const emailerModel = await this.invoicesService.prepareInvoiceEmail(this.estimateModel);
			this.slickScreenBlockerService.unblock();
			const sentEmail = await this.emailerDialogRef.showDialog(emailerModel);

			if (sentEmail) {
				await this.invoicesService.addInvoiceEmailSentNote(this.estimateModel.invoiceId, false);
			}
		}
		finally {
			this.slickScreenBlockerService.unblock();
		}
	}

	async textEstimate() {
		this.slickScreenBlockerService.block();
		try {
			const textModel = await this.invoicesService.prepareInvoiceText(this.estimateModel);
			this.slickScreenBlockerService.unblock();
			const sent = await this.texterDialogRef.showDialog(textModel);
			if (sent)
				this.slickToastService.showSuccess("Estimate sent");
		}
		finally {
			this.slickScreenBlockerService.unblock();
		}
	}


	duplicateEstimate() {
		this.onDuplicate.emit();
	}

	async prePayment() {
		if (!this.estimateModel.customer) {
			await Swal.fire({
				icon: 'warning',
				title: 'Oops...',
				text: "Please select a customer",
				confirmButtonColor: '#007bff',
				heightAuto: false
			});
			return;
		}

		if (!this.estimateModel.invoiceNumber) {
			await Swal.fire({
				icon: 'warning',
				title: 'Oops...',
				text: "Estimate # is required",
				confirmButtonColor: '#007bff',
				heightAuto: false
			});
			return;
		}

		this.estimateModel = await this.invoicesService.updateInvoice(this.estimateModel);

		const creditCardProcessPaymentModel = new CreditCardProcessPaymentModel();
		creditCardProcessPaymentModel.invoiceId = this.estimateModel.invoiceId;
		creditCardProcessPaymentModel.amount = this.estimateModel.outstandingBalance;
		creditCardProcessPaymentModel.invoiceNumber = this.estimateModel.invoiceNumber;
		creditCardProcessPaymentModel.poNumber = this.estimateModel.purchaseOrderNumber;
		this.creditCardDialogRef.openDialog(this.estimateModel.customer, this.estimateModel.invoiceId, null, this.estimateModel.outstandingBalance, this.estimateModel.invoiceNumber, this.estimateModel.purchaseOrderNumber);
	}

	onPaymentTypesChanged(creditCardPaymentTypeModel: ICreditCardPaymentTypeModel[]) {
		const defaultCard = creditCardPaymentTypeModel.find(x => x.isDefault === true);

		if (defaultCard)
			this.estimateModel.customer.cardOnFile = defaultCard.displayText;
	}

	onApproved(paymentApprovedModel: ICreditCardPaymentApprovedModel) {
		if (paymentApprovedModel.creditCardTransactionLogModel.result === "Approved") {
			if (!this.estimateModel.appliedPayments)
				this.estimateModel.appliedPayments = [];

			this.estimateModel.appliedPayments = [...this.estimateModel.appliedPayments, paymentApprovedModel.appliedPayment];

			if (this.onSave)
				this.onSave.emit(this.estimateModel);
		}
	}

	onACHApproved(paymentApprovedModel: IACHPaymentApprovedModel) {
		if (paymentApprovedModel.achTransactionLogModel.result === "Approved") {
			if (!this.estimateModel.appliedPayments)
				this.estimateModel.appliedPayments = [];

			this.estimateModel.appliedPayments = [...this.estimateModel.appliedPayments, paymentApprovedModel.appliedPayment];

			if (this.onSave)
				this.onSave.emit(this.estimateModel);
		}
	}

	async deleteEstimate() {

	}

	isValid(): boolean {
		if (!this.estimateModel.customer)
			return false;

		if (!this.estimateModel.invoiceNumber)
			return false;

		if (!this.estimateModel.shippingAddress.address1)
			return false;

		if (this.isMizu) {
			if (this.estimateModel.quoteStatus === "Closed" && (!this.estimateModel.reasonId || !this.estimateModel.detailedReasonId)) {
				return false
			}
		}
	}

	async onRefreshPricing() {
		this.estimateModel = await this.invoicesService.refreshPricing(this.estimateModel);
	}

	async saveEstimate() {
		try {
			this.isSubmitted = true;

			if (this.isValid() === false) {
				this.spinnerStatus = "error";
				throw new Error("Estimate is invalid");
			}

			this.spinnerStatus = "spin";

			await SleepService.sleep(500);
			if (this.useJellyFishOrdering && this.estimateModel.jellyFishOrderSpecsModel) {
				this.estimateModel.jellyFishOrderSpecsModel = await this.jellyFishService.saveOrderSpecs(this.estimateModel.jellyFishOrderSpecsModel);
				this.estimateModel.jellyFishOrderSpecsId = this.estimateModel.jellyFishOrderSpecsModel.jellyFishOrderSpecsId;
			}

			this.estimateModel = await this.invoicesService.updateInvoice(this.estimateModel);

			if (this.onSave)
				this.onSave.emit(this.estimateModel);

			this.spinnerStatus = "ok";
		}
		catch (err) {
			this.spinnerStatus = "error";
			console.error("Error in saveEstimate:", err);
			throw new Error("Failed to save estimate"); 
		}
	}

	cancelEstimate() {
		if (this.onCancel)
			this.onCancel.emit();
	}

	async createJellyFishOrder() {
		try {
			let itemsToSend = this.estimateModel.lineItems.filter(x => x.sendToManufacturer && x.isParentItem)
			if (itemsToSend.length === 0) {
				await Swal.fire({
					icon: 'warning',
					title: 'Oops',
					text: 'Cannot create order because all line items are unchecked',
					confirmButtonColor: '#007bff',
					width: '28em',
					heightAuto: false
				});
				return;
			}

			await this.saveEstimate();

			// Check the payment terms to see if payment is required before submission
			// Get the customer that this company is associated with under JellyFish
			const jellyFishCustomer = await this.jellyFishService.getJellyFishCustomer();
			// we need them to be able to choose the card they want to be charged
			const paymentTypeId = await this.creditCardSelectorDialogRef.openDialog(jellyFishCustomer);

			if (!paymentTypeId) {
				return;
			}

			this.estimateModel.selectedCreditCardPaymentTypeId = paymentTypeId;
			this.slickScreenBlockerService.block();
			this.estimateModel = await this.jellyFishService.createOrderFromInvoice(this.estimateModel);
			this.slickToastService.showSuccess("Order Created");
			this.slickScreenBlockerService.unblock();
		}
		catch {
			this.slickScreenBlockerService.unblock();
			this.slickToastService.showDanger("Failed to create order");
		}
	}

	onLineItemsChanged(lineItems: ILineItemModel[]) {
		this.estimateModel.flagSaveForTaxUpdate = true;
		this.estimateModel.lineItems = [...lineItems];
	}

	private async refreshJobPhotos() {
		this.jobPhotos = await this.jobsService.getJobPhotos(this.estimateModel.jobId);

		if (this.jobPhotos?.length > 0 && this.estimateModel.selectedJobPhotoDocumentIds?.length > 0) {
			this.jobPhotos.forEach(p => {
				const isChecked = this.estimateModel.selectedJobPhotoDocumentIds.find(x => x === p.documentId);
				if (isChecked)
					p.isChecked = true;
			});
		}

	}
}
