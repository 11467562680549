<slick-container *ngIf="estimateModel" maxHeight="calc(100vh - 130px)">
    <slick-container-body>
        <slick-tabs [(tabIndex)]="tabIndex" (tabChange)="onTabChanged($event)">
            <slick-tab header="Estimate" icon="fa-calculator">

                <slick-container maxHeight="calc(100vh - 265px)">
                    <slick-container-body>
                        <div class="bold-header" style="display: flex; align-items: center; padding-top: 5px !important;">
                            <div style="flex-grow: 1;">Customer Info</div>
                            <div class="gray-line"></div>
                        </div>
                        <div class="d-flex flex-column mt-2" style="min-width: 600px;">
                            <!-- Top Column -->
                            <div class="d-flex">

                                <div class="d-flex flex-fill">
                                    <!-- Billing Customer-->
                                    <div class="width-half me-3">
                                        <label class="mt-1" style="padding-bottom: 4px;"><b>Billing Customer</b></label>
                                        <customer-search [customerModel]="estimateModel.customer"
                                                         (customerModelChange)="onCustomerModelChange($event)"
                                                         [isBillingCustomer]="true"
                                                         [disableButtons]="!estimatesAuthService.canEdit()"></customer-search>
                                        <form-error *ngIf="!estimateModel.customer && isSubmitted === true">Customer is required</form-error>
                                    </div>

                                    <!-- Shipping Address -->
                                    <div class="width-half me-3" style="font-size:14px;">
                                        <shipping-address [customer]="estimateModel.customer"
                                                          [shippingAddress]="estimateModel.shippingAddress"
                                                          [lathamOrderId]="estimateModel.lathamOrderId"
                                                          (shippingAddressChange)="onShippingAddressChanged($event)"></shipping-address>
                                        <form-error *ngIf="!this.estimateModel.shippingAddress?.address1 && this.isSubmitted">An address is required</form-error>
                                    </div>
                                </div>

                                <div class="ms-3 mt-4 text-end" style="font-size: 14px; line-height: 16px;" *ngIf="estimateModel.jobId">
                                    <div *ngIf="job?.jobName">Job Name: {{ job?.jobName }}</div>
                                    <div *ngIf="job?.jobCustomer.displayName">Job Customer: {{ job?.jobCustomer.displayName }}</div>
                                    <div *ngIf="job?.jobTypeDisplayName" class="d-flex justify-content-end align-items-center">
                                        <div class="me-1 text-end">
                                            Job Type:
                                        </div>
                                        <div style="max-width: 140px; text-align: left;">
                                            {{ job?.jobTypeDisplayName }}
                                        </div>
                                    </div>
                                    <div *ngIf="estimateModel.preAuthMessage">{{estimateModel.preAuthMessage}}</div>
                                </div>
                            </div>

                            <div *ngIf="estimateModel.customer?.billingNotes" class="row col-12 mt-3">
                                <div class="d-flex flex-fill" style="max-width: 100%;">
                                    <div class="flex-fill">
                                        <label>Customer Billing Notes</label>
                                        <textarea class='form-control' [(ngModel)]='estimateModel.customer.billingNotes' style='min-height: 100px'></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="mt-3">

                                <div class="bold-header mb-1" style="display: flex; align-items: center; padding-top: 5px !important;">
                                    <div style="flex-grow: 1;">Estimate Info</div>
                                    <div class="gray-line"></div>
                                </div>

                                <div class="d-flex align-items-center">
                                    <div class="flex-grow-1 me-3">
                                        <label>Estimate #</label>
                                        <input type="text" class="form-control" [(ngModel)]="estimateModel.invoiceNumber" [slick-validation-indicator]="estimateModel.invoiceNumber" />
                                        <form-error *ngIf="!estimateModel.invoiceNumber && isSubmitted === true">Estimate # is required</form-error>
                                    </div>
                                    <div class="flex-grow-1 me-3 ms-1">
                                        <label>PO #</label>
                                        <input type="text" class="form-control" [maxlength]="isMizu ? 12 : null" [(ngModel)]="estimateModel.purchaseOrderNumber" [slick-validation-indicator]="estimateModel.purchaseOrderNumber" slickValidationType="warning" />
                                    </div>
                                    <div class="flex-shrink-0 text-end" style="width: auto;" *ngIf="estimateModel.createdBy && createdByUser">
                                        <label>Created By: {{createdByUser.fullName}}</label>
                                        <div *ngIf="estimateModel.acceptedDate">Accepted on {{estimateModel.acceptedDate | date:'M/d/yyyy'}} by {{estimateModel.acceptedSignature}}</div>
                                        <div *ngIf="!estimateModel.acceptedDate">Not accepted</div>
                                        <div *ngIf="estimateModel.preAuthMessage">{{estimateModel.preAuthMessage}}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col">
                                    <label>Date</label>
                                    <input type="text" class="form-control" slick-date-picker [(ngModel)]="estimateModel.quoteDate" [slick-validation-indicator]="estimateModel.quoteDate" slickValidationType="warning" />
                                </div>

                                <div class="col">
                                    <label>Valid Until</label>
                                    <input type="text" class="form-control" slick-date-picker [(ngModel)]="estimateModel.quoteValidUntilDate" [slick-validation-indicator]="estimateModel.quoteValidUntilDate" slickValidationType="warning" />
                                </div>

                                <div class="col" *ngIf="salesReps.length > 0">
                                    <label>Sales Rep</label>
                                    <slick-drop-down [ngModel]="estimateModel.salesRepUserId"
                                                     [items]="salesReps"
                                                     allowEmpty="true"
                                                     placeholder="None"
                                                     (onSelect)="onSalesRepSelected($event)"></slick-drop-down>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col">
                                    <label>Payment Terms</label>
                                    <slick-drop-down [(ngModel)]="estimateModel.paymentTermId"
                                                     [items]="paymentTerms"
                                                     allowEmpty="false"
                                                     placeholder="Payment Term"
                                                     [validationIndicator]="estimateModel.paymentTermId"></slick-drop-down>
                                </div>

                                <div *ngIf="downPaymentType === 1" class="col">
                                    <label>Down Payment %</label>
                                    <input type="number" step="1" class="form-control" style="text-align: right;" [(ngModel)]="estimateModel.downPaymentPercent" />
                                </div>

                                <div *ngIf="downPaymentType === 2" class="col">
                                    <label>Down Payment</label>
                                    <input type="text" class="form-control" [slick-currency-mask] [(ngModel)]="estimateModel.downPaymentAmount" />
                                </div>

                                <div *ngIf="showDownPaymentPercent" class="col">
                                    <label>Down Pmt (%)&nbsp;<button type="button" style="font-size: 10px;" class="btn btn-link p-0 m-0" (click)="swapDownPaymentType()"><i class="far fa-sync"></i></button></label>
                                    <input type="number" step="1" class="form-control" style="text-align: right;" [(ngModel)]="estimateModel.downPaymentPercent" />
                                </div>

                                <div *ngIf="showDownPaymentDollar" class="col">
                                    <label>Down Pmt ($)&nbsp;<button type="button" style="font-size: 10px;" class="btn btn-link p-0 m-0" (click)="swapDownPaymentType()"><i class="far fa-sync"></i></button></label>
                                    <input type="text" class="form-control" [slick-currency-mask] [(ngModel)]="estimateModel.downPaymentAmount" />
                                </div>

                                <div *ngIf="useAdvancedTax === false" class="col">
                                    <label>Tax Rate</label>
                                    <input type="number" class="form-control" [(ngModel)]="estimateModel.taxRate" [slick-validation-indicator]="estimateModel.taxRate !== null" slickValidationType="warning" />
                                </div>

                            </div>

                            <div class="row mt-3" *ngIf="isMizu">

                                <div class="col" style="max-width:calc(25%)">
                                    <label>Quote Status</label>
                                    <slick-drop-down [(ngModel)]="estimateModel.quoteStatus"
                                                     [items]="quoteStatuses"
                                                     allowEmpty="false"
                                                     [validationIndicator]="estimateModel.quoteStatus"></slick-drop-down>
                                </div>

                                <div class="col" *ngIf="estimateModel.quoteStatus === 'Closed'">
                                    <label>Closure Details</label>
                                    <slick-drop-down [(ngModel)]="estimateModel.reasonId"
                                                     [items]="reasons"
                                                     allowEmpty="false"
                                                     [validationIndicator]="estimateModel.reasonId"></slick-drop-down>
                                </div>

                                <div class="col" *ngIf="estimateModel.quoteStatus === 'Closed'">
                                    <label>Reason</label>
                                    <slick-drop-down [(ngModel)]="estimateModel.detailedReasonId"
                                                     [items]="detailedReasons"
                                                     allowEmpty="false"
                                                     [validationIndicator]="estimateModel.detailedReasonId"></slick-drop-down>
                                </div>

                                <div class="col" *ngIf="estimateModel.quoteStatus === 'Closed'">
                                    <label>Closure Notes</label>
                                    <textarea type="text"
                                              class="form-control"
                                              style="line-height: 1rem"
                                              [(ngModel)]="estimateModel.reasonNotes"
                                              rows="4"></textarea>
                                </div>

                                <form-error *ngIf="this.estimateModel.quoteStatus === 'Closed' && this.isSubmitted && (!this.estimateModel.reasonId || !this.estimateModel.detailedReasonId)">Closure details and reason are required.</form-error>
                            </div>



                            <!--<div class="row">
        <div *ngIf="estimateModel?.shippingAddress?.state === 'CO'" class="mt-3">
            <input type="checkbox" [(ngModel)]="estimateModel.coloradoShippingSurcharge" (change)="onColoradoShippingSurchargeChange()" />Colorado shipping surcharge
        </div>
    </div>-->

                            <div class="mt-3">
                                <invoice-custom-fields [(invoice)]="estimateModel"></invoice-custom-fields>
                            </div>

                            <div class="d-flex flex-fill mb-3 mt-2" style="min-width: 66%;">
                                <div class="flex-fill me-3">
                                    <label>Notes</label>
                                    <textarea class='form-control' [(ngModel)]='estimateModel.notes' style='min-height: 100px'></textarea>
                                </div>

                                <div class="flex-fill" [ngClass]="{ 'me-3' : useLathamOrdering}">
                                    <label>Customer Notes</label>
                                    <textarea class='form-control' [(ngModel)]='estimateModel.customerNotes' style='min-height: 100px'></textarea>
                                </div>

                                <div class="flex-fill" *ngIf="useLathamOrdering">
                                    <label>Latham Order Notes</label>
                                    <textarea class='form-control bg-white text-dark' style="min-height: 100px; line-height: 1rem;" readonly>{{estimateModel.lathamOrderNotes}}</textarea>
                                </div>
                            </div>

                            <div class="bold-header mb-1" style="display: flex; align-items: center; padding-top: 5px !important;">
                                <div style="flex-grow: 1;">Line Items</div>
                                <div class="gray-line"></div>
                            </div>

                            <div class="mt-1" *ngIf="useJellyFishOrdering">

                                <!-- LOGO HEADER -->
                                <div class="logo-header" style="display: flex; align-items: center;">
                                    <div style="flex-grow: 1;">
                                        <i><img src="../../../content/images/jelly-fish-lighting-logo.png" style="max-width: 150px; max-height: 150px;" /></i>
                                    </div>
                                    <div class="gray-line"></div>
                                </div>

                                <!-- SPECS -->
                                <div class="mt-3 mb-1" *ngIf="estimateModel.jellyFishOrderSpecsModel">
                                    <jelly-fish-order-specs [(orderSpecs)]="estimateModel.jellyFishOrderSpecsModel"
                                                            [customerId]="estimateModel.customer?.customerId"
                                                            (onLineItemsChanged)="onLineItemsChanged($event)"
                                                            [lineItems]="estimateModel.lineItems"
                                                            [invoiceModel]="estimateModel"
                                                            [locked]="estimateModel.lockJellyFishSpecs"></jelly-fish-order-specs>
                                </div>
                            </div>

                            <!-- QUICK ADDS FOR JELLYFISH -->
                            <div *ngIf="useJellyFishOrdering" class="mt-2 mb-2">
                                <quick-adds [(lineItems)]="estimateModel.lineItems"
                                            (lineItemsChange)="onLineItemsChanged($event)"
                                            [customerId]="estimateModel?.customer?.customerId"></quick-adds>
                            </div>


                            <!-- Line Items -->
                            <div class="mb-2" style="max-width:100%;">
                                <line-items [(lineItems)]="estimateModel.lineItems"
                                            [(amountDue)]="estimateModel.outstandingBalance"
                                            [invoiceModel]="estimateModel"
                                            [appliedPayments]="estimateModel.appliedPayments"
                                            [invoiceGroupSeq]="estimateModel.invoiceGroupSeq"
                                            [customer]="estimateModel.customer"
                                            [jobSiteAddress]="estimateModel.jobSiteAddress"
                                            [shippingAddress]="estimateModel.shippingAddress"
                                            [taxRate]="estimateModel.taxRate"
                                            [pricingChanged]="estimateModel.pricingChanged"
                                            (onRefreshPricing)="onRefreshPricing()"
                                            [showSendToCheckBox]="useJellyFishOrdering"></line-items>

                                <div class="mt-4" *ngIf="!useJellyFishOrdering">
                                    <quick-adds [(lineItems)]="estimateModel.lineItems"
                                                (lineItemsChange)="onLineItemsChanged($event)"
                                                [customerId]="estimateModel?.customer?.customerId"></quick-adds>
                                </div>
                            </div>
                        </div>
</slick-container-body>
                </slick-container>

            </slick-tab>

            <slick-tab *ngIf="estimateModel.invoiceId !== 0 && estimateModel.jobId != null && canAccessJobCosting" header="Job Costing" icon="bi-graph-up-arrow">
                <slick-container maxHeight="calc(100vh - 250px)">
                    <slick-container-body *ngIf="tabKey === 'Job Costing'">
                        <div *ngIf="isJobCostSetupValid">
                            <div *ngIf="!estimateModel.jobCostingDetailModel">
                                <button class="btn btn-outline-primary" (click)="generateJobCostingReport()">Generate Job Costing Report</button>
                            </div>
                            <div *ngIf="estimateModel.jobCostingDetailModel">
                                <button class="btn btn-outline-primary" (click)="generateJobCostingReport()">Regenerate Job Costing Report</button>
                            </div>
                        </div>
                        <job-costing-details [isValid]="isJobCostSetupValid" [jobCostingDetailModel]="estimateModel.jobCostingDetailModel"></job-costing-details>
                    </slick-container-body>
                </slick-container>
            </slick-tab>

            <slick-tab header="Photos" icon="fa-camera">
                <slick-container maxHeight="calc(100vh - 265px)">
                    <slick-container-body>
                        <documents-edit *ngIf="tabKey === 'Photos'"
                                        [documentKey]="estimateModel.documentKey"
                                        title="Estimate Photos"
                                        documentType="Invoices"
                                        folderType="photos"
                                        [canDelete]="true"
                                        [hideFileDrop]="!estimatesAuthService.canEdit()"></documents-edit>
                    </slick-container-body>
                </slick-container>
            </slick-tab>

            <slick-tab *ngIf="estimateModel.jobId" header="Job Photos" icon="fa-camera">
                <slick-container maxHeight="calc(100vh - 265px)">
                    <slick-container-body>
                        <div *ngIf="!jobPhotos">Loading...</div>
                        <div *ngIf="jobPhotos?.length === 0">No Photos</div>
                        <div *ngIf="jobPhotos?.length > 0">
                            <slick-file-list #jobPhotosRef
                                             [files]="jobPhotos"
                                             [thumbnailSize]="120"
                                             [showPhotoGallery]="true"
                                             [showCheckboxes]="true"
                                             [allowDelete]="false"
                                             (onFileCheckChanged)="onFileCheckChanged($event)"></slick-file-list>
                        </div>
                    </slick-container-body>
                </slick-container>
            </slick-tab>

            <slick-tab header="Documents" icon="fa-file-alt">
                <slick-container maxHeight="calc(100vh - 265px)">
                    <slick-container-body>
                        <documents-edit *ngIf="tabKey === 'Documents'"
                                        [documentKey]="estimateModel.documentKey"
                                        documentType="Invoices"
                                        title="Estimate Documents"
                                        folderType="documents"
                                        [canDelete]="true"
                                        [hideFileDrop]="!estimatesAuthService.canEdit()"></documents-edit>
                    </slick-container-body>
                </slick-container>
            </slick-tab>

            <slick-tab header="System Logs" icon="fa-info-circle">
                <slick-container maxHeight="calc(100vh - 265px)">
                    <slick-container-body>
                        <system-logs *ngIf="tabKey === 'System Logs'" noteType="2" [referenceId]="estimateModel.invoiceId"></system-logs>
                    </slick-container-body>
                </slick-container>
            </slick-tab>
        </slick-tabs>

    </slick-container-body>

    <slick-container-footer *ngIf="!isDialog">
        <div class="d-flex flex-column" *ngIf="estimatesAuthService.canEdit()">
            <div class="mt-2 pt-2 mb-3 flex-column">
                <button type="button" [slick-button-spinner]="spinnerStatus" class="btn btn-outline-success me-2" style="width: 140px;" (click)="saveEstimate()"><i class="bi bi-check-circle"></i> Save</button>
                <button type="button" class="btn btn-outline-danger" style="width: 140px;" (click)="cancelEstimate()"><i class="bi bi-x-circle"></i> Cancel</button>
                <div class=" me-3" style="float: right">
                    <div class="btn-group" role="group">
                        <button type="button" [disabled]="estimateModel.invoiceId === 0" class="btn btn-outline-primary" title="Convert to Sales Order" (click)="convertToSalesOrder()"><i class="far fa-dollar-sign"></i></button>
                        <button type="button" [disabled]="estimateModel.invoiceId === 0" class="btn btn-outline-primary" title="Convert to Invoice" (click)="convertToInvoice()"><i class="far fa-file-invoice-dollar"></i></button>
                        <button type="button" [disabled]="estimateModel.invoiceId === 0" class="btn btn-outline-primary" title="Print Estimate" (click)="generateEstimatePdf()"><i class="far fa-print"></i></button>
                        <button type="button" [disabled]="estimateModel.invoiceId === 0" class="btn btn-outline-primary" title="Print Work Order" (click)="generateWorkOrderPdf()">
                            <div style="position: absolute; font-size: 6px; border-radius: 6px; right: 6px; top: 18px; line-height: 12px; min-width: 12px; padding: 0;" class="bg-dark text-white">WO</div>
                            <i class="far fa-print"></i>
                        </button>
                        <button type="button" [disabled]="estimateModel.invoiceId === 0" class="btn btn-outline-primary" title="Email Estimate" (click)="emailEstimate()"><i class="far fa-envelope"></i></button>
                        <button type="button" [disabled]="estimateModel.invoiceId === 0" class="btn btn-outline-primary" title="Text Estimate" (click)="textEstimate()"><i class="far fa-comment-alt-lines"></i></button>
                        <button type="button" [disabled]="estimateModel.invoiceId === 0" class="btn btn-outline-primary" title="Duplicate to new Estimate" (click)="duplicateEstimate()"><i class="far fa-copy"></i></button>
                        <button type="button" [disabled]="estimateModel.invoiceId === 0" class="btn btn-outline-primary" title="Pre-Payment" (click)="prePayment()"><i class="far fa-credit-card"></i></button>
                    </div>
                    <!--<button type="button" *ngIf="useJellyFishOrdering" [disabled]="estimateModel.invoiceId === 0 || estimateModel.jellyFishOrderId" class="btn btn-outline-primary ms-2" title="Create JellyFish Order" (click)="createJellyFishOrder()"><i><img src="../../../content/images/jelly-fish-logo-colored.png" style="max-width: 20px; max-height: 20px;" /></i> </button>-->
                </div>
            </div>
        </div>
        <div class="d-flex flex-fill" *ngIf="!estimatesAuthService.canEdit()">
            <permission-denied [message]="'*You do not have permission to edit Estimates.'"></permission-denied>
        </div>
    </slick-container-footer>
</slick-container>

<process-payment-dialog #creditCardDialogRef
                    (onApproved)="onApproved($event)"
                    (onACHApproved)="onACHApproved($event)"
                    (onPaymentTypesChanged)="onPaymentTypesChanged($event)"></process-payment-dialog>

<emailer-dialog #emailerDialogRef></emailer-dialog>
<texter-dialog #texterDialogRef></texter-dialog>
<credit-card-selector-dialog #creditCardSelectorDialogRef></credit-card-selector-dialog>
