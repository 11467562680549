import { Injectable } from '@angular/core';
import { ICustomerModel, ICreditCardProcessPaymentModel, ICreditCardPaymentApprovedModel, IACHPaymentApprovedModel, IACHPaymentTypeModel } from '@models';
import { Subject } from 'rxjs';
import { ICreditCardPaymentTypeModel } from '@models';

@Injectable()
export class CreditCardStore {
	customerModel: ICustomerModel;
	creditCardPaymentType: ICreditCardPaymentTypeModel;
	creditCardProcessPaymentModel: ICreditCardProcessPaymentModel;

	transactionSuccess$ = new Subject<ICreditCardPaymentApprovedModel | IACHPaymentApprovedModel>();
	cardUpdated$ = new Subject<ICreditCardPaymentTypeModel>();
	creditCardEditSuccess$ = new Subject<ICreditCardPaymentTypeModel>();
	achUpdated$ = new Subject<IACHPaymentTypeModel>();
	achEditSuccess$ = new Subject<IACHPaymentTypeModel>();

}
