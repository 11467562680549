import { AddressModel } from "../latham-ordering/address.model";

export interface IACHPaymentTypeModel {
    achPaymentTypeId: number; 
    customerId: number;
    addressId: number;
    processor: string;
    processorPaymentId: string;
    paymentDescription: string;
    accountNumber: string;
    routingNumber: string;
    accountMasked: string;
    addressModel: AddressModel;
    isDefault: boolean; 
    isDeleted: boolean;
    displayText: string;
    accountNumberLastThree: string;
}

export class ACHPaymentTypeModel implements IACHPaymentTypeModel {
    constructor() {
        this.addressModel = new AddressModel();
    }
    achPaymentTypeId: number;
    customerId: number;
    addressId: number;
    processor: string;
    processorPaymentId: string;
    paymentDescription: string;
    accountNumber: string;
    routingNumber: string;
    accountMasked: string;
    addressModel: AddressModel;
    isDefault: boolean;
    isDeleted: boolean;
    displayText: string;
    accountNumberLastThree: string;
}
