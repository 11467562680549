import { Injectable } from "@angular/core";
import { HttpService } from "../utils/http.service";
import { ICreditCardPaymentTypeModel, ICreditCardTransactionLogModel, ICreditCardProcessPaymentModel, ICreditCardProcessPaymentResult, IPaymentModel, IACHProcessPaymentModel, IACHProcessPaymentResultModel, IACHPaymentTypeModel } from "@models";
import { ICreditCardService } from "./credit-card.service";

@Injectable()
export class PayTraceService implements ICreditCardService {

    constructor(private httpService: HttpService) { }

    async getNewCreditCardPaymentType(customerId: number): Promise<ICreditCardPaymentTypeModel> {
        return await this.httpService.get("/paytrace/getNewCreditCardPaymentType", { customerId: customerId });
    }

    async getCreditCardPaymentType(creditCardPaymentTypeId: number): Promise<ICreditCardPaymentTypeModel> {
        return await this.httpService.get("/paytrace/getCreditCardPaymentType", { creditCardPaymentTypeId: creditCardPaymentTypeId });
    }

    async getCreditCardPaymentTypeForCustomer(customerId: number): Promise<ICreditCardPaymentTypeModel[]> {
        return await this.httpService.get("/paytrace/getCreditCardPaymentTypeForCustomer", { customerId: customerId });
    }

    async getACHPaymentInfoForCustomer(customerId: number): Promise<IACHPaymentTypeModel[]> {
        return await this.httpService.get("/paytrace/getACHPaymentTypeForCustomer", { customerId: customerId });
    }

    async getACHPaymentType(achPaymentTypeId: number): Promise<IACHPaymentTypeModel> {
        return await this.httpService.get("/paytrace/getachpaymenttype", {achPaymentTypeId: achPaymentTypeId})
    }

    async addCreditCardPaymentType(creditCardPaymentTypeModel: ICreditCardPaymentTypeModel): Promise<ICreditCardPaymentTypeModel> {
        return await this.httpService.post("/paytrace/addCreditCardPaymentType", creditCardPaymentTypeModel);
    }

    async updateCreditCardPaymentType(creditCardPaymentTypeModel: ICreditCardPaymentTypeModel): Promise<ICreditCardPaymentTypeModel> {
        return await this.httpService.post("/paytrace/updateCreditCardPaymentType", creditCardPaymentTypeModel);
    }

    async deleteCreditCardPaymentType(creditCardPaymentTypeModel: ICreditCardPaymentTypeModel): Promise<ICreditCardPaymentTypeModel> {
        return await this.httpService.post("/paytrace/deleteCreditCardPaymentType", creditCardPaymentTypeModel);
    }

    async processPrePayment(processCreditCardPaymentModel: ICreditCardProcessPaymentModel): Promise<ICreditCardProcessPaymentResult> {
        return await this.httpService.post("/paytrace/processPrePayment", processCreditCardPaymentModel);
    }

    async processPayment(processCreditCardPaymentModel: ICreditCardProcessPaymentModel): Promise<ICreditCardProcessPaymentResult> {
        return await this.httpService.post("/paytrace/processPayment", processCreditCardPaymentModel);
    }

    async voidPayment(paymentModel: IPaymentModel): Promise<IPaymentModel> {
        return await this.httpService.post("/paytrace/voidPayment", paymentModel);
    }
    async refundPayment(paymentModel: IPaymentModel, amount: number): Promise<IPaymentModel> {
        return await this.httpService.post(`/paytrace/refundPayment?amount=${amount}`, paymentModel);
    }
    async processACHPayment(processACHPaymentModel: IACHProcessPaymentModel): Promise<IACHProcessPaymentResultModel> {
        return await this.httpService.post("/paytrace/processACHPayment", processACHPaymentModel);
    }

    async addACHPaymentType(achPaymentTypeModel: IACHPaymentTypeModel): Promise<IACHPaymentTypeModel> {
        return await this.httpService.post("/paytrace/addACHPaymentType", achPaymentTypeModel);
    }
    async updateACHPaymentType(achPaymentTypeModel: IACHPaymentTypeModel): Promise<IACHPaymentTypeModel>
    {
        return await this.httpService.post("/paytrace/updateACHPaymentType", achPaymentTypeModel);

    }


}