import { Component, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { IAddressModel, ICustomerModel } from "@models";
import { CustomersService } from "@services/customers.service";
import { GlobalsService } from "@services";
import { ProcessPaymentDialogComponent } from "@components/process-payment-dialog/process-payment-dialog.module";

@Component({
	selector: "customer-display",
	templateUrl: "./customer-display.component.html",
	styleUrls: ['customer-display.component.scss'],
	providers: [CustomersService]
})
export class CustomerDisplayComponent implements OnChanges {
	@Input() customerModel: ICustomerModel;
	@Input() isBillingCustomer: boolean = false;
	@Input() enableCreditCardEdit: boolean;
	@Input() showAttentionNote: boolean = true;
	@Input() hideEditButton: boolean;
	@Input() editable: boolean = false;
	@Input() billingAddress: IAddressModel;

	@ViewChild("processPaymentDialogRef", { static: true }) processPaymentDialogRef: ProcessPaymentDialogComponent;

	creditCardDigits: string;

	constructor(private customersService: CustomersService) {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.isBillingCustomer)
			this.isBillingCustomer = (this.isBillingCustomer.toString().toLowerCase() === "true") ? true : false;

		if (this.customerModel) {
			if (this.customerModel.cardOnFile)
				this.creditCardDigits = this.customerModel.cardOnFile.split("|")[1];
		//	if (this.customerModel.achOnFile)
		//		this.achInfo = this.customerModel.achOnFile;
		}

		if (this.enableCreditCardEdit) {
			if (GlobalsService.company.useUSAePay === false && GlobalsService.company.usePayTrace === false && 
				GlobalsService.company.useBambora === false && GlobalsService.company.useZift === false)
				this.enableCreditCardEdit = false;
			else
				this.enableCreditCardEdit = (!!this.enableCreditCardEdit === false) ? false : true
		}

	}

	showCreditCardInfoDialog() {
		this.processPaymentDialogRef.openDialog(this.customerModel);
	}

	async creditCardDialogChanges(event) {
		this.customerModel = await this.customersService.getCustomer(this.customerModel.customerId);
		if (this.customerModel && this.customerModel.cardOnFile) {
			this.creditCardDigits = this.customerModel.cardOnFile.split("|")[1];
		}
	}

}
