import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common"
import { ProcessPaymentDialogModule } from "@components/process-payment-dialog/process-payment-dialog.module";
import { CustomerDisplayComponent } from "./customer-display.component";

@NgModule({
	imports: [
		CommonModule,
		ProcessPaymentDialogModule
	],
	declarations: [CustomerDisplayComponent],
	exports: [CustomerDisplayComponent]
})
export class CustomerDisplayModule { }
