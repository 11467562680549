import { Injectable } from "@angular/core";
import { IDocumentModel, ILocationModel, IScheduledAppointmentModel, ISelectedLocationModel, ITextChatConversationSummaryModel, ITextChatMessageModel, ITextChatTemplateModel, SelectedLocationModel } from "@models";
import { LoggerStore } from "@stores";
import { BehaviorSubject, Subject } from "rxjs";
import { GlobalsService, HttpService, UtilsService, AppointmentsService, LookupService, FunctionLockService, SleepService, UsersService } from "@services";
import { ITextChatConversationModel, TextChatConversationModel } from "../models/text-chat/text-chat-conversation.model";
import { sortBy } from "sort-by-typescript";

enum DisplayTypes { Open, Closed }

class TextChatInit {
	unreadMessageCount: number;
}

@Injectable()
export class TextChatStore {
	
	private _initRunning = true;
	private _isMobile;
	private _unreadMessagesCount: number = 0;
	private _openConversations: ITextChatConversationSummaryModel[];
	private _visibleConversations: ITextChatConversationSummaryModel[];
	private _activeConversation: ITextChatConversationModel = null;
	private _activeConversationUuid: string;
	//private _customerAppointments: IScheduledAppointmentModel[];
	private _displayType: DisplayTypes = DisplayTypes.Open;
	private _searchText: string;
	private _lastSearchText: string;
	private _templates: ITextChatTemplateModel[];
	private _selectedLocations: ISelectedLocationModel[] = [];

	unreadMessagesCount$: BehaviorSubject<number> = new BehaviorSubject<number>(this._unreadMessagesCount);
	visibleConversations$ = new Subject<ITextChatConversationSummaryModel[]>();
	activeConversation$ = new Subject<ITextChatConversationModel>();
	newMessage$ = new Subject<ITextChatMessageModel>();
	closeConversation$ = new Subject<ITextChatConversationSummaryModel>();
	reopenConversation$ = new Subject<ITextChatConversationSummaryModel>();
	//customerAppointments$ = new Subject<IScheduledAppointmentModel[]>();

	constructor(private httpService: HttpService,
		private appointmentsService: AppointmentsService,		
		private lookupService: LookupService,
		private usersService: UsersService) {
	}

	async init(isMobile: boolean) {
		this._isMobile = isMobile;

		if (this.lookupService.getLocations().length > 1) {

			let userLocationIds: number[];
			// Default to the same as the schedule
			if (sessionStorage.getItem(`TEXT_CHAT_SELECTED_LOCATION_IDS_${GlobalsService.userInfo.userId}`) === null)
				userLocationIds = GlobalsService.userInfo.selectedLocationIds;
			else
				userLocationIds = JSON.parse(sessionStorage.getItem(`TEXT_CHAT_SELECTED_LOCATION_IDS_${GlobalsService.userInfo.userId}`));

			if (userLocationIds.length === 0 && GlobalsService.userInfo.allowedLocationIds.length === 1) 
				userLocationIds = [...GlobalsService.userInfo.allowedLocationIds];

			this._selectedLocations = this.lookupService.getLocations()
				.filter(x => GlobalsService.userInfo.allowedLocationIds.includes(x.locationId))
				.map(l => {
					const scheduleLocation = new SelectedLocationModel();
					scheduleLocation.locationId = l.locationId;
					scheduleLocation.locationName = l.name;
					scheduleLocation.checked = userLocationIds.includes(l.locationId);

					return scheduleLocation;
				});
		}

		const openConversations = await this.refreshOpenConversations();
		this._visibleConversations = [...openConversations];

		const textChatInit: TextChatInit = await this.httpService.get("/textChat/textChatInit");
		this._unreadMessagesCount = textChatInit.unreadMessageCount;
		this.unreadMessagesCount$.next(this._unreadMessagesCount);

		this._initRunning = false;
	}

	async waitForInit(): Promise<void> {
		return new Promise<void>((resolve) => {
			let infinteLoop = 100;
			const waitInterval = setInterval(() => {
				if (this._initRunning === false || --infinteLoop <= 0) {
					if (infinteLoop <= 0)
						console.error("Infinite loop waiting for TextChatStore.init");
					clearInterval(waitInterval);

					resolve();
				}
			}, 100);
		})
	}

	get searchText() {
		return this._searchText;
	}

	set searchText(searchText) {
		this._searchText = searchText;
	}

	get lastSearchText() {
		return this._lastSearchText;
	}

	set lastSearchText(lastSearchText) {
		this._lastSearchText = lastSearchText;
	}

	get displayType(): DisplayTypes {
		return this._displayType;
	}

	set displayType(displayType: DisplayTypes) {
		this._displayType = displayType;
		//this is being called already on tab changed
		//this.refresh();
	}

	async refresh() {
		this.refreshVisibleConversations();

		if (this._activeConversation) {
			this._activeConversation = <ITextChatConversationModel>(await this.httpService.get('/textChat/getConversation', { textChatConversationId: this._activeConversation.textChatConversationId }));
			this.activeConversation$.next(this._activeConversation);
		}

		const textChatInit: TextChatInit = await this.httpService.get("/textChat/textChatInit");
		this._unreadMessagesCount = textChatInit.unreadMessageCount;
		this.unreadMessagesCount$.next(this._unreadMessagesCount);

	}

	async refreshOpenConversations(): Promise<ITextChatConversationSummaryModel[]> {
		const selectedLocations = this._selectedLocations
			.filter(x => x.checked === true)
			.map(x => x.locationId);

		const params = {
			selectedLocations: selectedLocations
		}
		this._openConversations = <ITextChatConversationSummaryModel[]>(await this.httpService.get('/textChat/getOpenConversations', params));

		return this._openConversations;
	}

	async refreshVisibleConversations(): Promise<ITextChatConversationSummaryModel[]> {
		const selectedLocations = this._selectedLocations
			.filter(x => x.checked === true)
			.map(x => x.locationId);

		const params = {
			displayType: this._displayType.toString(),
			searchText: this._searchText || '',
			isServiceTech: this._isMobile,
			selectedLocations: selectedLocations
		}
		this._visibleConversations = <ITextChatConversationSummaryModel[]>(await this.httpService.get('/textChat/getConversations', params));
		this.visibleConversations$.next(this._visibleConversations);

		return this._visibleConversations;
	}

	async onAddConversation(conversation: ITextChatConversationSummaryModel) {
		conversation = UtilsService.dateSanitize(conversation);

		// Check to see if this new conversation should be shown for this location
		if (conversation.locationId && this._selectedLocations && this.selectedLocations.length > 0) {
			const selectedLocation = this._selectedLocations.find(x => x.locationId === conversation.locationId && x.checked === true);
			if (selectedLocation === null)
				return;
		}

		//dont want to show it in the list until its reopened
		if (this._displayType === DisplayTypes.Open && conversation.isClosed === false) {
			const openConversation = this._visibleConversations.find(x => (x.textChatConversationId === conversation.textChatConversationId || this.scrubPhoneNumber(x.customerPhoneNumber) === this.scrubPhoneNumber(conversation.customerPhoneNumber)))
			if (!openConversation) {
				this._openConversations.unshift(conversation);
				this._visibleConversations.unshift(conversation);
			}

			this.visibleConversations$.next(this._visibleConversations);
		}
	}

	async onUpdateConversation(conversation: ITextChatConversationSummaryModel) {
		conversation = UtilsService.dateSanitize(conversation);

		const openIdx = this._openConversations.findIndex(x => x.uuid === conversation.uuid);
		if (openIdx >= 0)
			this._openConversations[openIdx] = conversation;

		const idx = this._visibleConversations.findIndex(x => x.uuid === conversation.uuid);
		if (idx >= 0) {
			this._visibleConversations[idx] = conversation;
			this.visibleConversations$.next(this._visibleConversations);
		}

		if (this._activeConversation.uuid === conversation.uuid) {
			this._activeConversation = await this.httpService.get("/textChat/getConversation", { textChatConversationId: conversation.textChatConversationId });
			this.activeConversation$.next(this._activeConversation);
		}
	}

	onCloseConversation(conversation: ITextChatConversationSummaryModel) {
		conversation = UtilsService.dateSanitize(conversation);

		if (conversation.unreadMessages) {
			this._unreadMessagesCount -= conversation.unreadMessages;
			this.unreadMessagesCount$.next(this._unreadMessagesCount);
		}
			
		this._openConversations = this._openConversations.filter(x => x.uuid !== conversation.uuid);

		if (this._displayType === DisplayTypes.Open) {
			this._visibleConversations = this._visibleConversations.filter(x => x.uuid !== conversation.uuid);
			this.visibleConversations$.next(this._visibleConversations);
		}

		this.closeConversation$.next(conversation);
	}

	onReopenConversation(conversation: ITextChatConversationSummaryModel) {
		conversation = UtilsService.dateSanitize(conversation);

		const openIdx = this._openConversations.findIndex(x => x.uuid === conversation.uuid);
		if (openIdx < 0)
			this._openConversations.unshift(conversation);
		else
			this._openConversations[openIdx] = conversation;

		if (this._displayType === DisplayTypes.Open) {
			const openConversation = this._visibleConversations.find(x => (x.textChatConversationId === conversation.textChatConversationId || this.scrubPhoneNumber(x.customerPhoneNumber) === this.scrubPhoneNumber(conversation.customerPhoneNumber)))
			if(!openConversation)
				this._visibleConversations = [...this._visibleConversations, conversation];
		}
		else
			this._visibleConversations = this._visibleConversations.filter(x => x.uuid !== conversation.uuid);		

		this._visibleConversations = this._visibleConversations.sort(sortBy("-lastTextSentDate"));

		this.visibleConversations$.next(this._visibleConversations);
		this.reopenConversation$.next(conversation);
	}

	async onNewMessage(message: ITextChatMessageModel) {
		message = UtilsService.dateSanitize(message);

		const conversation = this._openConversations.find(x => x.textChatConversationId === message.textChatConversationId);

		if (conversation != null) {
			const visibleConversation = this._visibleConversations.find(x => x.textChatConversationId === message.textChatConversationId);

			if (visibleConversation) {
				visibleConversation.lastTextBody = message.body;
				visibleConversation.lastTextSentDate = message.sentDate;
			}
			if (!message.isSystemMessage) {
				// If we're an attendant of this conversation, either add an unread badge 
				// or reset the last read time if it's the current conversation
				if (conversation.assignedToUserId === GlobalsService.userInfo.userId || conversation.attendantUserIds.indexOf(GlobalsService.userInfo.userId) >= 0) {
					if (this._activeConversation && this._activeConversation.textChatConversationId === message.textChatConversationId) {
						this.httpService.get("/textChat/resetLastConversationReadTime", { textChatConversationId: message.textChatConversationId })
					}
					else {
						if (message.isFromCustomer) {
							if (visibleConversation)
								visibleConversation.unreadMessages++;
							this._unreadMessagesCount++;
							this.unreadMessagesCount$.next(this._unreadMessagesCount);
						}
					}
				}

				// Only refresh the visible conversations if we can see this conversation
			}
            if (visibleConversation)
                this.visibleConversations$.next(this._visibleConversations);
        }

		this._visibleConversations = this._visibleConversations.sort(sortBy("-lastTextSentDate"));

		this.newMessage$.next(message);
	}

	getVisibleConversations(): ITextChatConversationSummaryModel[] {
		return this._visibleConversations;
	}

	async loadActiveConversation(textChatConversationId: number) {
		this._activeConversation = <ITextChatConversationModel>(await this.httpService.get('/textChat/getConversation', { textChatConversationId }));
		this._activeConversationUuid = this._activeConversation.uuid;
		this.activeConversation$.next(this._activeConversation);

		// Find the conversation in open conversations and reset the unread count
		const conversation = this._openConversations.find(x => x.textChatConversationId === textChatConversationId);
		if (conversation != null) {
			this._unreadMessagesCount -= conversation.unreadMessages;
			this.unreadMessagesCount$.next(this._unreadMessagesCount);
			conversation.unreadMessages = 0;

			const visibleConversation = this._visibleConversations.find(x => x.textChatConversationId === textChatConversationId);
			if (visibleConversation) {
				visibleConversation.unreadMessages = 0;
				this.visibleConversations$.next(this._visibleConversations);
			}
				

			//this.loadCustomerAppointments(conversation.customerId, conversation.appointmentId);
		}
	}

	getActiveConversationUuid(): string {
		return this._activeConversationUuid;
	}

	setActiveConversation(conversation: TextChatConversationModel) {
		this._activeConversation = conversation;
		this._activeConversationUuid = conversation.uuid;
		this.activeConversation$.next(this._activeConversation);

	}

	getActiveConversation() {
		return this._activeConversation;
	}

	clearActiveConversation(clearActiveConversationUuid: boolean = true) {
		this._activeConversation = null;
		if (clearActiveConversationUuid === true)
			this._activeConversationUuid = null;
		this.activeConversation$.next(this._activeConversation);

	//	this._customerAppointments = null;
	//	this.customerAppointments$.next(this._customerAppointments);
	}

	startConversation(conversation: ITextChatConversationModel): Promise<ITextChatConversationSummaryModel> {
		return this.httpService.post('/textChat/startConversation', conversation);
	}

	updateAttendants(conversation: ITextChatConversationModel): Promise<ITextChatConversationSummaryModel> {
		return this.httpService.post('/textChat/updateAttendants', conversation);
	}

	updateAssignedTo(conversation: ITextChatConversationModel): Promise<ITextChatConversationSummaryModel> {
		return this.httpService.patch(`/textChat/updateAssignedTo?textChatConversationId=${conversation.textChatConversationId}&assignedToUserId=${conversation.assignedToUserId || ''}`);
	}

	async sendMessage(message: ITextChatMessageModel) {
		if (this._activeConversation.isClosed === true) {
			await this.httpService.patch(`/textChat/reopenConversation?textChatConversationId=${this._activeConversation.textChatConversationId}`);
		}

		this.httpService.post('/textChat/sendMessage', message);
	}

	async sendAttachments(files: IDocumentModel[]): Promise<ITextChatMessageModel[]> {
		if (this._activeConversation.isClosed === true) {
			await this.httpService.patch(`/textChat/reopenConversation?textChatConversationId=${this._activeConversation.textChatConversationId}`);
		}

		try {
			const formData = new FormData();
			files.forEach(file => {
				if (file.base64Image && file.base64Image.length > 0) {
					const fileCopy: IDocumentModel = UtilsService.clone(file);
					const fileName = file.name;
					delete fileCopy.file;
					delete fileCopy.thumbnailBase64Image;
					const fileJSON = JSON.stringify(fileCopy);
					formData.append(fileName, fileJSON);
				}
			});

			return await this.httpService.postMultipart(`/textChat/sendAttachments?textChatConversationId=${this._activeConversation.textChatConversationId}`, formData);
		} catch (error) {
			console.error(error);
		}
	}

	addSystemMessage(message: ITextChatMessageModel) {
		this.httpService.post('/textChat/addSystemMessage', message);
	}

	closeConversation(textChatConversationId: number) {
		this.httpService.patch(`/textChat/closeConversation?textChatConversationId=${textChatConversationId}`);
	}

	async getConversationByAppointmentId(appointmentId: number): Promise<ITextChatConversationModel> {
		return this.httpService.get(`/textChat/getConversationByAppointmentId?appointmentId=${appointmentId}`);
	}

	async getConversationByPhoneNumber(textChatPhoneNumber: string): Promise<ITextChatConversationModel> {
		return this.httpService.get(`/textChat/getConversationByPhoneNumber?phoneNumber=${textChatPhoneNumber}`);
	}

	//async loadCustomerAppointments(customerId: number, defaultAppointmentId?: number) {
	//	if (this._isMobile)
	//		return null;

	//	if (!customerId) 
	//		this._customerAppointments = null;
	//	else
	//		this._customerAppointments = await this.appointmentsService.getScheduledAppointmentsForCustomer(customerId, defaultAppointmentId);

	//	this.customerAppointments$.next(this._customerAppointments);
	//}

	async getTemplates(showInactive: boolean = false, forceReload: boolean = false): Promise<ITextChatTemplateModel[]> {
		if (!this._templates || forceReload)
			this._templates = await this.httpService.get("/textChatTemplates/getTemplates");

		if (showInactive === false)
			return this._templates.filter(x => x.active === true);
		else 
			return [...this._templates];
	}

	async updateTemplates(templates: ITextChatTemplateModel[]): Promise<ITextChatTemplateModel[]> {
		this._templates = await this.httpService.post('/textChatTemplates/updateTemplates', templates);

		return this._templates;
	}

	set selectedLocations(selectedLocations: ISelectedLocationModel[]) {
		this._selectedLocations = selectedLocations;
	}

	get selectedLocations(): ISelectedLocationModel[] {
		return this._selectedLocations;
	}

	async toggleSelectedLocation(locationId: number): Promise<ISelectedLocationModel[]> {
		const selectedLocation = this._selectedLocations.find(x => x.locationId === locationId);
		if (selectedLocation) {
			selectedLocation.checked = !selectedLocation.checked;
			await this.refreshOpenConversations();
			await this.refreshVisibleConversations();
		}

		const selectedLocationIds = this._selectedLocations
			.filter(x => x.checked === true)
			.map(x => x.locationId);
		sessionStorage.setItem(`TEXT_CHAT_SELECTED_LOCATION_IDS_${GlobalsService.userInfo.userId}`, JSON.stringify(selectedLocationIds));

		await this.usersService.toggleSelectedLocation(locationId);

		return this.selectedLocations;
    }

	public scrubPhoneNumber(phoneNumber: string): string {
		if (!phoneNumber)
			return;
        phoneNumber = phoneNumber.replace("+1", "").replace("+", "");
        const justDigits = phoneNumber.replace(/\D/g, "");

        let result = justDigits;
        if (justDigits.length > 10) {
            result = justDigits.slice(-10);
        }

        return result;
    };
}
