<div *ngIf="customerModel && customerModel.customerId > 0" class="customer-display">
	<section class="customer-info mt-1 card" style="width: 100%;">
		<section *ngIf="customerModel.customerAttentionNote && showAttentionNote" class="alert-section">
			<div class="alert alert-danger mt-0 mb-0 bg-danger" role="alert">
				{{customerModel.customerAttentionNote}}
			</div>
		</section>
		<section *ngIf="customerModel.creditHoldNotes" class="alert-section">
			<div class="alert alert-danger mt-0 mb-0 bg-danger" role="alert">
				{{customerModel.creditHoldNotes}}
			</div>
		</section>
		<!-- CustomerId of 1 = Archived order customer -->
		<section *ngIf="enableCreditCardEdit === true && customerModel.customerId > 1" class="alert-section">
			<div class="alert alert-danger mt-0 mb-0 bg-danger" role="alert" *ngIf="!customerModel.cardOnFile && !customerModel.bankAccountOnFile">
				<button *ngIf="hideEditButton == false" class="btn" style="float: right; max-height: 3px;" [disabled]="!enableCreditCardEdit" [title]="enableCreditCardEdit ? 'Edit Payment Info' : 'No default payment provider found!'" (click)="showCreditCardInfoDialog()">
					<i class="fa fa-pencil"></i>
				</button>
				No payment on file
			</div>
			<div class="alert alert-danger bg-success mt-0 mb-0" role="alert" *ngIf="customerModel.cardOnFile || customerModel.bankAccountOnFile">
				<div *ngIf="customerModel.cardOnFile">

				Card on file:
				<span class="ms-2">
					<i *ngIf="customerModel.cardOnFile.toLowerCase().indexOf('visa') > -1" class='fab fa-cc-visa'></i>
					<i *ngIf="customerModel.cardOnFile.toLowerCase().indexOf('mastercard') > -1" class='fab fa-cc-mastercard'></i>
					<i *ngIf="customerModel.cardOnFile.toLowerCase().indexOf('discover') > -1" class='fab fa-cc-discover'></i>
					<i *ngIf="customerModel.cardOnFile.toLowerCase().indexOf('american express') > -1" class='fab fa-cc-amex'></i>
					<span class="cc-digits"> {{ creditCardDigits }}</span>
				</span>
				</div>
				<div *ngIf="customerModel.bankAccountOnFile">
					<span *ngIf="customerModel.cardOnFile">&nbsp;|&nbsp;</span>
					ACH on file:
					<span class="ms-2">
						<span class="cc-digits"> {{ customerModel.bankAccountOnFile }}</span>
					</span>

				</div>
				<button *ngIf="hideEditButton == false" class="btn" style="float: right; max-height: 3px;" [disabled]="!enableCreditCardEdit || hideEditButton == true" [title]="enableCreditCardEdit ? 'Edit Payment Info' : 'No default payment provider found!'" (click)="showCreditCardInfoDialog()">
					<i class="fa fa-pencil"></i>
				</button>
			</div>
		</section>
		<ul class="customer-list-item pt-0 m-2">
			<li *ngIf="customerModel.companyName" class="bigger">{{ customerModel.companyName }}</li>
			<li *ngIf="customerModel.companyName" class="mt-1" style="font-size: .8rem; font-weight: bold;">{{ customerModel.fullName }}</li>

			<li *ngIf="!customerModel.companyName" class="bigger">{{ customerModel.fullName }}</li>

			<div class="mt-2">
				<div class="d-flex" *ngIf="customerModel.address1 && !isBillingCustomer || customerModel.address2 && !isBillingCustomer || customerModel.displayCityStateZip && !isBillingCustomer">
					<i class="bi bi-house-fill" style="margin-right: 4px;"></i>
					<li *ngIf="customerModel.address1 && !isBillingCustomer" style="line-height: 1rem">{{ customerModel.address1 }} {{ customerModel.address2 }} {{ customerModel.displayCityStateZip }}</li>
				</div>

				<div class="d-flex" *ngIf="customerModel.billingAddress1 && isBillingCustomer || customerModel.billingAddress2 && isBillingCustomer || customerModel.displayBillingCityStateZip && isBillingCustomer">
					<i class="bi bi-house-fill" style="margin-right: 4px;"></i>
					<li *ngIf="customerModel.billingAddress1 && isBillingCustomer" style="line-height: 1rem">{{ customerModel.billingAddress1 }} {{ customerModel.billingAddress2 }} {{ customerModel.displayBillingCityStateZip }}</li>
				</div>

				<li *ngIf="customerModel.cellPhone" style="margin-top: .4rem;"> <i class="bi bi-telephone-fill"></i> Cell: {{ customerModel.cellPhone }}</li>
				<li *ngIf="customerModel.homePhone" style="margin-top: .4rem;"><i class="bi bi-telephone-fill"></i> Home: {{ customerModel.homePhone }}</li>
				<li *ngIf="customerModel.workPhone" style="margin-top: .4rem;"><i class="bi bi-telephone-fill"></i> Work: {{ customerModel.workPhone }}</li>
				<li *ngIf="customerModel.email" style="margin-top: .4rem;"><i class="bi bi-envelope-fill"></i> Email: {{ customerModel.email }}</li>
			</div>
		</ul>
	</section>
</div>

<process-payment-dialog #processPaymentDialogRef (onPaymentTypesChanged)="creditCardDialogChanges($event)"></process-payment-dialog>
