<div *ngIf="customerModel" class="me-3">
	<div [ngClass]="{ 'full-screen': !isDialog }">
		<!-- SKEDIT PAY - READ THIS SO YOU DONT SPEND 20 MIN TYRING TO ADD AN EVENT EMITTER INTO THE WRONG COMPONENT -->
		<div *ngIf="skeditPay">
			<slick-tabs (tabChange)="onTabChanged($event)">
				<slick-tab icon="bi bi-person-fill">
					<div style="max-height: calc(100vh - 350px); overflow-y: auto;">
					<customer-edit-layout #customerEditLayoutRef *ngIf="tabKey === 'Customer'" [(customerModel)]="customerModel"></customer-edit-layout>
					</div>
				</slick-tab>
				<slick-tab *ngIf="usingCreditCards" header="Payment Info" icon="bi bi-credit-card-fill">
					<payment-info-edit *ngIf="tabKey === 'Payment Info'" [(customerModel)]="customerModel"></payment-info-edit>
				</slick-tab>
				<slick-tab header="Documents" icon="bi bi-file-earmark-text-fill">
					<documents-edit *ngIf="tabKey === 'Documents'"
									[documentKey]="customerModel.documentKey"
									documentType="Customers"
									folderType="documents"
									[canDelete]="customersAuthService.canEdit()"
									[hideFileDrop]="!customersAuthService.canEdit()"></documents-edit>
				</slick-tab>
			<slick-tab header="System Logs" icon="bi bi-info-circle-fill">
				<div style="max-height: calc(100vh - 350px); overflow-y:auto;">
					<system-logs *ngIf="tabKey === 'System Logs'" [referenceId]="customerModel.customerId" noteType="0"></system-logs>
				</div>
			</slick-tab>
			</slick-tabs>
		</div>
		<!-- NOT SKEDIT PAY -->
		<div *ngIf="!skeditPay" style="padding-left: 15px;">	
			<slick-tabs [(tabIndex)]="tabIndex" (tabChange)="onTabChanged($event)">
				<slick-tab icon-class="bi-lg" header="Customer" icon="bi bi-person-fill">
					<customer-edit-layout #customerEditLayoutRef [(customerModel)]="customerModel" (existingCustomerSelected)="existingCustomerSelected($event)"></customer-edit-layout>
				</slick-tab>
				<slick-tab *ngIf="showAdditionalAddresses" header="Add'l Addresses" icon="bi bi-houses-fill">
					<customer-multiple-addresses *ngIf="tabKey === 'Add\'l Addresses'" [(customerModel)]="customerModel"></customer-multiple-addresses>
				</slick-tab>
				<slick-tab *ngIf="usingCreditCards" header="Payment Info" icon="bi bi-credit-card-fill">
					<payment-info-edit *ngIf="tabKey === 'Payment Info'" [(customerModel)]="customerModel"></payment-info-edit>
				</slick-tab>
				<!--<slick-tab header="Contacts" icon="fa-users">
					<contact-edit-layout *ngIf="tabKey === 'Contacts'" [customerModel]="customerModel"></contact-edit-layout>
				</slick-tab>-->
				<slick-tab header="CRM" icon="bi bi-headset">
					<crm-edit *ngIf="tabKey === 'CRM'" [customerModel]="customerModel"></crm-edit>
				</slick-tab>
				<slick-tab header="Photos" icon="bi bi-camera-fill">
					<documents-edit *ngIf="tabKey === 'Photos'"
									title="Customer Photos"
									[documentKey]="customerModel.documentKey"
									documentType="Customers"
									folderType="photos"
									[canDelete]="customersAuthService.canEdit()"
									[hideFileDrop]="!customersAuthService.canEdit()"></documents-edit>

					<documents-edit *ngIf="tabKey === 'Photos'"
									title="Job Photos"
									[documentKey]="customerModel.documentKey"
									documentType="Jobs"
									folderType="photos"
									getUrl="/documents/getAllJobPhotosForCustomer"
									[canDelete]="false"
									[hideFileDrop]="true"></documents-edit>

				</slick-tab>
				<slick-tab header="Documents" icon="bi bi-file-earmark-text-fill">
					<documents-edit *ngIf="tabKey === 'Documents'"
									[documentKey]="customerModel.documentKey"
									title="Customer Documents"
									documentType="Customers"
									folderType="documents"
									[canDelete]="customersAuthService.canEdit()"
									[hideFileDrop]="!customersAuthService.canEdit()"></documents-edit>
				</slick-tab>
				<slick-tab header="Appt. History" icon="bi bi-clock-history">
					<!-- <div style="max-height: calc(100vh - 350px); overflow-y:auto;"> -->
						<job-history *ngIf="tabKey === 'Appt. History'" [customerId]="customerModel.customerId"></job-history>
					<!-- </div> -->
				</slick-tab>
				<slick-tab *ngIf="useLathamOrdering" header="Latham" icon="fa-calculator">
					<latham-edit *ngIf="tabKey === 'Latham'" [(customerModel)]="customerModel" [(lathamCarriers)]="customerModel.lathamCarriers"></latham-edit>
				</slick-tab>
				<slick-tab header="System Logs" icon="bi bi-info-circle-fill">
					<system-logs *ngIf="tabKey === 'System Logs'" [referenceId]="customerModel.customerId" noteType="0"></system-logs>
				</slick-tab>
			</slick-tabs>
		</div>
	</div>
		<!--<div class="row mt-3 d-flex pt-2 border-top ms-3 me-3">
		<div class="me-3" *ngIf="flagNonSignedServiceAgreement && !customerModel.acceptedTerms">
			<button class="btn btn-outline-primary align-self-r" [disabled]="!customerModel.email" (click)="sendServiceAgreement(customerModel.customerId)" [slick-button-spinner]="agreementspinnerStatus"><i class="far fa-envelope"></i> Send Service Agreement</button>
		</div>
		</div>-->
	<layout-footer>
		<div *ngIf="!isDialog">
			<div class="flex-fill ms-1" *ngIf="!customersAuthService.canEdit()">
			  <permission-denied [message]="'*You do not have permission to edit Customers.'"></permission-denied>
			</div>
			<div class="mt-2 pt-2 mb-3 me-3" style="min-width: 100%;">
			  <div class="ms-1" *ngIf="customersAuthService.canEdit()" style="display: flex; justify-content: left;">
				<button class="save-button btn btn-outline-success me-3" style="width: 140px;" (click)="saveCustomer()" [slick-button-spinner]="spinnerStatus"><i class="bi bi-check-circle"></i> Save </button>
				<button class="cancel-button btn btn-outline-danger" style="width: 140px;" (click)="cancelCustomer()"> <i class="bi bi-x-circle"></i> Cancel </button>
				<div *ngIf="!skeditPay" style="margin-left: auto;">
				  <div *ngIf="customerModel.acceptedTerms === true" class="mt-2"><i class="bi bi-check-circle-fill text-success"></i>&nbsp;<b>Service Agreement Signed</b></div>
				  <div *ngIf="customerModel.acceptedTerms === false">
					<button type="button" style="width: 280px;" class="btn btn-outline-primary" [slick-button-spinner]="agreementspinnerStatus" (click)="sendServiceAgreement()">
					  <i class="bi bi-envelope"></i> Send Service Agreement
					</button>
				  </div>
				</div>
			  </div>
			</div>
		  </div>
	</layout-footer>
</div>