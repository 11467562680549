import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from "@components";
import { CustomerDisplayModule } from "@components/customer-display";
import { SystemLogsModule } from "@components/system-logs";
import { CustomerSearchModule } from "@components/customer-search";
import { ProcessPaymentDialogModule } from '@components/process-payment-dialog/process-payment-dialog.module';
import { CreditCardSelectorDialogModule } from '@shared-components/credit-card-selector-dialog/credit-card-selector-dialog.module';
import { PaymentsComponentsModule } from "@app/payments/payments-components";
import { InvoiceCustomFieldsModule } from "@shared-components/invoice-custom-fields"
import { CompareLathamBillDialogModule } from "@app/latham-orders/compare-latham-bill-dialog";
import { ShippingAddressModule } from "@shared-components/shipping-address";
import { TexterDialogModule } from "@shared-components/texter-dialog";

import { SlickButtonSpinnerModule, SlickContainerModule, SlickCurrencyMaskModule, SlickDatePickerModule, SlickDialogModule, SlickDropDownModule, SlickFileListModule, SlickScreenBlockerModule, SlickTabsModule, SlickToastModule, SlickValidationIndicatorModule } from "@slick-components";

import { LineItemsModule } from "@components/line-items"
import { InventoryLineItemsModule } from "@shared-components/inventory-line-items";

import { InvoiceEditComponent } from "./invoice-edit/invoice-edit.component";
import { InvoiceEditDialogComponent } from "./invoice-edit-dialog/invoice-edit-dialog.component";
import { SplitInvoiceDialogComponent } from "./split-invoice-dialog/split-invoice-dialog.component";
import { InvoicesService, PaymentsService } from "@services";
import { EmailerDialogModule } from "@components/emailer-dialog";
import { AddressSearchModule } from "@shared-components/address-search";
import { JellyFishOrderSpecsModule } from "@shared-components/jelly-fish-order-specs/jelly-fish-order-specs.module";
import { JobCostingDetailsModule } from "@app/job-costing-details/job-costing-details.module";


@NgModule({
	imports: [AddressSearchModule,CommonModule,
		FormsModule,
		ComponentsModule,
		CustomerDisplayModule,
		SystemLogsModule,
		CustomerSearchModule,
		ProcessPaymentDialogModule,
		CreditCardSelectorDialogModule,
		LineItemsModule,
		InventoryLineItemsModule,
		JellyFishOrderSpecsModule,
		JobCostingDetailsModule,
		EmailerDialogModule,
		PaymentsComponentsModule,
		InvoiceCustomFieldsModule,
		ShippingAddressModule,
		TexterDialogModule,
		CompareLathamBillDialogModule,
		SlickButtonSpinnerModule, SlickContainerModule, SlickCurrencyMaskModule, SlickDialogModule, SlickDropDownModule, SlickDatePickerModule, SlickFileListModule, SlickScreenBlockerModule, SlickTabsModule, SlickToastModule, SlickValidationIndicatorModule],
	exports: [InvoiceEditComponent, InvoiceEditDialogComponent, SplitInvoiceDialogComponent],
	declarations: [InvoiceEditComponent, InvoiceEditDialogComponent, SplitInvoiceDialogComponent],
	providers: [InvoicesService, PaymentsService]
})
export class InvoiceComponentsModule { }

export { InvoiceEditDialogComponent }
export { SplitInvoiceDialogComponent } 