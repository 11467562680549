<slick-container *ngIf="invoiceModel">
    <slick-container-body>
        <slick-tabs [(tabIndex)]="tabIndex" (tabChange)="onTabChanged($event)">
            <slick-tab header="Invoice" icon="fa-calculator">

                <slick-container maxHeight="calc(100vh - 260px)">
                    <slick-container-body>
                        <div class="bold-header" style="display: flex; align-items: center; padding-top: 5px !important;">
                            <div style="flex-grow: 1;">Customer Info</div>
                            <div class="gray-line"></div>
                        </div>
                        <div class="d-flex flex-column mt-3">

                            <!-- Top Column -->
                            <div class="d-flex">
                                <div class="d-flex flex-fill">
                                    <!-- Billing Customer-->
                                    <div class="width-half">
                                        <label class="mt-1" style="padding-bottom: 4px"><b>Billing Customer</b></label>
                                        <div>
                                            <customer-search [customerModel]="invoiceModel.customer"
                                                             (customerModelChange)="onCustomerModelChange($event)"
                                                             [disableButtons]="!invoicesAuthService.canEdit()"
                                                             [showButtons]="!adminEdit === false"
                                                             [disabled]="adminEdit === false"></customer-search>
                                            <form-error *ngIf="!invoiceModel.customer && isSubmitted === true">Customer is required</form-error>
                                        </div>
                                    </div>




                                    <!-- Shipping Address -->
                                    <div *ngIf="invoiceModel" class="width-half ms-3" style="font-size:14px">
                                        <shipping-address [customer]="invoiceModel.customer"
                                                          [shippingAddress]="invoiceModel.shippingAddress"
                                                          [lathamOrderId]="invoiceModel.lathamOrderId"
                                                          [editable]='adminEdit === true'
                                                          (shippingAddressChange)="onShippingAddressChanged($event)"></shipping-address>
                                        <form-error *ngIf="!this.invoiceModel.shippingAddress.address1 && this.isSubmitted">An address is required</form-error>
                                    </div>
                                </div>

                                <div class="d-flex">
                                    <div class="ms-3 mt-4 text-end" style="font-size: 14px; line-height: 16px; margin-top: 5px;" *ngIf="invoiceModel.jobId">
                                        <div *ngIf="job?.jobName">Job Name: {{ job?.jobName }}</div>
                                        <div *ngIf="job?.jobCustomer.displayName">Job Customer: {{ job?.jobCustomer.displayName}}</div>
                                        <div *ngIf="job?.jobTypeDisplayName" class="d-flex justify-content-end align-items-center">
                                            <div class="me-1">
                                                Job Type:
                                            </div>
                                            <div style="max-width: 140px;">
                                                {{ job?.jobTypeDisplayName }}
                                            </div>
                                        </div>
                                        <div *ngIf="invoiceModel.preAuthMessage">{{invoiceModel.preAuthMessage}}</div>
                                    </div>

                                    <div *ngIf="isQuickbooksEnabled" class="position-relative top-0 end-0" style="margin-top: -25px;">
                                        <quickbooks-icon [syncToQuickbooks]="invoiceModel.syncToQuickbooks"
                                                         [quickbooksId]="invoiceModel.quickbooksId"
                                                         [quickbooksDocNumber]="invoiceModel.quickbooksDocNumber"
                                                         [modifyDocNumber]="true"
                                                         lockSynced="true"
                                                         (onClick)="syncToQuickbooks($event)"></quickbooks-icon>
                                    </div>

                                    <div *ngIf="useBusinessCentral" class="position-relative top-0 end-0" style="max-width: 58px; margin-top: -25px;">
                                        <business-central-icon [syncToBusinessCentral]="invoiceModel.syncToBusinessCentral"
                                                               [businessCentralId]="invoiceModel.businessCentralId"
                                                               (onClick)="businessCentralSync()"></business-central-icon>
                                    </div>

                                </div>

                            </div>

                            <div *ngIf="invoiceModel.customer?.billingNotes" class="row col-12 mt-2">
                                <div class="d-flex flex-fill" style="max-width: 100%;">
                                    <div class="flex-fill">
                                        <label>Customer Notes</label>
                                        <pre style="line-height: 1rem; font-family: 'Quicksand', sans-serif;white-space: pre-wrap; word-wrap: break-word;overflow-wrap: break-word;" class="border p-2 m-0">{{invoiceModel.customer?.billingNotes}}</pre>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-3">

                                <div class="bold-header mb-1" style="display: flex; align-items: center; padding-top: 5px !important;">
                                    <div style="flex-grow: 1;">Invoice Info</div>
                                    <div class="gray-line"></div>
                                </div>

                                <div class="d-flex align-items-center">
                                    <div class="flex-grow-1 me-3">
                                        <label>Invoice #</label>
                                        <input type="text" class="form-control" [(ngModel)]="invoiceModel.invoiceNumber" [slick-validation-indicator]="invoiceModel.invoiceNumber" />
                                        <form-error *ngIf="!invoiceModel.invoiceNumber && isSubmitted === true">Invoice # is required</form-error>
                                    </div>
                                    <div class="flex-grow-1 me-3 ms-2">
                                        <label>PO #</label>
                                        <input type="text" class="form-control" [maxlength]="isMizu ? 12 : null" [(ngModel)]="invoiceModel.purchaseOrderNumber" [slick-validation-indicator]="invoiceModel.purchaseOrderNumber" slickValidationType="warning" />
                                    </div>
                                    <div class="flex-shrink-0 text-end" style="width: auto;" *ngIf="invoiceModel.createdBy && createdByUser">
                                        <label>Created By: {{createdByUser.fullName}}</label>
                                        <div *ngIf="invoiceModel.preAuthMessage">{{invoiceModel.preAuthMessage}}</div>
                                    </div>
                                </div>

                            </div>

                            <div class="row mt-3">

                                <div class="col">
                                    <label>Date</label>
                                    <input type="text"
                                           class="form-control"
                                           slick-date-picker
                                           [(ngModel)]="invoiceModel.invoiceDate"
                                           (onDateChange)="onInvoiceDateChange($event)"
                                           [slick-validation-indicator]="invoiceModel.invoiceDate"
                                           slickValidationType="warning" />
                                </div>

                                <div class="col">
                                    <label>Due Date</label>
                                    <input type="text" class="form-control" slick-date-picker [(ngModel)]="invoiceModel.invoiceDueDate" [slick-validation-indicator]="invoiceModel.invoiceDueDate" slickValidationType="warning" />
                                </div>

                                <div class="col" *ngIf="salesReps.length > 0">
                                    <label>Sales Rep</label>
                                    <slick-drop-down [ngModel]="invoiceModel.salesRepUserId"
                                                     [items]="salesReps"
                                                     allowEmpty="true"
                                                     placeholder="None"
                                                     (onSelect)="onSalesRepSelected($event)"></slick-drop-down>
                                </div>

                            </div>
                            <div class="row mt-3">
                                <div class="col">
                                    <label>Status</label>
                                    <slick-drop-down [(ngModel)]="invoiceModel.invoiceStatusId"
                                                     [items]="invoiceStatuses"
                                                     allowEmpty="false"></slick-drop-down>
                                </div>

                                <div *ngIf="useAdvancedTax === false" class="col">
                                    <label>Tax Rate</label>
                                    <input type="number" class="form-control" [(ngModel)]="invoiceModel.taxRate" [slick-validation-indicator]="invoiceModel.taxRate !== null" slickValidationType="warning" />
                                </div>

                                <div class="col">
                                    <label>Payment Terms</label>
                                    <slick-drop-down [(ngModel)]="invoiceModel.paymentTermId"
                                                     [items]="paymentTerms"
                                                     allowEmpty="false"
                                                     placeholder="Payment Term"
                                                     (onSelect)="onPaymentTermSelect($event)"
                                                     [validationIndicator]="invoiceModel.paymentTermId"></slick-drop-down>
                                </div>
                                <div class="mt-3">
                                    <invoice-custom-fields [(invoice)]="invoiceModel"></invoice-custom-fields>
                                </div>
                            </div>
                            <div class="row mt-2" style="min-width: 66%;">
                                <div class="col me-3">
                                    <label>Invoice Notes</label>
                                    <textarea class='form-control' [(ngModel)]='invoiceModel.notes' style='min-height: 100px'></textarea>
                                </div>

                                <div class="col">
                                    <label>Customer Notes</label>
                                    <textarea class='form-control' [(ngModel)]='invoiceModel.customerNotes' style='min-height: 100px'></textarea>
                                </div>
                            </div>

                            <div class="d-flex flex-fill mb-4 mt-3">
                                <div class="flex-fill mb-2 me-3" *ngIf="useLathamOrdering">
                                    <label>Latham Order Notes</label>
                                    <textarea class='form-control bg-white text-dark' style="min-height: 100px; line-height: 1rem;" readonly>{{invoiceModel.lathamOrderNotes}}</textarea>
                                </div>

                                <div class="flex-fill mb-2" *ngIf="useLathamOrdering && invoiceModel.lathamOfficeNotes">
                                    <label>Latham Office Notes</label>
                                    <textarea class='form-control bg-white text-dark' style="min-height: 100px; line-height: 1rem;" readonly>{{invoiceModel.lathamOfficeNotes}}</textarea>
                                </div>

                                <!--<div *ngIf="invoiceModel?.shippingAddress?.state === 'CO' && adminEdit" class="row mb-2">
                                    <div class="col-12">
                                        <input type="checkbox" [(ngModel)]="invoiceModel.coloradoShippingSurcharge" (change)="onColoradoShippingSurchargeChange()" />Colorado shipping surcharge
                                    </div>
                                </div>-->
                            </div>

                            <div class="bold-header mb-1" style="display: flex; align-items: center; padding-top: 5px !important;">
                                <div style="flex-grow: 1;">Line Items</div>
                                <div class="gray-line"></div>
                            </div>

                            <div class="mt-1" *ngIf="useJellyFishOrdering">

                                <!-- LOGO HEADER -->
                                <div class="logo-header" style="display: flex; align-items: center;">
                                    <div style="flex-grow: 1;">
                                        <i><img src="../../../content/images/jelly-fish-lighting-logo.png" style="max-width: 150px; max-height: 150px;" /></i>
                                    </div>
                                    <div class="gray-line"></div>
                                </div>

                                <!-- SPECS -->
                                <div class="mt-3" *ngIf="invoiceModel.jellyFishOrderSpecsModel">
                                    <jelly-fish-order-specs [(orderSpecs)]="invoiceModel.jellyFishOrderSpecsModel"
                                                            [customerId]="invoiceModel.customer?.customerId"
                                                            (onLineItemsChanged)="onLineItemsChanged($event)"
                                                            [lineItems]="invoiceModel.lineItems"
                                                            [invoiceModel]="invoiceModel"
                                                            [locked]="invoiceModel.lockJellyFishSpecs"></jelly-fish-order-specs>
                                </div>
                            </div>

                            <!-- QUICK ADDS FOR JELLYFISH -->
                            <div *ngIf="useJellyFishOrdering" class="mt-2">
                                <quick-adds [(lineItems)]="invoiceModel.lineItems"
                                            (lineItemsChange)="onLineItemsChanged($event)"
                                            [customerId]="invoiceModel?.customer?.customerId"></quick-adds>
                            </div>

                            <!-- Line Items -->
                            <line-items [(lineItems)]="invoiceModel.lineItems"
                                        [invoiceModel]="invoiceModel"
                                        [(amountDue)]="invoiceModel.outstandingBalance"
                                        [editable]="adminEdit === true"
                                        [isLathamOrder]="showLathamOrderCheckboxes"
                                        [appliedPayments]="invoiceModel.appliedPayments"
                                        [invoiceGroupSeq]="invoiceModel.invoiceGroupSeq"
                                        [customer]="invoiceModel.customer"
                                        [jobSiteAddress]="invoiceModel.jobSiteAddress"
                                        [shippingAddress]="invoiceModel.shippingAddress"
                                        [taxRate]="invoiceModel.taxRate"
                                        [pricingChanged]="invoiceModel.pricingChanged"
                                        (onRefreshPricing)="onRefreshPricing()"
                                        [showSendToCheckBox]="useJellyFishOrdering"></line-items>

                            <quick-adds *ngIf="adminEdit === true && !useJellyFishOrdering" [(lineItems)]="invoiceModel.lineItems"
                                        (lineItemsChange)="onLineItemsChanged($event)"
                                        [customerId]="invoiceModel?.customer?.customerId"></quick-adds>

                        </div>
                    </slick-container-body>
                </slick-container>
            </slick-tab>

            <slick-tab *ngIf="invoiceModel.invoiceId !== 0 && invoiceModel.jobId != null && canAccessJobCosting" header="Job Costing" icon="bi-graph-up-arrow">
                <slick-container maxHeight="calc(100vh - 250px)">
                    <slick-container-body *ngIf="tabKey === 'Job Costing'">
                        <div *ngIf="isJobCostSetupValid">
                            <div *ngIf="!invoiceModel.jobCostingDetailModel">
                                <button class="btn btn-outline-primary" (click)="generateJobCostingReport()">Generate Job Costing Report</button>
                            </div>
                            <div *ngIf="invoiceModel.jobCostingDetailModel">
                                <button class="btn btn-outline-primary" (click)="generateJobCostingReport()">Regenerate Job Costing Report</button>
                            </div>
                        </div>
                        <job-costing-details [isValid]="isJobCostSetupValid" [jobCostingDetailModel]="invoiceModel.jobCostingDetailModel"></job-costing-details>
                    </slick-container-body>
                </slick-container>
            </slick-tab>

            <slick-tab header="Payments" icon="fa-credit-card">
                <slick-container maxHeight="calc(100vh - 250px)">
                    <slick-container-body>

                        <div class="bold-header" style="display: flex; align-items: center; padding-top: 5px !important;">
                            <div style="flex-grow: 1;">Applied</div>
                            <div class="gray-line"></div>
                        </div>
                        <div class="ms-2">
                            <div class="mb-3" *ngIf="invoiceModel.appliedPayments.length === 0">No applied payments</div>
                            <table *ngIf="invoiceModel.appliedPayments.length > 0" class="table table-sm striped table-borderless">

                                <colgroup>
                                    <col style="width: 25px" />
                                    <col style="width: 120px" />
                                    <col style="width: 120px" />
                                    <col style="width: 120px" />
                                    <col style="width: 120px" />
                                    <col style="width: 120px" />
                                    <col style="width: 60px" />
                                </colgroup>

                                <thead>
                                    <tr class="border-bottom">
                                        <td></td>
                                        <td class="text-primary">Reference #</td>
                                        <td class="text-primary">Payment Date</td>
                                        <td style="text-align: right;" class="text-primary">Payment Total</td>
                                        <td style="text-align: right;" class="text-primary">Applied Amount</td>
                                        <td style="text-align: right;" class="text-primary">Payment Remaining</td>
                                        <td></td>
                                    </tr>
                                </thead>

                                <tbody style="vertical-align: middle;">
                                    <tr *ngFor="let appliedPayment of invoiceModel.appliedPayments">
                                        <td style="text-align: left; max-width: 10px;">
                                            <button type="button" class="btn btn-link text-danger" (click)="removeAppliedPayment(appliedPayment)"><i class="far fa-minus-circle"></i></button>
                                        </td>
                                        <td>{{appliedPayment.payment?.referenceNumber}}</td>
                                        <td>{{appliedPayment.payment?.paymentDate | date:'M/d/yyyy'}}</td>
                                        <td style="text-align: right;">{{appliedPayment.payment?.amount | currency}}</td>
                                        <td style="text-align: right;">
                                            <input type="text" class="form-control" style="text-align: right; padding: 2px; padding-right: 5px; height: 26px;" [slick-currency-mask] [(ngModel)]="appliedPayment.appliedAmount" (change)="onChangeAppliedAmount(appliedPayment)" />
                                        </td>
                                        <td style="text-align: right;">  {{ (appliedPayment.payment?.amount - (appliedPayment.appliedAmount || 0)) | currency }}</td>
                                        <td style="text-align: right;">
                                            <button type="button" style="font-size: 1rem; line-height: 22px;" class="btn btn-outline-primary btn-sm me-1 py-0 px-1" (click)="printAppliedPayment(appliedPayment)"><i class="far fa-print"></i></button>
                                            <button type="button" style="font-size: 1rem; line-height: 22px;" class="btn btn-outline-primary btn-sm me-1 py-0 px-1" (click)="emailAppliedPayment(appliedPayment)"><i class="far fa-envelope"></i></button>
                                        </td>

                                    </tr>
                                </tbody>
                            </table>

                            <table *ngIf="invoiceModel.appliedPayments.length > 0" class="table table-sm table-borderless">

                                <colgroup>
                                    <col style="width: 100%" />
                                    <col style="min-width: 200px" />
                                    <col style="min-width: 40px" />
                                    <col style="min-width: 50px" />
                                    <col style="min-width: 110px" />
                                </colgroup>

                                <tbody style="vertical-align: middle;">
                                    <tr>
                                        <td></td>
                                        <td style="text-align: right;"><b>Invoice Total:</b></td>
                                        <td></td>
                                        <td style="text-align: right;"><b>{{invoiceModel.invoiceTotal | currency}}</b></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style="text-align: right;"><b>Applied Total:  </b></td>
                                        <td></td>
                                        <td style="text-align: right;"><b>{{ calculateAppliedTotal() | currency }}</b></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style="font-weight: bold; text-align: right; border-top: 2px solid var(--bs-border-color);">Balance Due:</td>
                                        <td style="border-top: 2px solid var(--bs-border-color);"></td>
                                        <td style="font-weight: bold; text-align: right; border-top: 2px solid var(--bs-border-color);">
                                            <div>{{invoiceModel.outstandingBalance | currency}}</div>
                                        </td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                        <button class="btn btn-outline-primary my-2" (click)="addPayment()"><i class="far fa-plus"></i> Add Payment</button>

                        <div class="bold-header" style="display: flex; align-items: center; padding-top: 5px !important;">
                            <div *ngIf="!paymentsExpanded" (click)="paymentsExpanded = !paymentsExpanded" style="flex-grow: 1;"> <i class="bi bi-plus-circle"></i></div>
                            <div *ngIf="paymentsExpanded" (click)="paymentsExpanded = !paymentsExpanded" style="flex-grow: 1;"> <i class="bi bi-dash-circle"></i></div>
                            <div style="flex-grow: 1;">Payments</div>
                            <div class="gray-line"></div>
                        </div>
                        <div class="ms-2" *ngIf="paymentsExpanded">
                            <div class="mb-3" *ngIf="!unappliedPayments">Loading...</div>
                            <div class="mb-3" *ngIf="unappliedPayments && unappliedPayments.length === 0">No payments have been made</div>
                            <table *ngIf="unappliedPayments && unappliedPayments.length > 0" class="table table-sm striped table-borderless">

                                <colgroup>
                                    <col style="width: 25px" />
                                    <col style="width: 120px" />
                                    <col style="width: 120px" />
                                    <col style="width: 120px" />
                                    <col style="width: 120px" />
                                    <col style="width: 120px" />
                                </colgroup>

                                <thead>
                                    <tr class="border-bottom">
                                        <td></td>
                                        <td class="text-primary">Reference #</td>
                                        <td class="text-primary">Payment Date</td>
                                        <td style="text-align: right;" class="text-primary">Payment Total</td>
                                        <td style="text-align: right;" class="text-primary">Applied Amount</td>
                                        <td style="text-align: right;" class="text-primary">Payment Remaining</td>
                                    </tr>

                                </thead>

                                <tbody style="vertical-align: middle;">
                                    <tr *ngFor="let unappliedPayment of unappliedPayments">
                                        <td style="text-align: left; max-width: 10px;">
                                            <button type="button" class="btn btn-link text-success" (click)="addAppliedPayment(unappliedPayment)"><i class="far fa-plus-circle"></i></button>
                                        </td>
                                        <td>{{unappliedPayment.referenceNumber}}</td>
                                        <td>{{unappliedPayment.paymentDateDisplay}}</td>
                                        <td style="text-align: right;">{{unappliedPayment.amountDisplay}}</td>
                                        <td style="text-align: right;">{{unappliedPayment.appliedPaymentAmount | currency}}</td>
                                        <td style="text-align: right;">{{unappliedPayment.totalRemainingAmountDisplay}}</td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </slick-container-body>
                </slick-container>
            </slick-tab>


            <slick-tab *ngIf="useInventory" header="Inventory" icon="fa-box-full">
                <slick-container maxHeight="calc(100vh - 250px)">
                    <slick-container-body>
                        <div class="mt-3">
                            <input type="checkbox" [checked]="!invoiceModel.useDefaultInventory" (click)="changeDefaultInventory()" /> Custom Inventory
                        </div>
                        <div class="d-flex mt-2" *ngIf="useShipping">
                            <div class="d-flex mt-2" *ngIf="useShipping">
                                <div class="me-2">
                                    <label>Pro Number</label>
                                    <input type="text"
                                           class="form-control"
                                           [(ngModel)]="invoiceModel.proNumber" />
                                </div>
                                <div class="me-2">
                                    <label>Ship Date</label>
                                    <input slick-date-picker
                                           class="form-control"
                                           [(ngModel)]="invoiceModel.estimatedShipDate" />
                                    <form-error *ngIf="flagShipDate">Ship date is required</form-error>
                                </div>
                                <div>
                                    <label>Carrier</label>
                                    <slick-drop-down [items]="carriers"
                                                     [(ngModel)]="invoiceModel.carrierId"></slick-drop-down>
                                </div>
                            </div>
                        </div>



                        <inventory-line-items #inventoryLineItemsRef
                                              [invoiceUuid]="invoiceModel.uuid"
                                              [(lineItems)]="invoiceModel.inventoryLineItems"
                                              [(inventoryWarehouseId)]="invoiceModel.inventoryWarehouseId"
                                              [editable]="!invoiceModel.useDefaultInventory"
                                              [isSubmitted]="isSubmitted"></inventory-line-items>

                    </slick-container-body>
                </slick-container>
            </slick-tab>

            <slick-tab header="Photos" icon="fa-camera">
                <slick-container maxHeight="calc(100vh - 250px)">
                    <slick-container-body>
                        <documents-edit *ngIf="tabKey === 'Photos'"
                                        [documentKey]="invoiceModel.documentKey"
                                        documentType="Invoices"
                                        title="Invoice Photos"
                                        folderType="photos"
                                        [canDelete]="true"
                                        [hideFileDrop]="!invoicesAuthService.canEdit()"></documents-edit>
                    </slick-container-body>
                </slick-container>
            </slick-tab>

            <slick-tab *ngIf="invoiceModel.jobId" header="Job Photos" icon="fa-camera">
                <slick-container maxHeight="calc(100vh - 250px)">
                    <slick-container-body>
                        <div *ngIf="!jobPhotos">Loading...</div>
                        <div *ngIf="jobPhotos?.length === 0">No Photos</div>
                        <div *ngIf="jobPhotos?.length > 0">
                            <slick-file-list #jobPhotosRef
                                             [files]="jobPhotos"
                                             [thumbnailSize]="120"
                                             [showPhotoGallery]="true"
                                             [showCheckboxes]="true"
                                             [allowDelete]="false"
                                             (onFileCheckChanged)="onFileCheckChanged($event)"></slick-file-list>
                        </div>
                    </slick-container-body>
                </slick-container>
            </slick-tab>

            <slick-tab header="Documents" icon="fa-file-alt">
                <slick-container maxHeight="calc(100vh - 250px)">
                    <slick-container-body>
                        <documents-edit *ngIf="tabKey === 'Documents'"
                                        [documentKey]="invoiceModel.documentKey"
                                        title="Invoice Documents"
                                        documentType="Invoices"
                                        folderType="documents"
                                        [canDelete]="true"
                                        [hideFileDrop]="!invoicesAuthService.canEdit()"></documents-edit>
                    </slick-container-body>
                </slick-container>
            </slick-tab>

            <slick-tab header="System Logs" icon="fa-info-circle">
                <slick-container maxHeight="calc(100vh - 250px)">
                    <slick-container-body>
                        <system-logs *ngIf="tabKey === 'System Logs'" noteType="2" [referenceId]="invoiceModel.invoiceId"></system-logs>
                    </slick-container-body>
                </slick-container>
            </slick-tab>
        </slick-tabs>
    </slick-container-body>

    <slick-container-footer *ngIf="!isDialog">
        <div class="d-flex flex-column" *ngIf="invoicesAuthService.canEdit()">
            <div class="mt-1 pt-1 row" style="min-width: 780px; max-width: 100%;">
                <div class="col">
                    <button type="button" [slick-button-spinner]="spinnerStatus" class="btn btn-outline-success me-2" style="width: 140px;" (click)="saveInvoice()"><i class="bi bi-check-circle"></i> Save</button>
                    <button type="button" class="btn btn-outline-danger" style="width: 140px;" (click)="cancelInvoice()"><i class="bi bi-x-circle"></i> Cancel</button>
                    <div class="mt-2" *ngIf="invoiceModel.shipped">*Order has been shipped</div>
                </div>
                <div class="col text-end me-2" *ngIf="invoicesAuthService.canEdit()">
                    <div class="btn-group me-1" role="group">
                        <button type="button" [disabled]="invoiceModel.invoiceId === 0" class="btn btn-outline-primary" title="Print Invoice" (click)="generateInvoicePdf()"><i class="far fa-print"></i></button>
                        <button type="button" [disabled]="invoiceModel.invoiceId === 0" class="btn btn-outline-primary" title="Print Work Order" (click)="generateWorkOrderPdf()">
                            <div style="position: absolute; font-size: 6px; border-radius: 6px; right: 6px; top: 18px; line-height: 12px; min-width: 12px; padding: 0;" class="bg-dark text-white">WO</div>
                            <i class="far fa-print"></i>
                        </button>
                        <button type="button" [disabled]="invoiceModel.invoiceId === 0" class="btn btn-outline-primary" title="Email Invoice" (click)="emailInvoice()"><i class="far fa-envelope"></i></button>
                        <button type="button" [disabled]="invoiceModel.invoiceId === 0" class="btn btn-outline-primary" title="Text Invoice" (click)="textInvoice()"><i class="far fa-comment-alt-lines"></i></button>

                        <button type="button" [disabled]="invoiceModel.invoiceId === 0" class="btn btn-outline-primary" title="Duplicate to new Invoice" (click)="duplicateInvoice()"><i class="far fa-copy"></i></button>
                        <button *ngIf="useCreditCardProcessor" type="button" [disabled]="invoiceModel.invoiceId === 0" class="btn btn-outline-primary" title="Payment" (click)="payment()"><i class="far fa-credit-card"></i></button>
                        <button *ngIf="useShipping" type="button" [disabled]="invoiceModel.invoiceId === 0" class="btn btn-outline-primary" title="Create shipping record" (click)="createShippingRecord()"><i class="bi bi-truck-front"></i></button>
                        <button *ngIf="adminUnlock && adminEdit === false" title="Admin Edit" [disabled]="invoiceModel.invoiceId === 0" type="button" class="btn btn-outline-primary" (click)="onAdminEdit()"><i class="far fa-lock"></i></button>
                        <button *ngIf="adminUnlock && adminEdit === true" title="Admin Edit" [disabled]="invoiceModel.invoiceId === 0" type="button" class="btn btn-outline-primary" (click)="adminEdit=false"><i class="far fa-unlock"></i></button>
                    </div>
                    <!--<button type="button" *ngIf="useJellyFishOrdering" [disabled]="invoiceModel.invoiceId === 0 || invoiceModel.jellyFishOrderId" class="btn btn-outline-primary ms-2 me-2" title="Create JellyFish Order" (click)="createJellyFishOrder()"><i><img src="../../../content/images/jelly-fish-logo-colored.png" style="max-width: 20px; max-height: 20px;" /></i> </button>-->
                    <button *ngIf="canMoveToSalesOrder" type="button" [disabled]="invoiceModel.invoiceId === 0" class="btn btn-outline-primary" title="Back to Sales Order" (click)="convertToSalesOrder()"><i class="far fa-arrow-alt-left" style="margin-right: 2px;"></i><i class="far fa-dollar-sign"></i></button>
                    <button *ngIf="(isMizu || isCanada) && isLathamOrder" type="button" class="btn btn-outline-primary ms-1" title="Compare Latham Bill" (click)="compareLathamBill()"><i class="far fa-check-circle"></i></button>

                </div>

            </div>
            <form-error *ngIf="tabKey !== 'Inventory' && flagShipDate">Ship date is required</form-error>
            <span class="ml-1" style="font-size:14px" *ngIf="invoiceModel.submittedToParent && invoiceModel.submittedDate"> *Submitted on {{invoiceModel.submittedDate | date:'M/dd/yyyy h:mma'}}</span>

        </div>
        <div class="flex-fill" *ngIf="!invoicesAuthService.canEdit()">
            <permission-denied [message]="'*You do not have permission to edit Invoices.'"></permission-denied>
        </div>
    </slick-container-footer>

</slick-container>

<process-payment-dialog #processPaymentDialogRef
                        [amountLocked]="lockPaymentAmount"
                        (onApproved)="onApproved($event)"
                        (onACHApproved)="onACHApproved($event)"
                        (onPaymentTypesChanged)="onPaymentTypesChanged($event)"></process-payment-dialog>

<emailer-dialog #emailerDialogRef></emailer-dialog>

<add-payment-dialog #addPaymentDialogRef></add-payment-dialog>

<slick-dialog #qbDocNumberChangeDialogRef header="QB Doc Number" [width]="400">
    <div *ngIf="invoiceModel" class="d-flex flex-column">
        <label>Quickbooks Doc Number</label>
        <input type="text" class="form-control" [(ngModel)]="invoiceModel.quickbooksDocNumber" />
    </div>

    <slick-dialog-footer>
        <button type="button" [slick-button-spinner]="qbDocNumberSpinnerStatus" style="width: 160px" class="btn btn-outline-primary me-3" (click)="onUpdateQBDocNumber()"><i class="bi bi-check-circle"></i> Save</button>
        <button type="button" style="width: 160px" class="btn btn-outline-danger" (click)="onCancelQBDocNumber()"><i class="bi bi-x-circle"></i> Cancel</button>
    </slick-dialog-footer>
</slick-dialog>

<compare-latham-bill-dialog #compareDialogRef></compare-latham-bill-dialog>
<texter-dialog #texterDialogRef></texter-dialog>
<credit-card-selector-dialog #creditCardSelectorDialogRef></credit-card-selector-dialog>

