import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from "@components";
import { CustomerDisplayModule } from "@components/customer-display";
import { SystemLogsModule } from "@components/system-logs";
import { CustomerSearchModule } from "@components/customer-search";
import { ProcessPaymentDialogModule } from '@components/process-payment-dialog/process-payment-dialog.module';
import { EmailerDialogModule } from "@components/emailer-dialog";

import { SlickButtonSpinnerModule, SlickConfirmDialogModule, SlickContainerModule, SlickCurrencyMaskModule, SlickDatePickerModule, SlickDialogModule, SlickDropDownModule, SlickScreenBlockerModule, SlickTabsModule, SlickToastModule, SlickValidationIndicatorModule } from "@slick-components";

import { LineItemsModule } from "@components/line-items"

import { AddPaymentDialogComponent } from "./add-payment-dialog/add-payment-dialog.component";
import { PaymentEditComponent } from "./payment-edit/payment-edit.component";
import { PaymentEditDialogComponent } from "./payment-edit-dialog/payment-edit-dialog.component";
import { InvoicesService } from "@services";
import { RefundPaymentDialogModule } from "@shared-components/refund-payment-dialog/refund-payment-dialog.module"

@NgModule({
	imports: [CommonModule,
		FormsModule,
		ComponentsModule,
		CustomerDisplayModule,
		SystemLogsModule,
		CustomerSearchModule,
		ProcessPaymentDialogModule,
		EmailerDialogModule,
		LineItemsModule,
		RefundPaymentDialogModule,
		SlickButtonSpinnerModule, SlickConfirmDialogModule, SlickContainerModule, SlickCurrencyMaskModule, SlickDialogModule, SlickDropDownModule, SlickDatePickerModule, SlickScreenBlockerModule, SlickTabsModule, SlickToastModule, SlickValidationIndicatorModule],
	exports: [AddPaymentDialogComponent, PaymentEditComponent, PaymentEditDialogComponent],
	declarations: [AddPaymentDialogComponent, PaymentEditComponent, PaymentEditDialogComponent],
	providers: [InvoicesService]
})
export class PaymentsComponentsModule { }

export { PaymentEditDialogComponent }
